import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';

import { DropdownReviewer } from '../app/dropdown-reviewer';
import { getPhoneNumbersForApp, IAppUserPhoneNumbers } from '@safarilaw-webapp/shared/common-objects-models';
@Injectable({
  providedIn: 'root'
})
export class DropdownReviewerAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownReviewer): Required<DropdownReviewer> {
    return {
      ...super.fromListModel(model),
      hasSignature: model.hasSignature,
      jobTitle: model.jobTitle,
      phoneNumbers: model.phoneNumbers,
      email: model.email,
      firstName: model.firstName,

      lastName: model.lastName,

      teamId: model.teamId,
      ...(getPhoneNumbersForApp(model) as Required<IAppUserPhoneNumbers>)
    };
  }
}
