import { Injectable } from '@angular/core';
import { CrudService } from '@safarilaw-webapp/shared/crud';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import { cloneDeep } from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportType } from '../../enums';
import { ReportAdapter } from '../adapters/reports-adapter';
import { Report } from '../models/app/report';

export enum ReportTypes {
  AllData = '1',
  OpenRequests = '2',
  DocumentRequestCostRecovery = '3',
  ResponseDuration = '4',
  ReviewerHistory = '5',
  TransferDetails = '6',
  UnconfirmedTransfers = '7',
  GarnishmentCurrentlySeizedAmounts = '8',
  Documents = '9',
  LedgerTransactions = '10',
  Participants = '11'
}

class FlexmonsterReports {
  /* eslint-disable @typescript-eslint/naming-convention -- report mapping */
  private static ReportFieldMapping = {
    ...{
      'Date Created': {
        type: 'datetime'
      },
      'Date Received': {
        type: 'datetime'
      },
      // This is in the Matters report
      'Response Return Date': {
        type: 'datetime',
        caption: 'Return Date'
      },
      // This is in the Reviewer History report
      'Return Date': {
        type: 'datetime'
      },
      // This is in the Matters report
      'Date Due (Response)': {
        type: 'datetime'
      },
      // This is in the Reviewer History report
      'Date Response Due': {
        type: 'datetime',
        caption: 'Date Due (Response)'
      },
      'Response Sent Date': {
        type: 'datetime',
        caption: 'Date Sent'
      },
      'Date Filed': {
        type: 'datetime'
      },
      'Response Resolved Date': {
        type: 'datetime',
        caption: 'Date Resolved'
      },
      'Closed Date': {
        type: 'datetime',
        caption: 'Date Closed'
      },
      'Portal Expiration Date': {
        type: 'datetime'
      },
      'Portal Last Access Date': {
        type: 'datetime'
      },
      'Last Delivery Portal Payment Date': {
        type: 'datetime'
      },
      'Last ServePort Payment Date': {
        type: 'datetime'
      },
      'Transfer Sent Date': {
        type: 'datetime'
      },
      'Transfer Confirmed Date': {
        type: 'datetime'
      },
      'Transfer Link Expiration Date': {
        type: 'datetime'
      },
      'Garnishment Release Date': {
        type: 'datetime'
      },
      'Date Due (Answer)': {
        type: 'datetime'
      },
      'Date Due (Current)': {
        type: 'datetime'
      },
      'Date Due (Payment)': {
        type: 'datetime'
      },
      'Date Of Transition': {
        type: 'datetime'
      },
      'Document Uploaded On': {
        type: 'datetime'
      },
      'Portal Date': {
        type: 'datetime'
      },
      'Download Date': {
        type: 'datetime'
      },
      'Date Document Auto-Deleted': {
        type: 'datetime'
      },
      'BSA Review Date': {
        type: 'datetime'
      },
      'Transaction Date': {
        type: 'datetime'
      },
      'Participant Date Added': {
        type: 'datetime'
      },
      'Document MD5 Hash': {
        type: 'string'
      },
      'Legal Order Fee / Prepaid Amount': {
        type: 'number'
      },
      'Response Manual Payment Amount': {
        type: 'number'
      },
      'Response Current Invoice Amount': {
        type: 'number'
      },
      'Last Delivery Portal Payment Amount': {
        type: 'number'
      },
      'Last Delivery Portal Processing Fee': {
        type: 'number'
      },
      'Last ServePort Payment Amount': {
        type: 'number'
      },
      'Last ServePort Processing Fee': {
        type: 'number'
      },
      'Response Total Invoice Amount': {
        type: 'number'
      },
      'Response Total Payment Amount': {
        type: 'number'
      },
      'Total Delivery Portal Payment Amount': {
        type: 'number'
      },
      'Total Delivery Portal Processing Fee': {
        type: 'number'
      },
      'Total ServePort Payment Amount': {
        type: 'number'
      },
      'Total ServePort Processing Fees': {
        type: 'number'
      },
      'Garnishment Levy / Order Amount': {
        type: 'number'
      },
      'Garnishment Currently Seized Amount': {
        type: 'number'
      },
      'Garnishment Pending Amount': {
        type: 'number'
      },
      'Garnishment Processing Fee': {
        type: 'number'
      },
      'Garnishment Release Amount': {
        type: 'number'
      },
      'Garnishment Total Payment Amount': {
        type: 'number'
      },
      'Garnishment Total Seized Amount': {
        type: 'number'
      },
      'Garnishment Minimal Threshold Amount': {
        type: 'number'
      },
      Amount: {
        type: 'number'
      },
      'Litigation Amount Claimed': {
        type: 'number'
      },
      'Litigation Judgment Sought': {
        type: 'number'
      },
      'Litigation Judgment Amount (Collected)': {
        type: 'number'
      },
      'Litigation Judgment Amount (Paid)': {
        type: 'number'
      },
      'Response Duration Days Total': {
        type: 'number'
      },
      'Response Duration Days Excluding Weekends': {
        type: 'number'
      },
      'Transfer Duration Days Total': {
        type: 'number'
      },
      'Transfer Duration Days Excluding Weekends': {
        type: 'number'
      },
      'Internal Attachment Count': {
        type: 'number'
      },
      'Response Attachment Count': {
        type: 'number'
      },
      'Served Attachment Count': {
        type: 'number'
      },
      'Collections Attachment Count': {
        type: 'number'
      },
      'Financial Attachment Count': {
        type: 'number'
      },
      'Answer Attachment Count': {
        type: 'number'
      },
      'Correspondence Attachment Count': {
        type: 'number'
      },
      'Transaction Amount': {
        type: 'number'
      },
      'Number of Subjects': {
        type: 'number'
      },
      'Document Size (Bytes)': {
        type: 'number'
      },
      'Legal Order Fee / Prepaid Check Number': {
        type: 'string'
      },
      'Response Final Payment Check Number': {
        type: 'string'
      },
      'Other Matter Status': {
        type: 'string'
      },
      'Other Matter Closed Reason': {
        type: 'string'
      },
      'Case Number': {
        type: 'string'
      },
      'Serving Party Phone': {
        type: 'string'
      },
      'Serving Party Fax': {
        type: 'string'
      },
      'Serving Party Mobile': {
        type: 'string'
      },
      'Reviewer - Work Phone': {
        type: 'string'
      },
      'Reviewer - Mobile Phone': {
        type: 'string'
      },
      'Internal Reference Id': {
        type: 'string'
      },
      Type: {
        type: 'string'
      },
      Subtype: {
        type: 'string'
      },
      'Org Unit / Division': {
        type: 'string'
      },
      'Transaction From Account Number': {
        type: 'string'
      },
      'Transaction To Account Number': {
        type: 'string'
      },
      'Participant First Name': {
        type: 'string'
      },
      'Participant Last Name': {
        type: 'string'
      },
      'Participant Full Name': {
        type: 'string'
      },
      'Participant Email': {
        type: 'string'
      },
      'Participant Job Title': {
        type: 'string'
      },
      'Participant Work Phone': {
        type: 'string'
      },
      'Participant Mobile Phone': {
        type: 'string'
      },
      'Participant Is Active': {
        type: 'string'
      },
      'Team - Name': {
        type: 'string'
      },
      'Reviewer - Team - Name': {
        type: 'string'
      },
      'Participant - Team - Name': {
        type: 'string'
      },
      'Matter Participants': {
        type: 'string'
      },
      'Document Size': {
        type: 'string'
      },
      'Notified Team Names': {
        type: 'string'
      },
      'Notified Team Ids': {
        type: 'string'
      }
    },
    ...this.getPaymentMappingsObject(),
    ...this.getSubjectMappingsObject(),
    ...this.getAccountMappingsObject()
  };
  /* eslint-enable @typescript-eslint/naming-convention -- report mapping */

  private static NumericFormats = {
    formats: [
      {
        name: '',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        decimalPlaces: -1,
        maxDecimalPlaces: -1,
        maxSymbols: 20,
        negativeNumberFormat: '-1',
        currencySymbol: '',
        negativeCurrencyFormat: '-$1',
        positiveCurrencyFormat: '$1',
        isPercent: false,
        nullValue: '',
        infinityValue: 'Infinity',
        divideByZeroValue: 'Infinity',
        textAlign: 'right',
        beautifyFloatingPoint: true
      },
      {
        name: 'currency',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        decimalPlaces: 2,
        maxDecimalPlaces: 3,
        maxSymbols: 20,
        negativeNumberFormat: '-1',
        currencySymbol: '$',
        negativeCurrencyFormat: '-$1',
        positiveCurrencyFormat: '$1',
        isPercent: false,
        nullValue: '',
        infinityValue: 'Infinity',
        divideByZeroValue: 'Infinity',
        textAlign: 'right',
        beautifyFloatingPoint: true
      }
    ]
  };

  private static Measures = [
    {
      uniqueName: 'Legal Order Fee / Prepaid Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Response Manual Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Response Current Invoice Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Last Delivery Portal Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Last Delivery Portal Processing Fee',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Last ServePort Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Last ServePort Processing Fee',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Response Total Invoice Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Response Total Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Total Delivery Portal Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Total Delivery Portal Processing Fee',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Total ServePort Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Total ServePort Processing Fees',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Levy / Order Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Currently Seized Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Pending Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Processing Fee',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Release Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Total Payment Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Total Seized Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Garnishment Minimal Threshold Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Transaction Amount',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Litigation Amount Claimed',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Litigation Judgment Sought',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Litigation Judgment Amount (Collected)',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Litigation Judgment Amount (Paid)',
      aggregation: 'sum',
      format: 'currency'
    },
    {
      uniqueName: 'Response Duration Days Total',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Response Duration Days Excluding Weekends',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Transfer Duration Days Total',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Transfer Duration Days Excluding Weekends',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Internal Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Response Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Served Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Collections Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Financial Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Answer Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Correspondence Attachment Count',
      aggregation: 'sum',
      format: ''
    },
    {
      uniqueName: 'Document Size (Bytes)',
      aggregation: 'sum',
      format: ''
    },

    ...this.getPaymentMeasures(),
    ...this.getAccountMeasures()
  ];

  static templates: Report[] = [
    {
      id: ReportTypes.AllData,
      name: 'All Data',
      type: ReportType.AllMatter,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Date Created',
        'Subtype',
        'Type',
        'Has Restricted Access',
        'Entity Served - Full Name',
        'Entity Served - Short Name',
        'Entity Served - Is Active',
        'Incorrect Entity Named (from served doc)',
        'Matter Name',
        'State Received',
        'Date Received',
        'Response Return Date',
        'Date Due (Current)',
        'Response Delivery Method',
        'Response Mail Tracking Reference Number',
        'Status',
        'Response Sent Date',
        'Response Resolved Date',
        'Closed Date',
        'Response Duration Days Total',
        'Response Duration Days Excluding Weekends',
        'Response Current Invoice Type',
        'Portal Last Access Date',
        'Portal Expiration Date',
        'Delivery Portal Receipt Confirmed',
        'ServePort Receipt Confirmed',
        'Response Current Invoice Amount',
        'Last Delivery Portal Payment Amount',
        'Last Delivery Portal Payment Date',
        'Last Delivery Portal Processing Fee',
        'Last ServePort Payment Amount',
        'Last ServePort Payment Date',
        'Last ServePort Processing Fee',
        'Response Total Invoice Amount',
        'Response Total Payment Amount',
        'Total Delivery Portal Payment Amount',
        'Total Delivery Portal Processing Fee',
        'Total ServePort Payment Amount',
        'Total ServePort Processing Fees',
        'Legal Order Fee / Prepaid Amount',
        'Legal Order Fee / Prepaid Check Number',
        'Response Manual Payment Amount',
        'Response Final Payment Check Number',
        'Financial Notes',
        'Response Current Custom Invoice Description',
        'Plaintiffs',
        'Defendants',
        'Case Number',
        'Date Filed',
        'Received By',
        'Method Of Service',
        'Document Title',
        'Serving Party Contact Name',
        'Serving Party Phone',
        'Serving Party Fax',
        'Serving Party Mobile',
        'Serving Party Email',
        'Serving Party Additional Email',
        'Serving Party Org Type',
        'Serving Party Org Name',
        'Serving Party Org Address - Street 1',
        'Serving Party Org Address - City',
        'Serving Party Org Address - State',
        'Serving Party Org Address - Country',
        'Serving Party Org Address - Zip Code',
        'Forum Type And Name',
        'Forum Contact Address - Street 1',
        'Forum Contact Address - City',
        'Forum Contact Address - State',
        'Forum Contact Address - Country',
        'Forum Contact Address - Zip Code',
        'Reviewer - First Name',
        'Reviewer - Last Name',
        'Reviewer - Full Name',
        'Reviewer - Email',
        'Reviewer - Is Active',
        'Other Info',
        'Internal Reference Id',
        'Subject 1 - Name',
        'Subject 1 - Tax ID',
        'Subject 1 - Customer ID',
        'Subject 1 - DOB/Inc Date',
        'Subject 1 - Date Range Start',
        'Subject 1 - Date Range End',
        'Subject 1 - Street 1',
        'Subject 1 - City',
        'Subject 1 - State',
        'Subject 1 - Country',
        'Subject 1 - Zip Code',
        'Subject 1 - Documents Requested',
        'Subject 1 - Notes',

        'Subject 2 - Name',
        'Subject 2 - Tax ID',
        'Subject 2 - Customer ID',
        'Subject 2 - DOB/Inc Date',
        'Subject 2 - Date Range Start',
        'Subject 2 - Date Range End',
        'Subject 2 - Street 1',
        'Subject 2 - City',
        'Subject 2 - State',
        'Subject 2 - Country',
        'Subject 2 - Zip Code',
        'Subject 2 - Documents Requested',
        'Subject 2 - Notes',
        'Subject 3 - Name',
        'Subject 3 - Tax ID',
        'Subject 3 - Customer ID',
        'Subject 3 - DOB/Inc Date',
        'Subject 3 - Date Range Start',
        'Subject 3 - Date Range End',
        'Subject 3 - Street 1',
        'Subject 3 - City',
        'Subject 3 - State',
        'Subject 3 - Country',
        'Subject 3 - Zip Code',
        'Subject 3 - Documents Requested',
        'Subject 3 - Notes',
        'Subject 4 - Name',
        'Subject 4 - Tax ID',
        'Subject 4 - Customer ID',
        'Subject 4 - DOB/Inc Date',
        'Subject 4 - Date Range Start',
        'Subject 4 - Date Range End',
        'Subject 4 - Street 1',
        'Subject 4 - City',
        'Subject 4 - State',
        'Subject 4 - Country',
        'Subject 4 - Zip Code',
        'Subject 4 - Documents Requested',
        'Subject 4 - Notes',
        'Subject 5 - Name',
        'Subject 5 - Tax ID',
        'Subject 5 - Customer ID',
        'Subject 5 - DOB/Inc Date',
        'Subject 5 - Date Range Start',
        'Subject 5 - Date Range End',
        'Subject 5 - Street 1',
        'Subject 5 - City',
        'Subject 5 - State',
        'Subject 5 - Country',
        'Subject 5 - Zip Code',
        'Subject 5 - Documents Requested',
        'Subject 5 - Notes'
      ])
    },
    {
      id: ReportTypes.OpenRequests,
      name: 'Open Matters',
      type: ReportType.AllMatter,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Reviewer - Full Name',
        'Subtype',
        'Matter Name',
        'Date Received',
        'Response Return Date',
        'Date Due (Current)',
        'Status',
        'Serving Party Org Name',
        'Serving Party Org Type',
        'Entity Served - Full Name',
        'State Received',
        'Type',
        'Document Title',
        'Response Sent Date',
        {
          uniqueName: 'Closed Date',
          filter: {
            members: ['Closed Date.[Invalid date]']
          }
        }
      ])
    },
    {
      id: ReportTypes.DocumentRequestCostRecovery,
      name: 'Cost Recovery',
      type: ReportType.DocumentRequest,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Reviewer - Full Name',
        'Subtype',
        'Matter Name',
        'Entity Served - Full Name',
        'Serving Party Org Name',
        'Response Total Payment Amount',
        'Total Delivery Portal Payment Amount',
        'Total Delivery Portal Processing Fee',
        'Total ServePort Payment Amount',
        'Total ServePort Processing Fees',
        'Legal Order Fee / Prepaid Amount',
        'Last Delivery Portal Payment Amount',
        'Last Delivery Portal Payment Date',
        'Last Delivery Portal Processing Fee',
        'Last ServePort Payment Amount',
        'Last ServePort Payment Date',
        'Last ServePort Processing Fee',
        'State Received',
        'Date Received',
        'Status',
        'Response Sent Date',
        'Date Due (Current)'
      ])
    },
    {
      id: ReportTypes.GarnishmentCurrentlySeizedAmounts,
      name: 'Currently Seized',
      type: ReportType.Garnishment,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        {
          uniqueName: 'Garnishment Currently Seized Amount',
          filter: {
            query: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- flexmonster parameter
              greater: 0
            }
          }
        },
        'Garnishment Total Seized Amount',
        'Garnishment Total Payment Amount',
        'Garnishment Pending Amount',
        'Reviewer - Full Name',
        'Subtype',
        'Status',
        'State Received',
        'Date Received',
        'Date Due (Answer)',
        'Date Due (Payment)',
        'Date Due (Current)',
        'Serving Party Org Name',
        'Creditor Name',
        'Subject 1 - Name',
        'Subject 2 - Name',
        'Subject 3 - Name',
        'Subject 4 - Name',
        'Subject 5 - Name',
        'Case Number',
        'Entity Served - Full Name',
        'Issuing Authority Name',
        'Issuing Authority Address - State',
        'Legal Order Fee / Prepaid Amount',
        'Garnishment Processing Fee',
        'Financial Notes'
      ])
    },
    {
      id: ReportTypes.ResponseDuration,
      name: 'Response Duration',
      type: ReportType.DocumentRequest,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Status',
        'Response Duration Days Total',
        'Response Duration Days Excluding Weekends',
        'Date Received',
        'Date Due (Current)',
        'Response Sent Date',
        'Type',
        'Subtype',
        'Matter Name',
        'Serving Party Org Type',
        'State Received',
        'Response Current Invoice Amount',
        'Entity Served - Full Name',
        {
          uniqueName: 'Response Resolved Date',
          filter: {
            query: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- flexmonster parameter
              not_equal: ''
            }
          }
        }
      ])
    },
    {
      id: ReportTypes.ReviewerHistory,
      name: 'Reviewer History',
      type: ReportType.ReviewerHistory,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Date Created',
        'Status At Assignment',
        'Reviewer - Id',
        'Reviewer - First Name',
        'Reviewer - Last Name',
        'Reviewer - Full Name',
        'Reviewer - Email',
        'Reviewer - Is Active',
        'Status At Transition',
        'Date Of Transition',
        'Duration Days Total',
        'Duration Days Excluding Weekends',
        'Duration Hours Total',
        'Duration Hours Excluding Weekends',
        'Type',
        'Subtype',
        'Has Restricted Access',
        'Entity Served - Full Name',
        'Entity Served - Is Active',
        'Incorrect Entity Named (from served doc)',
        'Matter Name',
        'State Received',
        'Date Created',
        'Date Received',
        'Response Delivery Method',
        'Response Mail Tracking Reference Number',
        'Status',
        'Portal Status',
        'Closed Reason',
        'Status Text',
        'Response Sent Date',
        'Response Resolved Date',
        'Closed Date',
        'Response Return Date',
        'Response Duration Days Total',
        'Response Duration Days Excluding Weekends',
        'Response Current Invoice Type',
        'Portal Last Access Date',
        'Portal Expiration Date',
        'Delivery Portal Receipt Confirmed',
        'ServePort Receipt Confirmed',
        'Response Current Invoice Amount',
        'Last Delivery Portal Payment Amount',
        'Last Delivery Portal Processing Fee',
        'Last ServePort Payment Amount',
        'Last ServePort Processing Fee',
        'Legal Order Fee / Prepaid Amount',
        'Legal Order Fee / Prepaid Check Number',
        'Response Manual Payment Amount',
        'Response Final Payment Check Number',
        'Response Current Custom Invoice Description',
        'Plaintiffs',
        'Defendants',
        'Case Number',
        'Date Filed',
        'Received By',
        'Method Of Service',
        'Document Title',
        'Serving Party Contact Name',
        'Serving Party Phone',
        'Serving Party Fax',
        'Serving Party Mobile',
        'Serving Party Email',
        'Serving Party Additional Email',
        'Serving Party Org Type',
        'Serving Party Org Name',
        'Forum Type And Name',
        'Other Info',
        'Internal Reference Id',
        'Subject Count'
      ])
    },
    {
      id: ReportTypes.Participants,
      name: 'Current Participants',
      type: ReportType.Participants,
      isCustom: false,
      reportSchema: this.getReportSchema(['Safari ID', 'Participant Full Name', 'Participant - Team - Name', 'Participant Email', 'Participant Date Added', 'Number of Subjects'])
    },
    {
      id: ReportTypes.LedgerTransactions,
      name: 'Garnishment Ledger Transactions',
      type: ReportType.LedgerTransactions,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Transaction Amount',
        'Transaction Type',
        'Transaction From Account Number',
        'Transaction To Account Number',
        'Transaction Created By',
        'Transaction Date',
        'Transaction Transfer Status'
      ])
    },
    {
      id: ReportTypes.TransferDetails,
      name: 'Transfer Details',
      type: ReportType.AllMatter,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Subtype',
        'Matter Name',
        'Date Created',
        'Creation Source Type',
        'Creation Source Name',
        'Reviewer - Full Name',
        'Transfer Method',
        'Transfer Sent Date',
        'Transfer Confirmed Date',
        'Transfer Duration Days Total',
        'Transfer Duration Days Excluding Weekends',
        'Transfer Link Expiration Date',
        'State Received',
        'Date Due (Current)',
        {
          uniqueName: 'Status',
          filter: {
            query: {
              equal: 'Closed Transferred'
            }
          }
        }
      ])
    },
    {
      id: ReportTypes.UnconfirmedTransfers,
      name: 'Unconfirmed Transfers',
      type: ReportType.AllMatter,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Subtype',
        'Matter Name',
        'Date Created',
        'Reviewer - Full Name',
        'Transfer Method',
        'Transfer Sent Date',
        'Transfer Link Expiration Date',
        'State Received',
        'Date Due (Current)',
        {
          uniqueName: 'Status',
          filter: {
            query: {
              equal: 'Closed Transferred'
            }
          }
        },
        {
          uniqueName: 'Transfer Confirmed Date',
          filter: {
            query: {
              equal: ''
            }
          }
        }
      ])
    },
    {
      id: ReportTypes.Documents,
      name: 'Documents',
      type: ReportType.Documents,
      isCustom: false,
      reportSchema: this.getReportSchema([
        'Safari ID',
        'Folder',
        'Subfolder',
        'Document Name',
        'Document Uploaded On',
        'Document Uploaded By',
        'Document Size',
        'Document Type',
        'Portal Date',
        'Download Date',
        'Date Document Auto-Deleted',
        'Document MD5 Hash',
        'Serving Party Org Name',
        'Serving Party Contact Name',
        'Serving Party Email',
        'Serving Party Addl Email',
        'Reviewer - Full Name',
        'Type',
        'Subtype',
        'Case Number'
      ])
    }
  ];

  private static getPaymentMappingsObject() {
    const paymentMappingsObject = {};
    for (let i = 1; i <= 20; i++) {
      paymentMappingsObject[`Garnishment Payment ${i} - Account Number`] = {
        type: 'string'
      };
      paymentMappingsObject[`Garnishment Payment ${i} - Check Number`] = {
        type: 'string'
      };
      paymentMappingsObject[`Garnishment Payment ${i} - Date`] = {
        type: 'datetime'
      };
      paymentMappingsObject[`Garnishment Payment ${i} - Amount`] = {
        type: 'number'
      };
    }
    return paymentMappingsObject;
  }
  private static getPaymentMeasures(): any[] {
    const paymentMeasures = [];
    for (let i = 1; i <= 20; i++) {
      paymentMeasures.push({
        uniqueName: `Garnishment Payment ${i} - Amount`,
        aggregation: 'sum',
        format: 'currency'
      });
    }
    return paymentMeasures;
  }

  private static getSubjectMappingsObject() {
    const subjectMappingsObject = {};
    for (let i = 1; i <= 5; i++) {
      subjectMappingsObject[`Subject ${i} - Date Range Start`] = {
        type: 'datetime'
      };
      subjectMappingsObject[`Subject ${i} - Date Range End`] = {
        type: 'datetime'
      };
      subjectMappingsObject[`Subject ${i} - DOB/Inc Date`] = {
        type: 'datetime'
      };
      subjectMappingsObject[`Subject ${i} - Zip Code`] = {
        type: 'string'
      };
      subjectMappingsObject[`Subject ${i} - Tax ID`] = {
        type: 'string'
      };
      subjectMappingsObject[`Subject ${i} - RFPA Certificate`] = {
        type: 'string'
      };
      subjectMappingsObject[`Subject ${i} - RFPA Notes`] = {
        type: 'string'
      };
      subjectMappingsObject[`Subject ${i} - Alias`] = {
        type: 'string'
      };
      subjectMappingsObject[`Subject ${i} - Alias Masked`] = {
        type: 'string'
      };
    }
    return subjectMappingsObject;
  }

  private static getAccountMappingsObject() {
    const subjectMappingsObject = {};
    for (let i = 1; i <= 5; i++) {
      subjectMappingsObject[`Garnishment Account ${i} - Seized Amount`] = {
        type: 'number'
      };
      subjectMappingsObject[`Garnishment Account ${i} - Account Number`] = {
        type: 'string'
      };
      subjectMappingsObject[`Garnishment Account ${i} - Account Type`] = {
        type: 'string'
      };
      subjectMappingsObject[`Garnishment Account ${i} - Exempt Amount`] = {
        type: 'number'
      };
      subjectMappingsObject[`Garnishment Account ${i} - Account Balance`] = {
        type: 'number'
      };
      subjectMappingsObject[`Garnishment Account ${i} - Offset Amount`] = {
        type: 'number'
      };
    }
    return subjectMappingsObject;
  }
  private static getAccountMeasures(): any[] {
    const accountMeasures = [];
    for (let i = 1; i <= 5; i++) {
      accountMeasures.push({
        uniqueName: `Garnishment Account ${i} - Seized Amount`,
        aggregation: 'sum',
        format: 'currency'
      });
    }
    return accountMeasures;
  }

  private static getColumnsForReportDefinition(columns: (string | any)[]): any[] {
    return [
      ...columns.map(o => {
        if (typeof o === 'string') {
          return { uniqueName: o };
        } else {
          return o;
        }
      }),
      { uniqueName: '[Measures]' }
    ];
  }

  private static getFlatOrderForReportDefinition(columns: (string | any)[]): string[] {
    return columns.map(o => {
      if (typeof o === 'string') {
        return o;
      } else {
        return o.uniqueName;
      }
    });
  }

  private static getReportSchema(columns: (string | any)[]): any {
    const columnNames = this.getFlatOrderForReportDefinition(columns);
    const measures = [...FlexmonsterReports.Measures];
    for (const measure of measures) {
      if (!columnNames.any(columnName => columnName == measure.uniqueName)) {
        measure.active = false;
      }
    }

    return {
      dataSource: {
        filename: null,
        ignoreQuotedLineBreaks: false
      },
      ...FlexmonsterReports.NumericFormats,
      mapping: FlexmonsterReports.ReportFieldMapping,
      slice: {
        columns: this.getColumnsForReportDefinition(columns),
        measures,
        flatOrder: columnNames
      }
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends CrudService<Report> {
  constructor(adapter: ReportAdapter, appInsights: ApplicationInsightsService) {
    super(adapter, appInsights);
  }

  protected get apiRoot(): string {
    return this._appConfig.urls.apiRootLpms;
  }

  protected get apiSuffix(): string {
    return 'my/reports/definitions';
  }

  // We need to get preconfiguredreportlist customization out of there and turn this into
  // and injectable service responsible for delivering report items from the API.
  // preconfiguredreportlist should be prepended by the UI
  retrieveAll() {
    return forkJoin([of(this.getPreconfiguredReportsList()), super.retrieveAll()]).pipe(
      map(([preconfigured, saved]) => {
        const result = {
          totalCount: saved.items.length + preconfigured.length,
          items: cloneDeep([...(preconfigured as any), ...(saved as any).items])
        };

        return result;
      })
    );
  }
  retrieve(id: any, payload: Report = null): Observable<Report> {
    switch (id) {
      case ReportTypes.AllData:
      case ReportTypes.OpenRequests:
      case ReportTypes.DocumentRequestCostRecovery:
      case ReportTypes.ResponseDuration:
      case ReportTypes.ReviewerHistory:
      case ReportTypes.TransferDetails:
      case ReportTypes.UnconfirmedTransfers:
      case ReportTypes.GarnishmentCurrentlySeizedAmounts:
      case ReportTypes.Documents:
      case ReportTypes.LedgerTransactions:
      case ReportTypes.Participants:
        return of(FlexmonsterReports.templates.find(o => o.id === id) as any);
      default:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion -- eslint claims it's not necessary but it is
        return super.retrieve(id, payload) as Observable<Report>;
    }
  }

  private getPreconfiguredReportsList(): { id: string; type: number; name: string; isCustom: boolean }[] {
    return [
      {
        id: ReportTypes.AllData,
        type: ReportType.AllMatter,
        name: 'All Data',
        isCustom: false
      },
      {
        id: ReportTypes.OpenRequests,
        type: ReportType.AllMatter,
        name: 'Open Matters',
        isCustom: false
      },
      {
        id: ReportTypes.DocumentRequestCostRecovery,
        type: ReportType.DocumentRequest,
        name: 'Cost Recovery',
        isCustom: false
      },
      {
        id: ReportTypes.GarnishmentCurrentlySeizedAmounts,
        type: ReportType.Garnishment,
        name: 'Currently Seized',
        isCustom: false
      },
      {
        id: ReportTypes.ResponseDuration,
        type: ReportType.DocumentRequest,
        name: 'Response Duration',
        isCustom: false
      },
      {
        id: ReportTypes.ReviewerHistory,
        type: ReportType.ReviewerHistory,
        name: 'Reviewer History',
        isCustom: false
      },
      {
        id: ReportTypes.Participants,
        type: ReportType.Participants,
        name: 'Current Participants',
        isCustom: false
      },
      {
        id: ReportTypes.Documents,
        type: ReportType.Documents,
        name: 'Documents',
        isCustom: false
      },
      {
        id: ReportTypes.TransferDetails,
        type: ReportType.AllMatter,
        name: 'Transfer Details',
        isCustom: false
      },
      {
        id: ReportTypes.UnconfirmedTransfers,
        type: ReportType.AllMatter,
        name: 'Unconfirmed Transfers',
        isCustom: false
      },
      {
        id: ReportTypes.LedgerTransactions,
        type: ReportType.LedgerTransactions,
        name: 'Garnishment Ledger Transactions',
        isCustom: false
      }
    ];
  }
}
