import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { AddParticipantModel, ParticipantModel } from '../../_lpms-api-models.generated';
import { Participant } from '../models/app/participant';
@Injectable({
  providedIn: 'root'
})
export class ParticipantAdapter extends ApiDataAdapter<Participant> {
  fromListModel(o: ParticipantModel): Required<Participant> {
    return {
      email: o.email,
      firstName: o.firstName,
      fullName: o.fullName,
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      lastName: o.lastName,
      participationLevel: o.participationLevel,
      securityRole: o.securityRole,

      teamId: o.teamId as unknown as string
    };
  }
  toCreateModel(o: Participant): Required<AddParticipantModel> {
    return {
      userId: this.helpers.toApiId(o.id)
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
}
