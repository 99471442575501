/* eslint-disable @typescript-eslint/no-shadow -- this doesn't work well in TS lint */
/* eslint-disable no-restricted-syntax -- framework */
/**
 * THIS NEEDS TO BE EXPORTED SO THAT THE WEBAPP CAN REGISTER THE OBJECTS IT WANTS TO USE
 * IN ITS BASE PAGE
 */
import { inject, InjectionToken } from '@angular/core';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';

import { ISafariObjectState, SafariReduxApiObject, SafariReduxDropdownObject } from '@safarilaw-webapp/shared/redux';

import { CompanyEntity } from '../company-entity/models/app/company-entity';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { DocumentAlert } from '../document-alert/models/app/document-alert';
import { DropdownMatterSubtypeUser } from '../dropdown/dropdown-matter-subtype-user/models/app/dropdown-matter-subtype-user';
import { DropdownMatterSubtype } from '../dropdown/dropdown-matter-subtype/models/app/dropdown-matter-subtype';

import { createSelector } from '@ngrx/store';

import { CompanyPicklist } from '../company-picklist/models/app/company-picklist';
import { DocumentRequest } from '../document-request/models/app/document-request';

import { DropdownType, MatterType } from '../enums';
import { Garnishment } from '../garnishment/models/app/garnishment';

import { InvoiceTemplate } from '../invoice-template/models/app/invoice-template';
import { InvoiceTemplatePreview } from '../invoice-template/models/app/invoice-template-preview';
import { LibraryDocument } from '../library/models/app/library-document';
import { LibraryFolderList } from '../library/models/app/library-folder-list';
import { LibraryTemplate } from '../library/models/app/library-template';

import { Litigation } from '../litigation/models/app/litigation';

import { MatterCount } from '../matter-count/models/app/matter-count';
import { MatterSearch } from '../matter-search/models/matter-search';
import { Matter } from '../matter/models/app/matter';
import { OrganizationSearch } from '../organization-search/models/organization-search';
import { Organization } from '../organization/models/app/organization';
import { OtherMatter } from '../other-matter/models/app/other-matter';
import { PortalActivity } from '../portal-activity/models/app/portal-activity';
import { Report } from '../reports/models/app/report';
import { ReportsService } from '../reports/services/reports.service';
import { ServingPartySearch } from '../serving-party-search/models/serving-party-search';
import { ServingParty } from '../serving-party/models/app/serving-party';
import { Transfer } from '../transfer/models/app/transfer';
import {
  ALL_MATTERS_SERVICE_TOKEN,
  CHANGE_SUBTYPE_SERVICE_TOKEN,
  COMPANY_ENTITY_LOGO_FILE_OBJECT_SERVICE_TOKEN,
  COMPANY_ENTITY_SERVICE_TOKEN,
  COMPANY_PICKLIST_SERVICE_TOKEN,
  DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN,
  DOCUMENT_REQUEST_PORTAL_ACTIVITY_SERVICE_TOKEN,
  DOCUMENT_REQUEST_SERVICE_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_SERVICE_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_USER_SERVICE_TOKEN,
  DROPDOWN_SERVICE_TOKEN,
  EXTEND_PORTAL_SERVICE_TOKEN,
  GARNISHMENT_SERVICE_TOKEN,
  GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_SERVICE_TOKEN,
  GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_SERVICE_TOKEN,
  GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_SERVICE_TOKEN,
  GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_SERVICE_TOKEN,
  GENERATE_REPORTS_MATTER_TEMPLINK_SERVICE_TOKEN,
  INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN,
  INVOICE_TEMPLATE_SERVICE_TOKEN,
  LIBRARY_DOCUMENT_ATTACHMENT_LINK_SERVICE_TOKEN,
  LIBRARY_DOCUMENT_FILE_OBJECT_SERVICE_TOKEN,
  LIBRARY_DOCUMENT_SERVICE_TOKEN,
  LIBRARY_FOLDER_LIST_SERVICE_TOKEN,
  LIBRARY_TEMPLATE_SERVICE_TOKEN,
  LITIGATION_SERVICE_TOKEN,
  LPMS_COMPANY_SETTINGS_SERVICE_TOKEN,
  MATTER_ALERTS_SERVICE_TOKEN,
  MATTER_COUNTS_SERVICE_TOKEN,
  MATTER_LOOKUP_SERVICE_TOKEN,
  MATTER_SEARCH_SERVICE_TOKEN,
  matterServiceMap,
  ORGANIZATION_SEARCH_SERVICE_TOKEN,
  ORGANIZATION_SERVICE_TOKEN,
  OTHER_MATTER_SERVICE_TOKEN,
  RECENT_MATTER_SERVICE_TOKEN,
  RESEND_ANSWER_SERVICE_TOKEN,
  RESEND_PORTAL_INVITE_SERVICE_TOKEN,
  SEND_PORTAL_REMINDER_SERVICE_TOKEN,
  SERVING_PARTY_SEARCH_SERVICE_TOKEN,
  SERVING_PARTY_SERVICE_TOKEN,
  STARRED_MATTER_SERVICE_TOKEN,
  TRANSFER_SERVICE_TOKEN,
  TRANSFER_TEMPLINK_SERVICE_TOKEN,
  UPDATE_PORTAL_SERVICE_TOKEN
} from './injectable-service-tokens';
import { getSharedFeatureState, ILpmsStateDropdown, PROJECT_NAME } from './state.interface';

export class AttachmentLinkName {
  private static _transferLedgerAttachment: string;
  public static get TransferLedgerAttachment(): string {
    return AttachmentLinkName._transferLedgerAttachment;
  }

  private static _transferLedgerReport: string;
  public static get TransferLedgerReport(): string {
    return AttachmentLinkName._transferLedgerReport;
  }

  private static _libraryDocumentAttachmentLink: string;
  public static get LibraryDocumentAttachmentLink(): string {
    return AttachmentLinkName._libraryDocumentAttachmentLink;
  }
  private static _subjectSearchAttachment: string;
  public static get SubjectSearchAttachment(): string {
    return AttachmentLinkName._subjectSearchAttachment;
  }

  private static _correspondenceAttachment: string;
  public static get CorrespondenceAttachment(): string {
    return AttachmentLinkName._correspondenceAttachment;
  }

  private static _matterAttachment: string;
  public static get MatterAttachment(): string {
    return AttachmentLinkName._matterAttachment;
  }
  private static _matterAttachmentZip: string;
  public static get MatterAttachmentZip(): string {
    return AttachmentLinkName._matterAttachmentZip;
  }
}
export const REPORT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Report>, Report>>('REPORT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Report>, Report>(
      PROJECT_NAME,
      'Report', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ReportsService),
      createSelector(getSharedFeatureState, state => state.reportCombinedState.reportState)
    )
});

export const MATTER_SEARCH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<MatterSearch>, MatterSearch>>('MATTER_SEARCH_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<MatterSearch>, MatterSearch>(
      PROJECT_NAME,
      'MatterSearch', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MATTER_SEARCH_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.searchState)
    )
});

export const ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>>('ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>(
      PROJECT_NAME,
      'OrganizationSearch', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ORGANIZATION_SEARCH_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.organizationCombinedState.searchState)
    )
});

export const ORGANIZATION_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Organization>, Organization>>('ORGANIZATION_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Organization>, Organization>(
      PROJECT_NAME,
      'Organization', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ORGANIZATION_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.organizationCombinedState.organizationState)
    )
});

export const SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<ServingPartySearch>, ServingPartySearch>>('SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<ServingPartySearch>, ServingPartySearch>(
      PROJECT_NAME,
      'ServingPartySearch', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SERVING_PARTY_SEARCH_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.servingPartyCombinedState.searchState)
    )
});

export const SERVING_PARTY_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<ServingParty>, ServingParty>>('SERVING_PARTY_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<ServingParty>, ServingParty>(
      PROJECT_NAME,
      'ServingParty', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SERVING_PARTY_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.servingPartyCombinedState.servingPartyState)
    )
});

export const INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<InvoiceTemplate>, InvoiceTemplate>>('INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<InvoiceTemplate>, InvoiceTemplate>(
      PROJECT_NAME,
      'InvoiceTemplate', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(INVOICE_TEMPLATE_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.invoiceTemplateCombinedState.invoiceTemplateState)
    )
});
export const INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>>(
  'INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>(
        PROJECT_NAME,
        'InvoiceTemplatePreview', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.invoiceTemplateCombinedState.invoiceTemplatePreviewState)
      )
  }
);
export const DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>>(
  'DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>(
        PROJECT_NAME,
        'DocumentRequestInvoiceTemplatePreview', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.invoiceTemplatePreviewState)
      )
  }
);
export const MATTER_COUNTS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<MatterCount>, MatterCount>>('MATTER_COUNTS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<MatterCount>, MatterCount>(
      PROJECT_NAME,
      'MatterCounts', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MATTER_COUNTS_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.matterCountsState)
    )
});
export const DROPDOWN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxDropdownObject<ILpmsStateDropdown>>('DROPDOWN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxDropdownObject<ILpmsStateDropdown>(
      PROJECT_NAME,
      inject(DROPDOWN_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.dropdownCombinedState.dropdownState),
      DropdownType,
      {
        countries: null,
        states: null,
        deliverymethods: null,
        documentsassignedtome: null,
        garnishmentstatuses: null,
        garnishmenttypes: null,
        reviewers: null,
        recentdocuments: null,
        servingpartyorgtypes: null,
        issuingauthoritytypes: null,
        methodofservices: null,
        subpoenastatuses: null,
        subpoenatypes: null
      }
    )
});
export const STARRED_MATTER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Matter>, Matter>>('STARRED_MATTER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Matter>, Matter>(
      PROJECT_NAME,
      'StarredMatter', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(STARRED_MATTER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.starredState)
    )
});
export const RECENT_MATTER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Matter>, Matter>>('RECENT_MATTER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Matter>, Matter>(
      PROJECT_NAME,
      'RecentMatter', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(RECENT_MATTER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.recentState)
    )
});
export const ALL_MATTERS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Matter>, Matter>>('ALL_MATTERS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Matter>, Matter>(
      PROJECT_NAME,
      'AllMatters', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ALL_MATTERS_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.matterState)
    )
});
export const LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<LibraryDocument>, LibraryDocument>>('LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<LibraryDocument>, LibraryDocument>(
      PROJECT_NAME,
      'LibraryDocument', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LIBRARY_DOCUMENT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.libraryCombinedState.libraryDocumentState)
    )
});
export const LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<LibraryTemplate>, LibraryTemplate>>('LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<LibraryTemplate>, LibraryTemplate>(
      PROJECT_NAME,
      'LibraryTemplate', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LIBRARY_TEMPLATE_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.libraryTemplateCombinedState.libraryTemplateState)
    )
});
export const LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<LibraryFolderList>, LibraryFolderList>>('LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<LibraryFolderList>, LibraryFolderList>(
      PROJECT_NAME,
      'LibraryFolderList', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LIBRARY_FOLDER_LIST_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.libraryFolderListCombinedState.libraryFolderListState)
    )
});
export const COMPANY_ENTITY_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<CompanyEntity>, CompanyEntity>>('COMPANY_ENTITY_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<CompanyEntity>, CompanyEntity>(
      PROJECT_NAME,
      'CompanyEntity', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(COMPANY_ENTITY_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.companyEntityCombinedState.companyEntityState)
    )
});
export const DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtype>, DropdownMatterSubtype>>(
  'DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtype>, DropdownMatterSubtype>(
        PROJECT_NAME,
        'MatterSubtype', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(DROPDOWN_MATTER_SUBTYPE_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.matterSubtypeCombinedState.matterSubtypeState)
      )
  }
);
export const DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtypeUser>, DropdownMatterSubtypeUser>>(
  'DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtypeUser>, DropdownMatterSubtypeUser>(
        PROJECT_NAME,
        'MatterSubtypeUser', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(DROPDOWN_MATTER_SUBTYPE_USER_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.matterSubtypeCombinedState.matterSubtypeUserState)
      )
  }
);
export const MATTER_ALERTS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<DocumentAlert>, DocumentAlert>>('MATTER_ALERTS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<DocumentAlert>, DocumentAlert>(
      PROJECT_NAME,
      'DocumentAlert', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MATTER_ALERTS_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.documentAlertCombinedState.documentAlertState)
    )
});
export const MATTER_LOOKUP_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Matter>, Matter>>('MATTER_LOOKUP_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Matter>, Matter>(
      PROJECT_NAME,
      'MatterLookup', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MATTER_LOOKUP_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.lookupState)
    )
});
export const CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'ChangeSubtype', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(CHANGE_SUBTYPE_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.matterCombinedState.changeSubtypeState)
    )
});
export const DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<PortalActivity>, PortalActivity>>(
  'DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<PortalActivity>, PortalActivity>(
        PROJECT_NAME,
        'PortalActivity', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(DOCUMENT_REQUEST_PORTAL_ACTIVITY_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.infoRequestPortalActivityState)
      )
  }
);

export const EXTEND_PORTAL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('EXTEND_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'ExtendPortal', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(EXTEND_PORTAL_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.extendPortalState)
    )
});
export const RESEND_ANSWER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('RESEND_ANSWER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'ResendAnswer', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(RESEND_ANSWER_SERVICE_TOKEN),
      SafariObject.EMPTY
    )
});
export const SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'SendPortalReminder', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SEND_PORTAL_REMINDER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.sendPortalReminderState)
    )
});

export const UPDATE_PORTAL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('UPDATE_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'UpdatePortal', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(UPDATE_PORTAL_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.updatePortalState)
    )
});
export const RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'ResendPortalInvite', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(RESEND_PORTAL_INVITE_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.resendPortalInviteState)
    )
});

export const LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>>('LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>(
      PROJECT_NAME,
      'CompanySettings', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LPMS_COMPANY_SETTINGS_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.companyCombinedState.companySettingsState)
    )
});

export const DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<DocumentRequest>, DocumentRequest>>('DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<DocumentRequest>, DocumentRequest>(
      PROJECT_NAME,
      'Document Request', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(DOCUMENT_REQUEST_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.infoRequestCombinedState.infoRequestState)
    )
});
export const GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Garnishment>, Garnishment>>('GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Garnishment>, Garnishment>(
      PROJECT_NAME,
      'Garnishment', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(GARNISHMENT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.garnishmentCombinedState.garnishmentState)
    )
});
export const LITIGATION_MAIN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Litigation>, Litigation>>('LITIGATION_MAIN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Litigation>, Litigation>(
      PROJECT_NAME,
      'Litigation', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LITIGATION_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.litigationCombinedState.litigationState)
    )
});
export const OTHER_MATTER_MAIN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<OtherMatter>, OtherMatter>>('OTHER_MATTER_MAIN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<OtherMatter>, OtherMatter>(
      PROJECT_NAME,
      'Other Matter', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(OTHER_MATTER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.otherMatterCombinedState.otherMatterState)
    )
});

export const GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Matter Attachment Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Matter ReviewerHistory Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Matter Participants Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);

export const GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Matter Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_MATTER_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Matter LedgerTransactions Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const TRANSFER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Transfer>, Transfer>>('TRANSFER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Transfer>, Transfer>(
      PROJECT_NAME,
      'Transfer', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(TRANSFER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.transferCombinedState.transferState)
    )
});
export const TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>('TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
      PROJECT_NAME,
      'Transfer_TempLink', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(TRANSFER_TEMPLINK_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
    )
});

export const LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'LibraryDocumentAttachmentLink', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(LIBRARY_DOCUMENT_ATTACHMENT_LINK_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
      )
  }
);
export const COMPANY_PICKLIST_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<CompanyPicklist>, CompanyPicklist>>('COMPANY_PICKLIST_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<CompanyPicklist>, CompanyPicklist>(
      PROJECT_NAME,
      'CompanyPicklist', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(COMPANY_PICKLIST_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.companyPicklistCombinedState.companyPicklistState)
    )
});

export const LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>('LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
      PROJECT_NAME,
      'LibraryDocumentFileObject', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(LIBRARY_DOCUMENT_FILE_OBJECT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
    )
});
export const COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>(
  'COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
        PROJECT_NAME,
        'CompanyEntityLogoFileObject', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(COMPANY_ENTITY_LOGO_FILE_OBJECT_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
      )
  }
);
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/

/* *******************************************************************************
 * *******************************************************************************
 *
 * CODE BELOW IS EXCLUSIVELY FOR MATTER REDUX OBJECTS. THESE ARE CHILD OBJECTS FOR 4 MATTER TYPES
 * THAT ARE ALMOST IDENTICAL BUT HAVE DIFFERENT ENDPOINTS. INSTEAD OF COPY-PASTING A TON OF REPETITIVE
 * INJECTION STATEMENTS WE NOW USE A HANDFUL OF AUTOMATION FUNCITONS BELOW TO REDUCE REPETITION
 *
 */

// Map of all matter redux objects that are auto-created
export const matterROMap: Map<string, InjectionToken<any>> = new Map<string, InjectionToken<any>>();

// Child enum/name. Used for convention-based map key and action name generation
export enum MatterReduxObjectType {
  Note = 'Note',
  Participant = 'Participant',
  Link = 'Link',
  TransferActivity = 'TransferActivity',
  Correspondence = 'Correspondence',
  CorrespondenceAttachment = 'CorrespondenceAttachment',
  CorrespondenceAttachmentFileObject = 'CorrespondenceAttachmentFileObject',
  AttachmentTemplate = 'AttachmentTemplate',
  TemplateField = 'TemplateField',
  AllowedUser = 'AllowedUser',
  CorrespondenceResend = 'CorrespondenceResend',
  AttachmentMetadata = 'AttachmentMetadata',
  AttachmentFileObject = 'AttachmentFileObject',
  AttachmentFolder = 'AttachmentFolder',
  SubjectSearch = 'SubjectSearch',
  SubjectSearchTempLink = 'SubjectSearchTempLink',
  CorrespondenceAttachmentTempLink = 'CorrespondenceAttachmentTempLink',
  MatterAttachmentTempLink = 'MatterAttachmentTempLink',
  MatterAttachmentZipLink = 'MatterAttachmentZipLink',
  AttachmentFolderMover = 'AttachmentFolderMover',
  AttachmentMover = 'AttachmentMover'
}
/**
 * This functino is used internally within data-access module to find the right redux object for effects and mappers
 * Simply uses the same naming convention that generators use to find the right object.
 * @param matterType DOC/GAR/LIT/OTH
 * @param objectName
 * @returns
 */
export const getMatterReduxObject = (matterType: MatterType, objectName: MatterReduxObjectType) => {
  const result = matterROMap.get(MatterType[matterType] + '_' + objectName);
  return result;
};
/**
 * This function is used to generate each of the matter redux objects.
 * For example on how to call it,  just look at setMatterReduxObjects()
 * @param key MatterType enum STRING value
 * @param objectName Child object name (link, correspondnece, etc)
 * @param stateMappings Redux state projector functions, 1 for each matter type
 * @param hasFileTransfer If TRUE it will also inject file transfer service from matterServiceMap using the naming convention
 * @returns
 */
const addObjectToMatterReduxObjectMappings = <T extends SafariObject>(objectName: MatterReduxObjectType, stateMappings: Map<MatterType, any>, hasFileTransfer = false) => {
  for (const key of Object.keys(MatterType).filter(x => isNaN(x as any))) {
    const stateProjector = stateMappings.get(MatterType[key]);
    let token = null;

    const prefix = (key + '_' + objectName).toUpperCase();
    const serviceKey = (key + '_' + objectName).toUpperCase() + '_REDUX_SERVICE_TOKEN';

    if (hasFileTransfer) {
      token = new InjectionToken<SafariReduxApiObject<ISafariObjectState<T>, T>>(prefix + '_REDUX_OBJECT_TOKEN', {
        providedIn: 'root',
        factory: () =>
          new SafariReduxApiObject<ISafariObjectState<T>, T>(PROJECT_NAME, key + ' ' + objectName, inject(matterServiceMap.get(serviceKey)), createSelector(getSharedFeatureState, stateProjector))
      });
    } else {
      token = new InjectionToken<SafariReduxApiObject<ISafariObjectState<T>, T>>(prefix + '_REDUX_OBJECT_TOKEN', {
        providedIn: 'root',
        factory: () =>
          new SafariReduxApiObject<ISafariObjectState<T>, T>(PROJECT_NAME, key + ' ' + objectName, inject(matterServiceMap.get(serviceKey)), createSelector(getSharedFeatureState, stateProjector))
      });
    }

    matterROMap.set(key + '_' + objectName, token);
  }
};
/**
 * ALL CHILDREN ROs. MAINLY ALL IT IS ABOUT IS SETTING THE CHILD TYPE AND 4 STATE PROJECTORS FOR EACH OF THE MATTERS
 */
export const setMatterReduxObjects = () => {
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.Note,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestNoteState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentNoteState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationNoteState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterNoteState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.Participant,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestParticipantState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentParticipantState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationParticipantState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterParticipantState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.Link,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestLinkState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentLinkState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationLinkState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterLinkState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.TransferActivity,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestTransferActivityState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentTransferActivityState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationTransferActivityState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterTransferActivityState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.Correspondence,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestCorrespondenceState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentCorrespondenceState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationCorrespondenceState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterCorrespondenceState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.CorrespondenceAttachment,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestCorrespondenceAttachmentState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentCorrespondenceAttachmentState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationCorrespondenceAttachmentState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterCorrespondenceAttachmentState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.CorrespondenceAttachmentFileObject,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentTemplate,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestAttachmentTemplateState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentAttachmentTemplateState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationAttachmentTemplateState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterAttachmentTemplateState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.TemplateField,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestTemplateFieldState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentTemplateFieldState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationTemplateFieldState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterTemplateFieldState]
    ])
  );

  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AllowedUser,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestAllowedUserState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentAllowedUserState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationAllowedUserState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterAllowedUserState]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.CorrespondenceResend,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestCorrespondenceResendState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentCorrespondenceResendState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationCorrespondenceResendState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterCorrespondenceResendState]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentMetadata,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestAttachmentMetadataState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentAttachmentMetadataState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationAttachmentMetadataState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterAttachmentMetadataState]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentFileObject,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentFolder,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestAttachmentFolderState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentAttachmentFolderState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationAttachmentFolderState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterAttachmentFolderState]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.SubjectSearch,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, state => state.infoRequestCombinedState.infoRequestSubjectSearchState],
      [MatterType.Garnishment, state => state.garnishmentCombinedState.garnishmentSubjectSearchState],
      [MatterType.Litigation, state => state.litigationCombinedState.litigationSubjectSearchState],
      [MatterType.Other, state => state.otherMatterCombinedState.otherMatterSubjectSearchState]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.SubjectSearchTempLink,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.CorrespondenceAttachmentTempLink,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.MatterAttachmentTempLink,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.MatterAttachmentZipLink,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentFolderMover,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
  addObjectToMatterReduxObjectMappings(
    MatterReduxObjectType.AttachmentMover,
    new Map<MatterType, any>([
      [MatterType.DocumentRequest, SafariObject.EMPTY],
      [MatterType.Garnishment, SafariObject.EMPTY],
      [MatterType.Litigation, SafariObject.EMPTY],
      [MatterType.Other, SafariObject.EMPTY]
    ])
  );
};
