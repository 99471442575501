import { Injectable } from '@angular/core';

import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DateTime } from 'luxon';
import { Country, CreationSourceType, MatterListModel, State } from '../../_lpms-api-models.generated';
import { Participant } from '../../participant/models/app/participant';
import { MatterAdapterHelper } from '../../utils';
import { MatterCreate } from '../models/api/matter-create';
import { MatterRead } from '../models/api/matter-read';
import { MatterUpdate } from '../models/api/matter-update';
import { Matter, MatterForList } from '../models/app/matter';

@Injectable({
  providedIn: 'root'
})
export class AllMattersAdapter extends ApiDataAdapter<Matter> {
  constructor(protected _matterAdapterHelper: MatterAdapterHelper) {
    super();
  }

  fromListModel(matter: MatterListModel): Required<MatterForList> {
    return {
      forumSameAsIssuingAuthority: undefined,
      type: matter.type,
      id: this.helpers.fromApiId(matter.id),
      safariId: matter.safariId,
      entityServedId: this.helpers.fromApiId(matter.entityServedId),
      otherNameServed: matter.otherNameServed,
      currentDueDate: matter.currentDueDate,
      matterSubtypeId: matter.matterSubtypeId,
      stateReceived: this.helpers.fromApiId(matter.stateReceived),
      issuingAuthority: matter.servingPartyOrgName,
      forumTypeAndName: matter.forumTypeAndName,
      plaintiffs: matter.plaintiffs,
      defendants: matter.defendants,
      matterName: matter.matterName,
      status: matter.status,
      subjects: this._matterAdapterHelper.parseSubjectsForList(matter.subjects),
      reviewerId: this.helpers.fromApiId(matter.reviewerId),

      servingPartyContact: matter.servingPartyContact,
      servingPartyEmail: matter.servingPartyEmail,
      servingPartyAdditionalEmail: undefined,
      servingPartyPhone: undefined,
      servingPartyPhoneExtension: undefined,
      servingPartyFax: matter.servingPartyFax,
      servingPartyMobile: undefined,
      caseNumber: matter.caseNumber,
      clientMatterNumber: matter.clientMatterNumber,
      dateFiled: undefined,
      dateReceived: this.helpers.fromApiDate(matter.dateReceived),
      searchTerms: matter.searchTerms ? [...matter.searchTerms] : [],
      documentTitle: undefined,

      servingPartyOrgType: null,
      servingPartyOrgName: matter.servingPartyOrgName,
      servingPartyOrgAddress: {
        city: undefined,
        country: undefined,
        street2: undefined,
        zipCode: undefined,
        street1: matter.servingPartyAddress1,
        state: matter.servingPartyState
      },
      issuingAuthorityType: undefined,
      issuingAuthorityName: undefined,
      issuingAuthorityAddress: undefined,

      forumContactAddress: undefined,
      methodOfService: matter.methodOfService,
      receivedBy: undefined,

      isStarred: matter.isStarred,
      otherInfo: matter.otherInfo,
      orgUnitOrDivision: matter.orgUnitOrDivision,

      noteCount: matter.noteCount,

      alerts: undefined,

      financialNotes: undefined,

      hasEntityNameDiscrepancy: undefined,
      hasRestrictedAccess: matter.hasRestrictedAccess,
      internalReferenceId: matter.internalReferenceId,

      participants: undefined,
      closedReason: matter.closedReason,
      deliveryStatus: matter.informationRequestPortalStatus,
      creationSourceName: undefined,
      creationSourceType: undefined,
      transferMethod: undefined,
      linkCount: matter.linkCount,

      notDomesticatedServiceResolution: undefined,
      noJurisdictionResolution: undefined,
      excludeFromRetentionPolicy: undefined,
      dateAttachmentsPurged: undefined,
      bsaNotificationRequired: matter.bsaNotificationRequired,
      bsaReviewDate: matter.bsaReviewDate,
      teamId: undefined,
      caseType: matter.caseType,
      caseSubtypeIds: matter.caseSubtypeIds as unknown as string[],
      caseSubtypeText: undefined,
      notifiedTeamIds: undefined,
      legalOrderFeeOrPrepaidAmount: undefined,
      legalOrderFeeOrPrepaidCheckNumber: undefined,
      disclosureProhibited: undefined,
      garnishmentLevyOrderAmount: matter.garnishmentLevyOrderAmount
    };
  }

  fromCommonGetModel(matter: MatterRead): Required<Matter> {
    return {
      type: null, // child overrides populate this
      currentDueDate: this.helpers.fromApiDate(matter.currentDueDate),
      forumSameAsIssuingAuthority: matter.forumSameAsIssuingAuthority,
      alerts: this._matterAdapterHelper.parseAlertsForGet(matter.alerts),
      id: this.helpers.fromApiId(matter.id),
      safariId: matter.safariId,
      entityServedId: this.helpers.fromApiId(matter.entityServedId),
      otherNameServed: matter.otherNameServed,
      matterSubtypeId: matter.matterSubtypeId,
      legalOrderFeeOrPrepaidAmount: matter.legalOrderFeeOrPrepaidAmount,
      legalOrderFeeOrPrepaidCheckNumber: matter.legalOrderFeeOrPrepaidCheckNumber,
      forumTypeAndName: matter.forumTypeAndName,
      plaintiffs: matter.plaintiffs,
      defendants: matter.defendants,
      matterName: matter.matterName,
      status: matter.status,
      reviewerId: this.helpers.fromApiId(matter.reviewerId),

      servingPartyContact: matter.servingPartyContact,
      servingPartyEmail: matter.servingPartyEmail,
      servingPartyAdditionalEmail: matter.servingPartyAdditionalEmail,
      ...this._matterAdapterHelper.getServingPartyPhonesForGet(matter.servingPartyPhoneNumbers),

      participants: matter.participants
        ? matter.participants.map(
            x =>
              ({
                email: x.email,
                firstName: x.firstName,
                fullName: x.fullName,
                id: x.id.toString(),
                isActive: x.isActive,
                lastName: x.lastName,
                participationLevel: x.participationLevel,

                teamId: x.teamId as unknown as string,
                securityRole: x.securityRole
              }) as Participant
          )
        : [],
      closedReason: matter.closedReason,
      creationSourceName: matter.creationSourceName,
      creationSourceType: matter.creationSourceType,

      transferMethod: matter.transferMethod,

      caseNumber: matter.caseNumber,
      clientMatterNumber: matter.clientMatterNumber,
      dateFiled: this.helpers.fromApiDate(matter.dateFiled),
      dateReceived: this.helpers.fromApiDate(matter.dateReceived),
      subjects: this._matterAdapterHelper.parseSubjectsForGet(matter.subjects),
      documentTitle: matter.documentTitle,
      servingPartyOrgType: matter.servingPartyOrgType,
      servingPartyOrgName: matter.servingPartyOrgName,
      servingPartyOrgAddress: matter.servingPartyOrgAddress
        ? {
            city: matter.servingPartyOrgAddress.city,
            country: matter.servingPartyOrgAddress.country,
            state: matter.servingPartyOrgAddress.state,
            street1: matter.servingPartyOrgAddress.street1,
            street2: matter.servingPartyOrgAddress.street2,
            zipCode: matter.servingPartyOrgAddress.zipCode
          }
        : null,
      issuingAuthorityType: matter.issuingAuthorityType,
      issuingAuthorityName: matter.issuingAuthorityName,
      issuingAuthorityAddress: matter.issuingAuthorityAddress
        ? {
            city: matter.issuingAuthorityAddress.city,
            country: matter.issuingAuthorityAddress.country,
            state: matter.issuingAuthorityAddress.state,
            street1: matter.issuingAuthorityAddress.street1,
            street2: matter.issuingAuthorityAddress.street2,
            zipCode: matter.issuingAuthorityAddress.zipCode
          }
        : null,

      forumContactAddress: matter.forumContactAddress
        ? {
            city: matter.forumContactAddress.city,
            country: matter.forumContactAddress.country,
            state: matter.forumContactAddress.state,
            street1: matter.forumContactAddress.street1,
            street2: matter.forumContactAddress.street2,
            zipCode: matter.forumContactAddress.zipCode
          }
        : null,
      methodOfService: matter.methodOfService,
      receivedBy: matter.receivedBy,

      isStarred: matter.isStarred,
      otherInfo: matter.otherInfo,
      orgUnitOrDivision: matter.orgUnitOrDivision,

      noteCount: matter.noteCount,
      linkCount: matter.linkCount,

      financialNotes: matter.financialNotes,

      hasEntityNameDiscrepancy: matter.hasEntityNameDiscrepancy,
      hasRestrictedAccess: matter.hasRestrictedAccess,
      internalReferenceId: matter.internalReferenceId,

      stateReceived: matter.stateReceived?.toString(),

      issuingAuthority: null,

      notDomesticatedServiceResolution: matter.notDomesticatedServiceResolution,
      noJurisdictionResolution: matter.noJurisdictionResolution,
      excludeFromRetentionPolicy: matter.excludeFromRetentionPolicy,
      dateAttachmentsPurged: this.helpers.fromApiDate(matter.dateAttachmentsPurged),
      teamId: matter.teamId as unknown as string,
      caseType: matter.caseType,
      caseSubtypeIds: matter.caseSubtypeIds,
      caseSubtypeText: matter.caseSubtypeText,
      notifiedTeamIds: matter.notifiedTeamIds,
      disclosureProhibited: matter.disclosureProhibited,
      garnishmentLevyOrderAmount: null
    };
  }
  fromGetModel(matter: MatterListModel): Required<Matter> {
    return {
      // we'll need to get better mapping for lookup, but right now lookup is using get model from matter
      // but the api is returning apilistmodel, so this is a quick fix
      type: matter.type,
      forumSameAsIssuingAuthority: null, // matter.forumSameAsIssuingAuthority,
      currentDueDate: this.helpers.fromApiDate(matter.currentDueDate),
      alerts: [],
      teamId: undefined,
      id: this.helpers.fromApiId(matter.id),
      safariId: matter.safariId,
      entityServedId: this.helpers.fromApiId(matter.entityServedId),
      otherNameServed: matter.otherNameServed,

      matterSubtypeId: matter.matterSubtypeId,

      forumTypeAndName: matter.forumTypeAndName,
      plaintiffs: matter.plaintiffs,
      defendants: matter.defendants,
      matterName: matter.matterName,
      status: null,
      reviewerId: this.helpers.fromApiId(matter.reviewerId),

      servingPartyContact: null,
      servingPartyEmail: matter.servingPartyEmail,
      servingPartyAdditionalEmail: null,
      servingPartyPhone: null,
      servingPartyPhoneExtension: null,
      servingPartyFax: null,
      servingPartyMobile: null,

      participants: null,
      closedReason: null,
      creationSourceName: null,
      creationSourceType: null,

      transferMethod: null,

      caseNumber: matter.caseNumber,
      clientMatterNumber: matter.clientMatterNumber,
      dateFiled: null,
      dateReceived: null,
      subjects: this._matterAdapterHelper.parseSubjectsForGet(matter.subjects),
      documentTitle: null,

      servingPartyOrgType: null,
      servingPartyOrgName: matter.servingPartyOrgName,
      servingPartyOrgAddress: null,

      issuingAuthorityType: null,
      issuingAuthorityName: null,
      issuingAuthorityAddress: null,

      forumContactAddress: null,
      methodOfService: matter.methodOfService,
      receivedBy: null,

      isStarred: null,
      otherInfo: matter.otherInfo,
      orgUnitOrDivision: matter.orgUnitOrDivision,

      noteCount: 0,
      linkCount: 0,

      financialNotes: null,

      hasEntityNameDiscrepancy: null,
      hasRestrictedAccess: matter.hasRestrictedAccess,
      internalReferenceId: matter.internalReferenceId,

      stateReceived: this.helpers.fromApiId(matter.stateReceived),

      issuingAuthority: null,

      notDomesticatedServiceResolution: null,
      noJurisdictionResolution: null,
      excludeFromRetentionPolicy: undefined,
      dateAttachmentsPurged: undefined,

      caseType: undefined,
      caseSubtypeIds: undefined,
      caseSubtypeText: undefined,
      notifiedTeamIds: undefined,
      legalOrderFeeOrPrepaidAmount: undefined,
      legalOrderFeeOrPrepaidCheckNumber: undefined,
      disclosureProhibited: undefined,
      garnishmentLevyOrderAmount: matter.garnishmentLevyOrderAmount
    };
  }
}
@Injectable({
  providedIn: 'root'
})
export class MatterAdapter extends AllMattersAdapter {
  constructor(matterAdapterHelper: MatterAdapterHelper) {
    super(matterAdapterHelper);
  }

  fromGetNewModel(): SafariRequired<Matter> {
    const currentDate = DateTime.now().toISO();
    return {
      id: ObjectHelper.getNewId(),
      forumSameAsIssuingAuthority: false,
      safariId: '',
      servingPartyContact: null,
      servingPartyEmail: null,
      servingPartyAdditionalEmail: null,
      servingPartyPhone: null,
      servingPartyPhoneExtension: null,
      servingPartyFax: null,
      servingPartyMobile: null,
      caseNumber: null,
      clientMatterNumber: null,
      dateFiled: null,
      dateReceived: currentDate,
      defendants: null,
      documentTitle: null,
      servingPartyOrgType: 0,
      servingPartyOrgName: null,
      servingPartyOrgAddress: null,
      issuingAuthorityType: 0,
      issuingAuthorityName: null,
      issuingAuthorityAddress: null,
      forumContactAddress: null,
      methodOfService: null,
      plaintiffs: null,
      matterName: null,
      receivedBy: null,
      status: null,
      matterSubtypeId: null,
      reviewerId: null,
      type: null,
      forumTypeAndName: '',

      isStarred: false,
      otherInfo: '',

      alerts: [],

      noteCount: 0,
      linkCount: 0,
      entityServedId: null,
      otherNameServed: null,

      subjects: [],
      attachmentTemplates: [],

      hasEntityNameDiscrepancy: null,
      hasRestrictedAccess: null,
      internalReferenceId: null,

      stateReceived: null,
      financialNotes: null,
      issuingAuthority: null,

      noJurisdictionResolution: null,
      notDomesticatedServiceResolution: null,

      caseType: undefined,
      caseSubtypeIds: undefined,
      caseSubtypeText: undefined,
      notifiedTeamIds: undefined
    } as any;
  }

  toCreateModel(matter: Matter): Required<MatterCreate> {
    return {
      matterSubtypeId: matter.matterSubtypeId,
      dateReceived: this.helpers.toApiDate(matter.dateReceived),
      stateReceived: matter.stateReceived as unknown as State,
      entityServedId: null,
      creationSourceType: CreationSourceType.Manual,
      creationSourceName: null,
      reviewerId: this.helpers.toApiId(matter.reviewerId),
      dateCreated: null,
      disclosureProhibited: matter.disclosureProhibited
    };
  }
  toUpdateModel(matter: Matter): Required<MatterUpdate> {
    return {
      forumSameAsIssuingAuthority: matter.forumSameAsIssuingAuthority,
      stateReceived: matter.stateReceived as unknown as State,
      matterName: matter.matterName,
      internalReferenceId: matter.internalReferenceId,
      subjects: matter.subjects ? this._matterAdapterHelper.parseSubjectsForUpdate(matter.subjects) : matter.subjects,
      hasEntityNameDiscrepancy: matter.hasEntityNameDiscrepancy,

      hasRestrictedAccess: matter.hasRestrictedAccess,
      caseNumber: matter.caseNumber,
      clientMatterNumber: matter.clientMatterNumber,
      dateFiled: matter.dateFiled ? this.helpers.toApiDate(matter.dateFiled) : matter.dateFiled,
      dateReceived: matter.dateReceived ? this.helpers.toApiDate(matter.dateReceived) : matter.dateReceived,
      defendants: matter.defendants,
      status: matter.status as unknown as any,
      documentTitle: matter.documentTitle,
      servingPartyContact: matter.servingPartyContact,
      servingPartyPhoneNumbers:
        typeof matter.servingPartyFax === 'undefined' && typeof matter.servingPartyMobile === 'undefined' && typeof matter.servingPartyPhone == 'undefined'
          ? undefined
          : this._matterAdapterHelper.parseServingPartyPhonesForUpdate(matter),
      servingPartyEmail: matter.servingPartyEmail,
      servingPartyAdditionalEmail: matter.servingPartyAdditionalEmail,
      servingPartyOrgType: matter.servingPartyOrgType,
      servingPartyOrgName: matter.servingPartyOrgName,
      servingPartyOrgAddress: matter.servingPartyOrgAddress
        ? {
            city: matter.servingPartyOrgAddress.city,
            country: matter.servingPartyOrgAddress.country as Country,
            state: matter.servingPartyOrgAddress.state as State,
            street1: matter.servingPartyOrgAddress.street1,
            street2: matter.servingPartyOrgAddress.street2,
            zipCode: matter.servingPartyOrgAddress.zipCode
          }
        : matter.servingPartyOrgAddress,
      issuingAuthorityType: matter.issuingAuthorityType,
      issuingAuthorityName: matter.issuingAuthorityName,
      issuingAuthorityAddress: matter.issuingAuthorityAddress
        ? {
            city: matter.issuingAuthorityAddress.city,
            country: matter.issuingAuthorityAddress.country as Country,
            state: matter.issuingAuthorityAddress.state as State,
            street1: matter.issuingAuthorityAddress.street1,
            street2: matter.issuingAuthorityAddress.street2,
            zipCode: matter.issuingAuthorityAddress.zipCode
          }
        : matter.issuingAuthorityAddress,

      forumContactAddress:
        matter.forumSameAsIssuingAuthority === true
          ? null
          : matter.forumContactAddress
            ? {
                city: matter.forumContactAddress.city,
                country: matter.forumContactAddress.country as Country,
                state: matter.forumContactAddress.state as State,
                street1: matter.forumContactAddress.street1,
                street2: matter.forumContactAddress.street2,
                zipCode: matter.forumContactAddress.zipCode
              }
            : matter.forumContactAddress,
      methodOfService: matter.methodOfService,
      plaintiffs: matter.plaintiffs,
      receivedBy: matter.receivedBy,
      matterSubtypeId: matter.matterSubtypeId,
      forumTypeAndName: matter.forumSameAsIssuingAuthority ? null : matter.forumTypeAndName,
      reviewerId: matter.reviewerId != null ? (matter.reviewerId as unknown as number) : (matter.reviewerId as any),
      entityServedId: matter.entityServedId != null ? (matter.entityServedId as unknown as number) : (matter.entityServedId as any),
      otherNameServed: matter.otherNameServed,
      otherInfo: matter.otherInfo,
      orgUnitOrDivision: matter.orgUnitOrDivision,
      financialNotes: matter.financialNotes,
      closedReason: matter.closedReason as unknown as any,
      noJurisdictionResolution: matter.noJurisdictionResolution,
      notDomesticatedServiceResolution: matter.notDomesticatedServiceResolution,
      excludeFromRetentionPolicy: matter.excludeFromRetentionPolicy,
      teamId: matter.teamId as unknown as number,
      caseType: matter.caseType,
      caseSubtypeIds: matter.caseSubtypeIds,
      caseSubtypeText: matter.caseSubtypeText,
      legalOrderFeeOrPrepaidAmount: matter.legalOrderFeeOrPrepaidAmount,
      legalOrderFeeOrPrepaidCheckNumber: matter.legalOrderFeeOrPrepaidCheckNumber,
      disclosureProhibited: matter.disclosureProhibited,
      notifiedTeamIds: matter.notifiedTeamIds
    };
  }
}
