/* eslint-disable no-restricted-syntax -- This is a framework level file so it's ok */

import { inject, InjectionToken } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { EffectWithIdentifier, GenericDropdownEffects, HttpRequestBehaviorType } from '@safarilaw-webapp/shared/redux';
import { CompanySettings } from '../company-settings/models/app/company-settings';

import { DocumentAlert } from '../document-alert/models/app/document-alert';

import { DropdownMatterSubtype } from '../dropdown/dropdown-matter-subtype/models/app/dropdown-matter-subtype';
import { InvoiceTemplate } from '../invoice-template/models/app/invoice-template';
import { LibraryDocument } from '../library/models/app/library-document';
import { LibraryTemplate } from '../library/models/app/library-template';
import { MatterCount } from '../matter-count/models/app/matter-count';
import { MatterSearch } from '../matter-search/models/matter-search';
import { Matter } from '../matter/models/app/matter';
import { Report } from '../reports/models/app/report';
import { ServingParty } from '../serving-party/models/app/serving-party';

import { CompanyEntity } from '../company-entity/models/app/company-entity';
import { DocumentRequest } from '../document-request/models/app/document-request';
import { DropdownType, MatterType } from '../enums';
import { Garnishment } from '../garnishment/models/app/garnishment';
import { LibraryFolderList } from '../library/models/app/library-folder-list';
import { Litigation } from '../litigation/models/app/litigation';
import { OtherMatter } from '../other-matter/models/app/other-matter';
import { ServingPartySearch } from '../serving-party-search/models/serving-party-search';
import {
  ALL_MATTERS_REDUX_OBJECT_TOKEN,
  CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN,
  COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  COMPANY_ENTITY_REDUX_OBJECT_TOKEN,
  COMPANY_PICKLIST_REDUX_OBJECT_TOKEN,
  DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN,
  DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN,
  DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  EXTEND_PORTAL_REDUX_OBJECT_TOKEN,
  GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_OBJECT_TOKEN,
  getMatterReduxObject,
  INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN,
  INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN,
  LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN,
  LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN,
  LITIGATION_MAIN_REDUX_OBJECT_TOKEN,
  LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN,
  MATTER_ALERTS_REDUX_OBJECT_TOKEN,
  MATTER_COUNTS_REDUX_OBJECT_TOKEN,
  MATTER_LOOKUP_REDUX_OBJECT_TOKEN,
  MATTER_SEARCH_REDUX_OBJECT_TOKEN,
  MatterReduxObjectType,
  ORGANIZATION_REDUX_OBJECT_TOKEN,
  ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN,
  OTHER_MATTER_MAIN_REDUX_OBJECT_TOKEN,
  RECENT_MATTER_REDUX_OBJECT_TOKEN,
  REPORT_REDUX_OBJECT_TOKEN,
  RESEND_ANSWER_REDUX_OBJECT_TOKEN,
  RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN,
  SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN,
  SERVING_PARTY_REDUX_OBJECT_TOKEN,
  SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN,
  STARRED_MATTER_REDUX_OBJECT_TOKEN,
  TRANSFER_REDUX_OBJECT_TOKEN,
  TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN,
  UPDATE_PORTAL_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';

import { CompanyPicklist } from '../company-picklist/models/app/company-picklist';
import { OrganizationSearch } from '../organization-search/models/organization-search';
import { Organization } from '../organization/models/app/organization';
import { Transfer } from '../transfer/models/app/transfer';
import { ILpmsStateDropdown } from './state.interface';

const getCommonMatterChildren = (matterType: MatterType) => [
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.Link)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.Note)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.Participant)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.TransferActivity)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.Correspondence)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.CorrespondenceAttachment)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.CorrespondenceAttachmentFileObject)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentTemplate)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.TemplateField)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AllowedUser)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.CorrespondenceResend)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentMetadata)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentFileObject)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentFolder)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.SubjectSearch)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.SubjectSearchTempLink)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.MatterAttachmentTempLink)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.CorrespondenceAttachmentTempLink)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.MatterAttachmentZipLink)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentFolderMover)),
  inject(getMatterReduxObject(matterType, MatterReduxObjectType.AttachmentMover))
];
export const getEffectTokens = () => [
  new InjectionToken<EffectWithIdentifier<MatterSearch>>('MATTER_SEARCH_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<MatterSearch>(inject(Store), inject(Actions), inject(MATTER_SEARCH_REDUX_OBJECT_TOKEN), null, {
        get: HttpRequestBehaviorType.Switch,
        getAll: HttpRequestBehaviorType.Switch,
        createOrUpdate: HttpRequestBehaviorType.Switch
      })
  }),

  new InjectionToken<EffectWithIdentifier<OrganizationSearch>>('ORGANIZATION_SEARCH_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<OrganizationSearch>(inject(Store), inject(Actions), inject(ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN), null, {
        get: HttpRequestBehaviorType.Switch,
        getAll: HttpRequestBehaviorType.Switch,
        createOrUpdate: HttpRequestBehaviorType.Switch
      })
  }),

  new InjectionToken<EffectWithIdentifier<Organization>>('ORGANIZATION_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Organization>(inject(Store), inject(Actions), inject(ORGANIZATION_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<EffectWithIdentifier<ServingPartySearch>>('SERVING_PARTY_SEARCH_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<ServingPartySearch>(inject(Store), inject(Actions), inject(SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN), null, {
        get: HttpRequestBehaviorType.Switch,
        getAll: HttpRequestBehaviorType.Switch,
        createOrUpdate: HttpRequestBehaviorType.Switch
      })
  }),

  new InjectionToken<EffectWithIdentifier<ServingParty>>('SERVING_PARTY_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<ServingParty>(inject(Store), inject(Actions), inject(SERVING_PARTY_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<EffectWithIdentifier<InvoiceTemplate>>('INVOICE_TEMPLATE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<InvoiceTemplate>(inject(Store), inject(Actions), inject(INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN), [inject(INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN)])
  }),
  new InjectionToken<EffectWithIdentifier<MatterCount>>('MATTER_COUNT_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<MatterCount>(inject(Store), inject(Actions), inject(MATTER_COUNTS_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<GenericDropdownEffects<ILpmsStateDropdown>>('DROPDOWN_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new GenericDropdownEffects<ILpmsStateDropdown>(
        inject(Actions),
        inject(Store),
        inject(DROPDOWN_REDUX_OBJECT_TOKEN),
        [
          // Our DropdownType enum is a mix of string and number values so it confuses TS compiler even
          // when the value we're taking IS a string. So we'll just toString() all the compound IDs
          SafariObject.id(MatterType.DocumentRequest, DropdownType.Participants),
          SafariObject.id(MatterType.Garnishment, DropdownType.Participants),
          SafariObject.id(MatterType.Other, DropdownType.Participants),
          SafariObject.id(MatterType.Litigation, DropdownType.Participants),
          DropdownType.InvoiceTemplates,
          SafariObject.id(MatterType.DocumentRequest, DropdownType.Reviewers),
          SafariObject.id(MatterType.Garnishment, DropdownType.Reviewers),
          SafariObject.id(MatterType.Other, DropdownType.Reviewers),
          SafariObject.id(MatterType.Litigation, DropdownType.Reviewers),
          DropdownType.LibraryTemplates,
          DropdownType.MatterSubtypes,
          DropdownType.Users
        ],
        null
      )
  }),
  new InjectionToken<EffectWithIdentifier<Matter>>('STARRED_MATTER_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Matter>(inject(Store), inject(Actions), inject(STARRED_MATTER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Matter>>('RECENT_MATTER_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Matter>(inject(Store), inject(Actions), inject(RECENT_MATTER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Matter>>('ALL_MATTERS_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Matter>(inject(Store), inject(Actions), inject(ALL_MATTERS_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<EffectWithIdentifier<LibraryDocument>>('LIBRARY_DOCUMENT_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<LibraryDocument>(inject(Store), inject(Actions), inject(LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<LibraryTemplate>>('LIBRARY_TEMPLATE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<LibraryTemplate>(inject(Store), inject(Actions), inject(LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<LibraryFolderList>>('LIBRARY_FOLDER_LIST_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<LibraryFolderList>(inject(Store), inject(Actions), inject(LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<CompanyEntity>>('COMPANY_ENTITY_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<CompanyEntity>(inject(Store), inject(Actions), inject(COMPANY_ENTITY_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<DropdownMatterSubtype>>('DROPDOWN_MATTER_SUBTYPE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<DropdownMatterSubtype>(inject(Store), inject(Actions), inject(DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN), [inject(DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN)])
  }),
  new InjectionToken<EffectWithIdentifier<DocumentAlert>>('MATTER_ALERTS_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<DocumentAlert>(inject(Store), inject(Actions), inject(MATTER_ALERTS_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Report>>('REPORT_REDUX_EFFECT"', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Report>(inject(Store), inject(Actions), inject(REPORT_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Matter>>('MATTER_LOOKUP_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Matter>(inject(Store), inject(Actions), inject(MATTER_LOOKUP_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('CHANGE_SUBTYPE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('EXTEND_PORTAL_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(EXTEND_PORTAL_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('SEND_PORTAL_REMINDER_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('UPDATE_PORTAL_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(UPDATE_PORTAL_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('RESEND_PORTAL_INVITE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('RESND_ANSWER_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(RESEND_ANSWER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<CompanySettings>>('LPMS_COMPANY_SETTINGS_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<CompanySettings>(inject(Store), inject(Actions), inject(LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Transfer>>('TRANSFER_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Transfer>(inject(Store), inject(Actions), inject(TRANSFER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('TRANSFER_TEMPLINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<DocumentRequest>>('DOCUMENT_REQUEST_MAIN_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<DocumentRequest>(
        inject(Store),
        inject(Actions),
        inject(DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN),
        getCommonMatterChildren(MatterType.DocumentRequest).concat([inject(DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN), inject(DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN)])
      )
  }),
  new InjectionToken<EffectWithIdentifier<Garnishment>>('GARNISHMENT_MAIN_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Garnishment>(inject(Store), inject(Actions), inject(GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN), getCommonMatterChildren(MatterType.Garnishment))
  }),
  new InjectionToken<EffectWithIdentifier<Litigation>>('LITIGATION_MAIN_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Litigation>(inject(Store), inject(Actions), inject(LITIGATION_MAIN_REDUX_OBJECT_TOKEN), getCommonMatterChildren(MatterType.Litigation))
  }),
  new InjectionToken<EffectWithIdentifier<OtherMatter>>('OTHER_MATTER_MAIN_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<OtherMatter>(inject(Store), inject(Actions), inject(OTHER_MATTER_MAIN_REDUX_OBJECT_TOKEN), getCommonMatterChildren(MatterType.Other))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<CompanyPicklist>>('COMPANY_PICKLIST_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<CompanyPicklist>(inject(Store), inject(Actions), inject(COMPANY_PICKLIST_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<FileObject>>('LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<FileObject>(inject(Store), inject(Actions), inject(LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<FileObject>>('COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<FileObject>(inject(Store), inject(Actions), inject(COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN))
  })
  /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
];
