import { Injectable } from '@angular/core';
import { PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../../redux/index';

export class UpdateFlagRequest {
  id: string;
  include: boolean;
}

export class IServingPartyListPageState extends PageUiState {
  includeFlagUpdateRequest: { selected: UpdateFlagRequest[] };
}

export const defaultServingPartyListPageUiState = {
  includeFlagUpdateRequest: null,
  ...defaultPageUiState
};
@Injectable({ providedIn: 'root' })
export class ServingPartyListPageUiReduxObject extends SafariReduxPageUiObject<IServingPartyListPageState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  requestIncludeFlagChange = super.addMessage('Request Include Flag Change', state => state.includeFlagUpdateRequest, { includeFlagUpdateRequest: this.fromAction() });

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.servingPartyListPageState);
    super('Lpms', 'Serving Party List Page', getPageState, defaultServingPartyListPageUiState);
  }
}
