import { Inject, Injectable } from '@angular/core';

import { ISafariObjectState, SafariReduxApiObject } from '@safarilaw-webapp/shared/redux';

import { PortalActivity } from '../portal-activity/models/app/portal-activity';

import { DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN, DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN, MatterReduxObjectType, getMatterReduxObject } from './injectable-object-tokens';

import { MatterType } from '../_lpms-api-models.generated';

import { MatterMapperBase } from './matter-redux-object';

@Injectable({
  providedIn: 'root'
})
export class DocumentRequestMapper extends MatterMapperBase {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-shadow -- disable silly TS error
    @Inject(DOCUMENT_REQUEST_PORTAL_ACTIVITY_REDUX_OBJECT_TOKEN) public PortalActivity: SafariReduxApiObject<ISafariObjectState<PortalActivity>, PortalActivity>,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.TransferActivity)) transferActivity,
    @Inject(DOCUMENT_REQUEST_MAIN_REDUX_OBJECT_TOKEN) infoRequest,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.Note)) note,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentTemplate)) attachmentTemplate,

    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.Correspondence)) correspondence,

    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.CorrespondenceResend)) correspondenceResend,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AllowedUser)) allowedUser,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.Link)) link,

    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.CorrespondenceAttachment)) correspondenceAttachment,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.CorrespondenceAttachmentFileObject)) correspondenceAttachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.Participant)) participant,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.TemplateField)) templateField,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentMetadata)) attachmentMetadata,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentFileObject)) attachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentFolder)) attachmentFolder,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.SubjectSearch)) subjectSearch,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.SubjectSearchTempLink)) subjectSearchTempLink,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.CorrespondenceAttachmentTempLink)) correspondenceAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.MatterAttachmentTempLink)) matterAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.MatterAttachmentZipLink)) matterAttachmentZipLink,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentFolderMover)) attachmentFolderMover,
    @Inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.AttachmentMover)) attachmentMover
  ) {
    super(
      infoRequest,
      transferActivity,
      note,
      attachmentTemplate,
      correspondence,
      correspondenceResend,
      allowedUser,
      link,
      correspondenceAttachment,
      correspondenceAttachmentFileObject,
      participant,
      templateField,
      attachmentMetadata,
      attachmentFileObject,
      attachmentFolder,
      subjectSearch,
      subjectSearchTempLink,
      correspondenceAttachmentTempLink,
      matterAttachmentTempLink,
      matterAttachmentZipLink,
      attachmentFolderMover,
      attachmentMover
    );
  }
}
