import { Injectable } from '@angular/core';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
// eslint-disable-next-line @typescript-eslint/naming-convention -- CKEDITOR global
declare let CKEDITOR: any;
@Injectable({
  providedIn: 'root'
})
export class RichTextEditorGlobalService {
  private _ckEditorInstance: any;
  constructor(private _appConfiguration: AppConfigurationService, private _loggerService: LoggerService) {}
  reparseHtmlFromApi(value: string) {
    // If somehow ckeditor is still not loaded by the time we call this then just return the value as is
    // Worst case scenario they may get a confusing "dirty page" warning but that's better than breaking the app
    // if we don't check for null
    if (this._ckEditorInstance == null) {
      // REVISIT: #4896. We may or may not need to re-enable this guy.
      // this._loggerService.LogError(
      //   { silent: true, error: 'Tried to call reparseHtmlFromApi before initialization', status: RichTextEditorGlobalError.ReparseHtmlCalledBeforeInitialization },
      //   window.location.href
      // );
      return value;
    }
    this._ckEditorInstance.setData(value);
    return this._ckEditorInstance.getData();
  }
  initializeRichTextEditorGlobal() {
    const textArea = document.createElement('textarea');
    textArea.style.cssText = 'position:absolute;top:-3000px;left:-3000px;width:0px;height:0px;display:none;';
    textArea.id = 'rich-text-editor-global';
    let instanceReady = false;
    document.body.appendChild(textArea);

    const config = {
      customConfig: '/assets/js/ckeditor/ckeditor-config.js',
      allowedContent: true,
      extraPlugins: 'sl-placeholder',
      on: {
        instanceReady: (evt: any) => {
          this._ckEditorInstance = evt.editor;
          instanceReady = true;
        }
      }
    };

    CKEDITOR.replace(textArea, config);

    let intervalCount = 0;
    let intervalId = null;
    const milisecsPerInterval = 25;
    const maxAllowedMiliseconds = this._appConfiguration.uiSettings.richTextEditor.initializationTimeoutInMs;

    return new Promise<boolean>(resolve => {
      intervalId = setInterval(() => {
        if (instanceReady === true) {
          clearInterval(intervalId);
          resolve(true);
        } else {
          intervalCount++;
          if (intervalCount * milisecsPerInterval > maxAllowedMiliseconds) {
            clearInterval(intervalId);

            // This won't stop the initialization but we'll bail out.
            // Quite possible that the instance will get initialized by the time they actually use it
            resolve(false);
          }
        }
      }, milisecsPerInterval);
    });
  }
}
