import { Injectable } from '@angular/core';
import { ApiCallContext, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';

@Injectable({
  providedIn: 'root'
})
export class ChangeSubtypeAdapter extends ApiDataAdapter<SafariObject> {
  toUpdateModel(formModel: SafariObject, context?: ApiCallContext): Required<any> {
    return null;
  }
}
