import { Injectable } from '@angular/core';

import { ApiCallContext, IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import '@safarilaw-webapp/shared/utils';
import { CaseType, DropdownType } from '../../enums';
import { DropdownCaseSubtype as ApiDropdownCaseSubtype } from '../dropdown-case-subtype/models/api/dropdown-case-subtype';
import { DropdownCaseSubtype as AppDropdownCaseSubtype } from '../dropdown-case-subtype/models/app/dropdown-case-subtype';
import * as apiCommunicationTypesDropdown from '../dropdown-communication-types/models/api/dropdown-communication-types-list';
import * as apiDeliveryDef from '../dropdown-delivery-method/models/api/dropdown-delivery-method-list';
import * as appDeliveryDef from '../dropdown-delivery-method/models/app/dropdown-delivery-method';
import { DropdownDocumentRequestClosedReasonAdapter } from '../dropdown-document-request-closed-reason/adapter/dropdown-document-request-closed-reason-adapter';
import { DropdownDocumentRequestStatusAdapter } from '../dropdown-document-request-status/adapter/dropdown-document-request-status-adapter';
import { DropdownGarnishmentClosedReasonAdapter } from '../dropdown-garnishment-closed-reason/adapter/dropdown-garnishment-closed-reason-adapter';
import { DropdownGarnishmentStatusAdapter } from '../dropdown-garnishment-status/adapter/dropdown-garnishment-status-adapter';
import * as apiInvoiceTemplateDropdown from '../dropdown-invoice-template/models/api/dropdown-invoice-template-list';
import * as appInvoiceTemplateDropdown from '../dropdown-invoice-template/models/app/dropdown-invoice-template';
import * as apiInvoiceType from '../dropdown-invoice-type/models/api/dropdown-invoice-type';
import * as appInvoiceType from '../dropdown-invoice-type/models/app/dropdown-invoice-type';
import { DropdownLegalAuthorityType as ApiDropdownLegalAuthorityType } from '../dropdown-legal-authority-type/api/dropdown-legal-authority-type';
import { DropdownLegalAuthorityType as AppDropdownLegalAuthorityType } from '../dropdown-legal-authority-type/app/dropdown-legal-authority-type';
import * as apiLibraryTemplateDropdown from '../dropdown-library-template/models/api/dropdown-library-template-list';
import * as appLibraryTemplateDropdown from '../dropdown-library-template/models/app/dropdown-library-template';
import { DropdownLitigationClosedReasonAdapter } from '../dropdown-litigation-closed-reason/adapter/dropdown-litigation-closed-reason-adapter';
import { DropdownLitigationStatusAdapter } from '../dropdown-litigation-status/adapter/dropdown-litigation-status-adapter';
import { DropdownMatterStatusFilterAdapter } from '../dropdown-matter-status-filter/adapter/dropdown-matter-status-filter-adapter';
import { DropdownMatterSubtypeAdapter } from '../dropdown-matter-subtype/adapters/dropdown-matter-subtype.adapter';
import { IDropdownMatterSubtypeExtended } from '../dropdown-matter-subtype/models/app/dropdown-matter-subtype';
import * as apiDropdownMethodOfService from '../dropdown-method-of-service/models/api/dropdown-method-of-service';
import * as appDropdownMethodOfService from '../dropdown-method-of-service/models/app/dropdown-method-of-service';
import { DropdownOtherClosedReasonAdapter } from '../dropdown-other-closed-reason/adapter/dropdown-other-closed-reason-adapter';
import { DropdownOtherStatusAdapter } from '../dropdown-other-status/adapter/dropdown-other-status-adapter';
import * as apiParticipantDef from '../dropdown-participant//models/api/dropdown-participant-list';
import * as appParticipantDef from '../dropdown-participant/models/app/dropdown-participant';
import { DropdownResponseDocumentType as ApiDropdownResponseDocumentType } from '../dropdown-response-document-type/api/dropdown-response-document-type';
import { DropdownResponseDocumentType as AppDropdownResponseDocumentType } from '../dropdown-response-document-type/app/dropdown-response-document-type';
import { DropdownReviewerAdapter } from '../dropdown-reviewer/models/adapters/dropdown-reviewer-adapter';
import { DropdownRfpaCertificateStatus } from '../dropdown-rfpa-certificate-status/app/dropdown-rfpa-certificate-status';
import { DropdownStateList } from '../dropdown-state/models/api/dropdown-state-list';
import { DropdownStateLpms } from '../dropdown-state/models/app/dropdown-state';
import { DropdownSubjectType as ApiDropdownSubjectType } from '../dropdown-subject-type/api/dropdown-subject-type';
import { DropdownSubjectType as AppDropdownSubjectType } from '../dropdown-subject-type/app/dropdown-subject-type';
import * as apiTemplateFieldDropdown from '../dropdown-template-field/models/api/dropdown-template-field-list';
import * as appTemplateFieldDropdown from '../dropdown-template-field/models/app/dropdown-template-field';
import { DropdownUserAdapter } from '../dropdown-user/adapters/dropdown-user.adapter';
import { DropdownAnswerDeliveryMethodAdapter } from '../dropdown-answer-delivery-method/adapters/dropdown-answer-delivery-method-adapter';
import { DropdownAnswerTypeAdapter } from '../dropdown-answer-type/adapters/dropdown-answer-type';

export class DropdownOneTimePinRead {
  id: string;
  name: string;
  isOptional: boolean;
  requireOneTimePin: boolean;
}

export class DropdownOneTimePin extends IdName {
  isOptional: boolean;
  requireOneTimePin: boolean;
}

export class DropdownState extends IdName {
  abbreviation: string;
  country: number;
  countryName: string;
}
@Injectable({
  providedIn: 'root'
})
export class DropdownStateAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownStateList): Required<DropdownState> {
    return {
      ...super.fromListModel(model),
      ...{
        abbreviation: model.abbreviation,
        country: model.country,
        countryName: model.countryName
      }
    };
  }
}
@Injectable({
  providedIn: 'root'
})
export class DropdownApiAdapter extends ApiDropdownAdapter {
  constructor(
    private _dropdownStateAdapter: DropdownStateAdapter,
    private _dropdownMatterSubtypeAdapter: DropdownMatterSubtypeAdapter,
    private _dropdownDocumentRequestStatusAdapter: DropdownDocumentRequestStatusAdapter,
    private _dropdownDocumentRequestClosedReasonAdapter: DropdownDocumentRequestClosedReasonAdapter,
    private _dropdownGarnishmentStatusAdapter: DropdownGarnishmentStatusAdapter,
    private _dropdownGarnishmentClosedReasonAdapter: DropdownGarnishmentClosedReasonAdapter,
    private _dropdownOtherStatusAdapter: DropdownOtherStatusAdapter,
    private _dropdownOtherClosedReasonAdapter: DropdownOtherClosedReasonAdapter,
    private _dropdownLitigationStatusAdapter: DropdownLitigationStatusAdapter,
    private _dropdownLitigationClosedReasonAdapter: DropdownLitigationClosedReasonAdapter,
    private _dropdownMatterStatusFilterAdapter: DropdownMatterStatusFilterAdapter,
    private _dropdownUserAdapter: DropdownUserAdapter,
    private _dropdownReviewerAdapter: DropdownReviewerAdapter,
    private _dropdownAnswerDeliveryMethodAdapter: DropdownAnswerDeliveryMethodAdapter,
    private _dropdownAnswerTypeAdapter: DropdownAnswerTypeAdapter
  ) {
    super();
  }
  fromListModel(model: any, context: ApiCallContext) {
    const url = context.__url;
    if (url.endsWith('/' + DropdownType.DeliveryMethods.toString())) {
      const extendedModel = model as apiDeliveryDef.DropdownDeliveryMethodList;

      const mappedModel = {
        ...super.fromListModel(extendedModel),
        includesFaxCommunication: extendedModel.includesFaxCommunication,
        includesEmailCommunication: extendedModel.includesEmailCommunication,
        isPortalDelivery: extendedModel.isPortalDelivery
      } as Required<appDeliveryDef.DropdownDeliveryMethod>;
      return mappedModel;
    } else if (url.endsWith(`/${DropdownType.MethodOfServices}`)) {
      const extendedModel = model as apiDropdownMethodOfService.DropdownMethodOfService;

      const mappedModel = {
        ...super.fromListModel(extendedModel),
        isServiceAutomated: extendedModel.isServiceAutomated
      } as Required<appDropdownMethodOfService.DropdownMethodOfService>;
      return mappedModel;
    } else if (url.endsWith('/participants')) {
      const extendedModel = model as apiParticipantDef.DropdownParticipantList;

      const mappedModel = {
        ...super.fromListModel(extendedModel),

        lastAccessDate: undefined,
        email: extendedModel.email,
        firstName: extendedModel.firstName,

        lastName: extendedModel.lastName,

        participantLevel: extendedModel.participantLevel,
        teamId: extendedModel.teamId as unknown as string,
        securityRole: extendedModel.securityRole
      } as Required<appParticipantDef.DropdownParticipant>;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.InvoiceTypes.toString())) {
      const extendedModel = model as apiInvoiceType.DropdownInvoiceType;
      const mappedModel = {
        ...super.fromListModel(extendedModel),
        requiresPaywall: extendedModel.requiresPaywall,
        allowsCustomInvoiceAmount: extendedModel.allowsCustomInvoiceAmount,
        isAutoGenerated: extendedModel.isAutoGenerated
      } as Required<appInvoiceType.DropdownInvoiceType>;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.InvoiceTemplates.toString())) {
      const extendedModel = model as apiInvoiceTemplateDropdown.DropdownInvoiceTemplateList;
      const mappedModel = {
        ...super.fromListModel(extendedModel),
        description: extendedModel.description,

        totalAmount: extendedModel.totalAmount,
        dateCreated: extendedModel.dateCreated,
        dateUpdated: extendedModel.dateUpdated
      } as Required<appInvoiceTemplateDropdown.DropdownInvoiceTemplate>;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.TemplateFields.toString())) {
      const extendedModel = model as apiTemplateFieldDropdown.DropdownTemplateFieldList;
      const mappedModel = {
        ...super.fromListModel(extendedModel),
        isCommon: extendedModel.isCommon,
        placeholderElement: extendedModel.placeholderElement,
        isHtmlResult: extendedModel.isHtmlResult
      } as Required<appTemplateFieldDropdown.DropdownTemplateField>;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.DocumentRequestStatuses.toString())) {
      return this._dropdownDocumentRequestStatusAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.DocumentRequestClosedReasons.toString())) {
      return this._dropdownDocumentRequestClosedReasonAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.GarnishmentStatuses.toString())) {
      return this._dropdownGarnishmentStatusAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.GarnishmentClosedReasons.toString())) {
      return this._dropdownGarnishmentClosedReasonAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.LitigationStatuses.toString())) {
      return this._dropdownLitigationStatusAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.LitigationClosedReasons.toString())) {
      return this._dropdownLitigationClosedReasonAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.OtherMatterStatuses.toString())) {
      return this._dropdownOtherStatusAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.OtherMatterClosedReasons.toString())) {
      return this._dropdownOtherClosedReasonAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.MatterStatusFilters.toString())) {
      return this._dropdownMatterStatusFilterAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.Users.toString())) {
      return { ...this._dropdownUserAdapter.fromListModel(model) };
    } else if (url.endsWith('/' + DropdownType.GarnishmentAnswerTypes.toString())) {
      return this._dropdownAnswerTypeAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.AnswerDeliveryMethods.toString())) {
      return this._dropdownAnswerDeliveryMethodAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.LibraryTemplates.toString())) {
      const extendedModel = model as Required<apiLibraryTemplateDropdown.DropdownLibraryTemplateList>;
      const mappedModel = {
        ...super.fromListModel(extendedModel),
        category: extendedModel.category,
        editableAfterGeneration: extendedModel.editableAfterGeneration,
        matterTypes: extendedModel.matterTypes,
        matterSubtypeIds: extendedModel.matterSubtypeIds,
        matterAttachmentTypes: extendedModel.matterAttachmentTypes
      } as Required<appLibraryTemplateDropdown.DropdownLibraryTemplate>;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.CommunicationTypes.toString())) {
      const extendedModel = model as apiCommunicationTypesDropdown.DropdownCommunicationTypesList;

      return super.fromListModel(extendedModel);
    } else if (url.endsWith('/' + DropdownType.RfpaCertificateStatuses.toString())) {
      const extendedModel = model as DropdownRfpaCertificateStatus;
      const mappedModel: Required<DropdownRfpaCertificateStatus> = {
        ...super.fromListModel(extendedModel),
        requireNotes: extendedModel.requireNotes
      };
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.States.toString())) {
      const extendedModel = model as DropdownStateList;
      const result: Required<DropdownStateLpms> = {
        ...this._dropdownStateAdapter.fromListModel(extendedModel)
      };
      return result;
    } else if (url.endsWith('/' + DropdownType.MatterSubtypes.toString())) {
      const dropdownModel = model as IDropdownMatterSubtypeExtended;

      // For a while we've been using mattersubtypeadapter's fromListModel to map
      // the dropdown. But that's actually not correct - GET model from matterSubtype
      // is much more similar to the picklist model, with a few extra overrides below
      return {
        ...this._dropdownMatterSubtypeAdapter.fromGetModel(model),
        ...super.fromListModel(model),
        ...{
          unassignedRequestNotificationSettings: {
            enabled: dropdownModel.unassignedRequestNotificationEnabled
          },
          garnishmentMultiStateJurisdictionWarningSettings: {
            enabled: dropdownModel.garnishmentMultiStateJurisdictionWarningEnabled,
            includeIssuingAuthorityState: dropdownModel.garnishmentMultiStateJurisdictionWarningIncludesIssuingAuthorityState,
            includeDebtorState: dropdownModel.garnishmentMultiStateJurisdictionWarningIncludesDebtorState,
            includeAccountLocationState: dropdownModel.garnishmentMultiStateJurisdictionWarningIncludesAccountLocationState
          },

          enableIssuingAuthorityFields: dropdownModel.issuingAuthorityFieldsEnabled,
          matterType: dropdownModel.matterType,
          matterTypeName: dropdownModel.matterTypeName,
          accountLocationTypeName: dropdownModel.accountLocationTypeName,
          accountLocationStateName: dropdownModel.accountLocationStateName,
          defaultExemptionTrackingStateName: dropdownModel.defaultExemptionTrackingStateName,
          restrictionIssuerWarningStateNames: dropdownModel.restrictionIssuerWarningStateNames,
          bsaNotificationEnabled: dropdownModel.bsaNotificationEnabled,
          bsaNotificationTiming: dropdownModel.bsaNotificationTiming,
          bsaNotificationDaysAfterPortal: dropdownModel.bsaNotificationDaysAfterPortal,
          servingPartyInstructionSettings: {
            enabled: dropdownModel.automaticallyAddServingPartyInstructions,
            defaultServingPartyInstructions: dropdownModel.defaultServingPartyInstructions,
            defaultServingPartyInstructionAcknowledgmentRequired: dropdownModel.defaultServingPartyInstructionAcknowledgmentRequired
          },
          responseDocumentTypeSettings: {
            enabled: dropdownModel.responseDocumentTypeSettingsEnabled,
            picklistIds: dropdownModel.responseDocumentTypeSettingsPicklists
          },
          legalAuthorityTypeSettings: {
            enabled: dropdownModel.legalAuthorityTypeSettingsEnabled,
            picklistIds: dropdownModel.legalAuthorityTypeSettingsPicklists
          },
          civilCaseSubtypeSettings: {
            enabled: dropdownModel.civilCaseSubtypeSettingsEnabled,
            picklistIds: dropdownModel.civilCaseSubtypeSettingsPicklists
          },
          criminalCaseSubtypeSettings: {
            enabled: dropdownModel.criminalCaseSubtypeSettingsEnabled,
            picklistIds: dropdownModel.criminalCaseSubtypeSettingsPicklists
          },
          regulatoryCaseSubtypeSettings: {
            enabled: dropdownModel.regulatoryCaseSubtypeSettingsEnabled,
            picklistIds: dropdownModel.regulatoryCaseSubtypeSettingsPicklists
          },
          otherCaseSubtypeSettings: {
            enabled: dropdownModel.otherCaseSubtypeSettingsEnabled,
            picklistIds: dropdownModel.otherCaseSubtypeSettingsPicklists
          },
          automaticReviewerSettings: {
            enabled: dropdownModel.automaticReviewerSettingsEnabled
          },
          disclosureProhibitedTrackingSettings: {
            defaultDisclosureProhibited: dropdownModel.disclosureProhibitedTrackingDefaultDisclosureProhibited,
            enabled: dropdownModel.disclosureProhibitedTrackingEnabled
          },
          deliveryPortalAcknowledgmentSettings: {
            enabled: dropdownModel.deliveryPortalAcknowledgmentEnabled,
            acknowledgmentText: dropdownModel.deliveryPortalAcknowledgmentText
          },
          notifiedTeamsTrackingSettings: {
            enabled: dropdownModel.notifiedTeamsTrackingSettingsEnabled,
            picklistIds: dropdownModel.notifiedTeamsTrackingSettingsPicklists
          },
          docExtractorSettings: {
            enableExtractionForServedFiles: dropdownModel.enableExtractionForServedFiles,
            enableOcrForServedFiles: dropdownModel.enableOcrForServedFiles
          }
        }
      };
    } else if (url.endsWith('/reviewers')) {
      return { ...this._dropdownReviewerAdapter.fromListModel(model) };
    } else if (url.endsWith('/' + DropdownType.ResponseDocumentTypes.toString())) {
      const extendedModel = model as ApiDropdownResponseDocumentType;
      const result: Required<AppDropdownResponseDocumentType> = {
        ...super.fromListModel(model),
        description: extendedModel.description
      };
      return result;
    } else if (url.endsWith('/' + DropdownType.LegalAuthorityTypes.toString())) {
      const extendedModel = model as ApiDropdownLegalAuthorityType;
      const result: Required<AppDropdownLegalAuthorityType> = {
        ...super.fromListModel(model),
        description: extendedModel.description
      };
      return result;
    } else if (url.endsWith('/' + DropdownType.SubjectTypes.toString())) {
      const extendedModel = model as ApiDropdownSubjectType;
      const result: Required<AppDropdownSubjectType> = {
        ...super.fromListModel(model),
        description: extendedModel.description
      };
      return result;
    } else if (
      url.endsWith('/' + DropdownType.CivilCaseSubtypes.toString()) ||
      url.endsWith('/' + DropdownType.CriminalCaseSubtypes.toString()) ||
      url.endsWith('/' + DropdownType.RegulatoryCaseSubtypes.toString()) ||
      url.endsWith('/' + DropdownType.OtherCaseSubtypes.toString())
    ) {
      let caseTypeId;
      /* Cheating a little bit here to make it easier to map and filter on the edit pages.
       * If we add custom CaseTypes then we'll have to do the mapping to a DropdownType.CaseTypes OR we've gotta
       * get caseTypeId from the API as part of the API model */
      if (url.endsWith('/' + DropdownType.CivilCaseSubtypes.toString())) {
        caseTypeId = CaseType.Civil;
      } else if (url.endsWith('/' + DropdownType.CriminalCaseSubtypes.toString())) {
        caseTypeId = CaseType.Criminal;
      } else if (url.endsWith('/' + DropdownType.RegulatoryCaseSubtypes.toString())) {
        caseTypeId = CaseType.Regulatory;
      } else if (url.endsWith('/' + DropdownType.OtherCaseSubtypes.toString())) {
        caseTypeId = CaseType.Other;
      }
      const extendedModel = model as ApiDropdownCaseSubtype;

      const result: Required<AppDropdownCaseSubtype> = {
        ...super.fromListModel(model),
        description: extendedModel.description,
        caseTypeId
      };
      return result;
    }

    return super.fromListModel(model);
  }
}
