import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownDocumentRequestStatusList } from '../models/api/dropdown-document-request-status-list';
import { DropdownDocumentRequestStatus } from '../models/app/dropdown-document-request-status';

@Injectable({
  providedIn: 'root'
})
export class DropdownDocumentRequestStatusAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownDocumentRequestStatusList): Required<DropdownDocumentRequestStatus> {
    return {
      ...super.fromListModel(model),

      statusGroup: model.statusGroup,
      statusGroupName: model.statusGroupName,
      isResolved: model.isResolved,
      isPortal: model.isPortal,
      isClosed: model.isClosed,

      requireSoftRequiredFields: model.requireSoftRequiredFields
    };
  }
}
