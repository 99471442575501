import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownGarnishmentStatusList } from '../models/api/dropdown-garnishment-status-list';
import { DropdownGarnishmentStatus } from '../models/app/dropdown-garnishment-status';

@Injectable({
  providedIn: 'root'
})
export class DropdownGarnishmentStatusAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownGarnishmentStatusList): Required<DropdownGarnishmentStatus> {
    return {
      ...super.fromListModel(model),
      statusGroup: model.statusGroup,
      statusGroupName: model.statusGroupName,
      isClosed: model.isClosed,
      requireSoftRequiredFields: model.requireSoftRequiredFields,
      isAvailableForX9: model.isAvailableForX9
    };
  }
}
