import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { AutoAssignReviewerType, CreateMatterSubtypeModel, MatterSubtypeListModel, MatterSubtypeModel, UpdateMatterSubtypeModel } from '../../../_lpms-api-models.generated';
import { DropdownMatterSubtype } from '../models/app/dropdown-matter-subtype';

@Injectable({
  providedIn: 'root'
})
export class DropdownMatterSubtypeAdapter extends ApiDataAdapter<DropdownMatterSubtype> {
  constructor(private _featureFlagService: FeatureFlagsService) {
    super();
  }

  toCreateModel(matterSubtype: DropdownMatterSubtype): Required<CreateMatterSubtypeModel> {
    return {
      recordRetentionPolicy: matterSubtype.recordRetentionPolicy
        ? {
            daysBeforeFileDeletion: matterSubtype.recordRetentionPolicy.daysBeforeFileDeletion,
            enabled: matterSubtype.recordRetentionPolicy.enabled
          }
        : null,
      type: matterSubtype.matterType,
      isActive: matterSubtype.isActive,
      name: matterSubtype.name,
      transferMethod: matterSubtype.transferSettings.transferMethod,
      transferEmail: matterSubtype.transferSettings.transferEmail,
      enableIssuingAuthorityFields: matterSubtype.enableIssuingAuthorityFields,
      enableNoJurisdictionWarning: matterSubtype.enableNoJurisdictionWarning,
      enableNotDomesticatedWarning: matterSubtype.enableNotDomesticatedWarning,
      garnishmentMultiStateJurisdictionWarningSettings: {
        enabled: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.enabled,
        includeIssuingAuthorityState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeIssuingAuthorityState,
        includeDebtorState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeDebtorState,
        includeAccountLocationState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeAccountLocationState
      },
      enableRfpaCertificateFields: matterSubtype.enableRfpaCertificateFields,
      enableCostRecovery: matterSubtype.enableCostRecovery,
      unassignedRequestNotificationSettings: {
        enabled: matterSubtype.unassignedRequestNotificationSettings.enabled,
        numberOfHoursBeforeNotification: matterSubtype.unassignedRequestNotificationSettings.numberOfHoursBeforeNotification,
        notifyCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyCoordinator,
        notifyBackupCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyBackupCoordinator,
        notifyUsersWithSubtypeAccess: matterSubtype.unassignedRequestNotificationSettings.notifyUsersWithSubtypeAccess,
        userIdsToNotify: matterSubtype.unassignedRequestNotificationSettings.userIdsToNotify as unknown as number[],
        emailAddressesToNotify: matterSubtype.unassignedRequestNotificationSettings.emailAddressesToNotify
      },
      transferEscalationSettings: {
        numberOfHoursBetweenEscalations: matterSubtype.transferSettings.transferEscalationSettings.numberOfHoursBetweenEscalations,
        sendEscalationAtInPt: matterSubtype.transferSettings.transferEscalationSettings.sendEscalationAtInPt,
        notifyReviewer: matterSubtype.transferSettings.transferEscalationSettings.notifyReviewer,
        notifyCoordinator: matterSubtype.transferSettings.transferEscalationSettings.notifyCoordinator,
        notifyBackupCoordinator: matterSubtype.transferSettings.transferEscalationSettings.notifyBackupCoordinator,
        notifyUsersWithSubtypeAccess: matterSubtype.transferSettings.transferEscalationSettings.notifyUsersWithSubtypeAccess,
        userIdsToNotify: matterSubtype.transferSettings.transferEscalationSettings.userIdsToNotify as unknown as number[],
        emailAddressesToNotify: matterSubtype.transferSettings.transferEscalationSettings.emailAddressesToNotify
      },
      accountLocationType: matterSubtype.accountLocationType,
      accountLocationState: matterSubtype.accountLocationState,
      enableExemptionTrackingFields: matterSubtype.enableExemptionTrackingFields,
      defaultExemptionTrackingState: matterSubtype.defaultExemptionTrackingState,
      enableRestrictionStateIssuerWarning: matterSubtype.enableRestrictionStateIssuerWarning,
      restrictionIssuerWarningStates: matterSubtype.restrictionIssuerWarningStates,
      defaultPortalDurationInDays: matterSubtype.defaultPortalDurationInDays,
      automaticReviewerSettings: {
        enabled: matterSubtype.automaticReviewerSettings.enabled,
        autoAssignReviewerType: matterSubtype.automaticReviewerSettings.autoAssignReviewerType,
        weighted: matterSubtype.automaticReviewerSettings.weighted,
        reviewers: matterSubtype.automaticReviewerSettings.reviewers ?? []
      },
      onlyOutsideCouncilDelivery: matterSubtype.onlyOutsideCouncilDelivery,
      portalReferenceField: matterSubtype.portalReferenceField,
      bsaNotificationSettings: matterSubtype.bsaNotificationSettings,
      servingPartyInstructionSettings: matterSubtype.servingPartyInstructionSettings,

      responseDocumentTypeSettings: matterSubtype.responseDocumentTypeSettings,
      legalAuthorityTypeSettings: matterSubtype.legalAuthorityTypeSettings,
      caseTypeSettingsEnabled: matterSubtype.caseTypeSettingsEnabled,
      civilCaseSubtypeSettings: matterSubtype.civilCaseSubtypeSettings,
      criminalCaseSubtypeSettings: matterSubtype.criminalCaseSubtypeSettings,
      regulatoryCaseSubtypeSettings: matterSubtype.regulatoryCaseSubtypeSettings,
      otherCaseSubtypeSettings: matterSubtype.otherCaseSubtypeSettings,
      disclosureProhibitedTrackingSettings: matterSubtype.disclosureProhibitedTrackingSettings
        ? {
            defaultDisclosureProhibited: matterSubtype.disclosureProhibitedTrackingSettings.defaultDisclosureProhibited,
            enabled: matterSubtype.disclosureProhibitedTrackingSettings.enabled
          }
        : null,
      deliveryPortalAcknowledgmentSettings: matterSubtype.deliveryPortalAcknowledgmentSettings
        ? {
            enabled: matterSubtype.deliveryPortalAcknowledgmentSettings.enabled,
            acknowledgmentText: matterSubtype.deliveryPortalAcknowledgmentSettings.acknowledgmentText
          }
        : null,
      notifiedTeamsTrackingSettings: matterSubtype.notifiedTeamsTrackingSettings
        ? {
            enabled: matterSubtype.notifiedTeamsTrackingSettings.enabled,
            picklistIds: matterSubtype.notifiedTeamsTrackingSettings.picklistIds
          }
        : null,
      docExtractorSettings: matterSubtype.docExtractorSettings
        ? {
            enableExtractionForServedFiles: matterSubtype.docExtractorSettings.enableExtractionForServedFiles,
            enableOcrForServedFiles: matterSubtype.docExtractorSettings.enableOcrForServedFiles
          }
        : null
    };
  }
  toUpdateModel(matterSubtype: DropdownMatterSubtype): Required<UpdateMatterSubtypeModel> {
    return {
      recordRetentionPolicy: matterSubtype.recordRetentionPolicy
        ? {
            daysBeforeFileDeletion: matterSubtype.recordRetentionPolicy.daysBeforeFileDeletion,
            enabled: matterSubtype.recordRetentionPolicy.enabled
          }
        : undefined,
      isActive: matterSubtype.isActive,
      enableCostRecovery: matterSubtype.enableCostRecovery,
      name: matterSubtype.name,
      transferMethod: matterSubtype.transferSettings.transferMethod,
      transferEmail: matterSubtype.transferSettings.transferEmail,
      enableIssuingAuthorityFields: matterSubtype.enableIssuingAuthorityFields,
      enableNoJurisdictionWarning: matterSubtype.enableNoJurisdictionWarning,
      enableNotDomesticatedWarning: matterSubtype.enableNotDomesticatedWarning,
      garnishmentMultiStateJurisdictionWarningSettings: {
        enabled: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.enabled,
        includeIssuingAuthorityState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeIssuingAuthorityState,
        includeDebtorState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeDebtorState,
        includeAccountLocationState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeAccountLocationState
      },
      enableRfpaCertificateFields: matterSubtype.enableRfpaCertificateFields,
      unassignedRequestNotificationSettings: {
        enabled: matterSubtype.unassignedRequestNotificationSettings.enabled,
        numberOfHoursBeforeNotification: matterSubtype.unassignedRequestNotificationSettings.numberOfHoursBeforeNotification,
        notifyCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyCoordinator,
        notifyBackupCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyBackupCoordinator,
        notifyUsersWithSubtypeAccess: matterSubtype.unassignedRequestNotificationSettings.notifyUsersWithSubtypeAccess,
        userIdsToNotify: matterSubtype.unassignedRequestNotificationSettings.userIdsToNotify as unknown as number[],
        emailAddressesToNotify: matterSubtype.unassignedRequestNotificationSettings.emailAddressesToNotify
      },
      transferEscalationSettings: {
        numberOfHoursBetweenEscalations: matterSubtype.transferSettings.transferEscalationSettings.numberOfHoursBetweenEscalations,
        sendEscalationAtInPt: matterSubtype.transferSettings.transferEscalationSettings.sendEscalationAtInPt,
        notifyReviewer: matterSubtype.transferSettings.transferEscalationSettings.notifyReviewer,
        notifyCoordinator: matterSubtype.transferSettings.transferEscalationSettings.notifyCoordinator,
        notifyBackupCoordinator: matterSubtype.transferSettings.transferEscalationSettings.notifyBackupCoordinator,
        notifyUsersWithSubtypeAccess: matterSubtype.transferSettings.transferEscalationSettings.notifyUsersWithSubtypeAccess,
        userIdsToNotify: matterSubtype.transferSettings.transferEscalationSettings.userIdsToNotify as unknown as number[],
        emailAddressesToNotify: matterSubtype.transferSettings.transferEscalationSettings.emailAddressesToNotify
      },
      accountLocationType: matterSubtype.accountLocationType,
      accountLocationState: matterSubtype.accountLocationState,
      enableExemptionTrackingFields: matterSubtype.enableExemptionTrackingFields,
      defaultExemptionTrackingState: matterSubtype.defaultExemptionTrackingState,
      enableRestrictionStateIssuerWarning: matterSubtype.enableRestrictionStateIssuerWarning,
      restrictionIssuerWarningStates: matterSubtype.restrictionIssuerWarningStates,
      defaultPortalDurationInDays: matterSubtype.defaultPortalDurationInDays,
      automaticReviewerSettings: {
        enabled: matterSubtype.automaticReviewerSettings.enabled,
        autoAssignReviewerType: matterSubtype.automaticReviewerSettings.autoAssignReviewerType,
        weighted: matterSubtype.automaticReviewerSettings.weighted,
        reviewers: matterSubtype.automaticReviewerSettings.reviewers ?? []
      },
      portalReferenceField: matterSubtype.portalReferenceField,
      onlyOutsideCouncilDelivery: matterSubtype.onlyOutsideCouncilDelivery,
      bsaNotificationSettings: matterSubtype.bsaNotificationSettings,
      servingPartyInstructionSettings: matterSubtype.servingPartyInstructionSettings,

      responseDocumentTypeSettings: matterSubtype.responseDocumentTypeSettings,
      legalAuthorityTypeSettings: matterSubtype.legalAuthorityTypeSettings,
      caseTypeSettingsEnabled: matterSubtype.caseTypeSettingsEnabled,
      civilCaseSubtypeSettings: matterSubtype.civilCaseSubtypeSettings,
      criminalCaseSubtypeSettings: matterSubtype.criminalCaseSubtypeSettings,
      regulatoryCaseSubtypeSettings: matterSubtype.regulatoryCaseSubtypeSettings,
      otherCaseSubtypeSettings: matterSubtype.otherCaseSubtypeSettings,
      disclosureProhibitedTrackingSettings: matterSubtype.disclosureProhibitedTrackingSettings
        ? {
            defaultDisclosureProhibited: matterSubtype.disclosureProhibitedTrackingSettings.defaultDisclosureProhibited,
            enabled: matterSubtype.disclosureProhibitedTrackingSettings.enabled
          }
        : null,
      deliveryPortalAcknowledgmentSettings: matterSubtype.deliveryPortalAcknowledgmentSettings
        ? {
            enabled: matterSubtype.deliveryPortalAcknowledgmentSettings.enabled,
            acknowledgmentText: matterSubtype.deliveryPortalAcknowledgmentSettings.acknowledgmentText
          }
        : null,
      notifiedTeamsTrackingSettings: matterSubtype.notifiedTeamsTrackingSettings
        ? {
            enabled: matterSubtype.notifiedTeamsTrackingSettings.enabled,
            picklistIds: matterSubtype.notifiedTeamsTrackingSettings.picklistIds
          }
        : null,
      docExtractorSettings: matterSubtype.docExtractorSettings
        ? {
            enableExtractionForServedFiles: matterSubtype.docExtractorSettings.enableExtractionForServedFiles,
            enableOcrForServedFiles: matterSubtype.docExtractorSettings.enableOcrForServedFiles
          }
        : null
    };
  }

  fromGetModel(matterSubtype: MatterSubtypeModel): Required<DropdownMatterSubtype> {
    return {
      recordRetentionPolicy: matterSubtype.recordRetentionPolicy
        ? {
            daysBeforeFileDeletion: matterSubtype.recordRetentionPolicy.daysBeforeFileDeletion,
            enabled: matterSubtype.recordRetentionPolicy.enabled
          }
        : null,
      order: 0,
      id: this.helpers.fromApiId(matterSubtype.id),
      matterType: matterSubtype.type,
      matterTypeName: '',
      // Same logic we do in the list call. It's needed to deal with picklists
      isActive: typeof matterSubtype.isActive == 'boolean' ? matterSubtype.isActive : (matterSubtype.isActive as string).toLowerCase() == 'true',
      name: matterSubtype.name,
      enableCostRecovery: matterSubtype.enableCostRecovery,
      enableIssuingAuthorityFields: matterSubtype.enableIssuingAuthorityFields,
      enableNoJurisdictionWarning: matterSubtype.enableNoJurisdictionWarning,
      enableNotDomesticatedWarning: matterSubtype.enableNotDomesticatedWarning,
      garnishmentMultiStateJurisdictionWarningSettings:
        matterSubtype.garnishmentMultiStateJurisdictionWarningSettings != null
          ? {
              enabled: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.enabled,
              includeIssuingAuthorityState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeIssuingAuthorityState,
              includeDebtorState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeDebtorState,
              includeAccountLocationState: matterSubtype.garnishmentMultiStateJurisdictionWarningSettings.includeAccountLocationState
            }
          : {
              enabled: false,
              includeIssuingAuthorityState: false,
              includeDebtorState: false,
              includeAccountLocationState: false
            },
      bsaNotificationSettings: matterSubtype.bsaNotificationSettings
        ? {
            enabled: matterSubtype.bsaNotificationSettings.enabled,
            notificationTiming: matterSubtype.bsaNotificationSettings.notificationTiming,
            notificationDaysAfterPortal: matterSubtype.bsaNotificationSettings.notificationDaysAfterPortal
          }
        : null,
      servingPartyInstructionSettings: matterSubtype.servingPartyInstructionSettings
        ? {
            enabled: matterSubtype.servingPartyInstructionSettings.enabled,
            defaultServingPartyInstructions: matterSubtype.servingPartyInstructionSettings.defaultServingPartyInstructions,
            defaultServingPartyInstructionAcknowledgmentRequired: matterSubtype.servingPartyInstructionSettings.defaultServingPartyInstructionAcknowledgmentRequired
          }
        : null,

      responseDocumentTypeSettings: matterSubtype.responseDocumentTypeSettings
        ? {
            enabled: matterSubtype.responseDocumentTypeSettings.enabled,
            picklistIds: matterSubtype.responseDocumentTypeSettings.picklistIds
          }
        : null,
      legalAuthorityTypeSettings: matterSubtype.legalAuthorityTypeSettings
        ? {
            enabled: matterSubtype.legalAuthorityTypeSettings.enabled,
            picklistIds: matterSubtype.legalAuthorityTypeSettings.picklistIds
          }
        : null,
      caseTypeSettingsEnabled: matterSubtype.caseTypeSettingsEnabled,
      civilCaseSubtypeSettings: matterSubtype.civilCaseSubtypeSettings
        ? {
            enabled: matterSubtype.civilCaseSubtypeSettings.enabled,
            picklistIds: matterSubtype.civilCaseSubtypeSettings.picklistIds
          }
        : null,
      criminalCaseSubtypeSettings: matterSubtype.criminalCaseSubtypeSettings
        ? {
            enabled: matterSubtype.criminalCaseSubtypeSettings.enabled,
            picklistIds: matterSubtype.criminalCaseSubtypeSettings.picklistIds
          }
        : null,
      regulatoryCaseSubtypeSettings: matterSubtype.regulatoryCaseSubtypeSettings
        ? {
            enabled: matterSubtype.regulatoryCaseSubtypeSettings.enabled,
            picklistIds: matterSubtype.regulatoryCaseSubtypeSettings.picklistIds
          }
        : null,
      otherCaseSubtypeSettings: matterSubtype.otherCaseSubtypeSettings
        ? {
            enabled: matterSubtype.otherCaseSubtypeSettings.enabled,
            picklistIds: matterSubtype.otherCaseSubtypeSettings.picklistIds
          }
        : null,
      disclosureProhibitedTrackingSettings: matterSubtype.disclosureProhibitedTrackingSettings
        ? {
            enabled: matterSubtype.disclosureProhibitedTrackingSettings.enabled,
            defaultDisclosureProhibited: matterSubtype.disclosureProhibitedTrackingSettings.defaultDisclosureProhibited
          }
        : null,
      deliveryPortalAcknowledgmentSettings: matterSubtype.deliveryPortalAcknowledgmentSettings
        ? {
            enabled: matterSubtype.deliveryPortalAcknowledgmentSettings.enabled,
            acknowledgmentText: matterSubtype.deliveryPortalAcknowledgmentSettings.acknowledgmentText
          }
        : null,
      notifiedTeamsTrackingSettings: matterSubtype.notifiedTeamsTrackingSettings
        ? {
            enabled: matterSubtype.notifiedTeamsTrackingSettings.enabled,
            picklistIds: matterSubtype.notifiedTeamsTrackingSettings.picklistIds
          }
        : null,
      docExtractorSettings: matterSubtype.docExtractorSettings
        ? {
            enableExtractionForServedFiles: matterSubtype.docExtractorSettings.enableExtractionForServedFiles,
            enableOcrForServedFiles: matterSubtype.docExtractorSettings.enableOcrForServedFiles
          }
        : null,

      enableRfpaCertificateFields: matterSubtype.enableRfpaCertificateFields,
      defaultPortalDurationInDays: matterSubtype.defaultPortalDurationInDays,
      transferSettings:
        matterSubtype.transferMethod != null
          ? {
              enabled: true,
              transferMethod: matterSubtype.transferMethod,
              transferEmail: matterSubtype.transferEmail,
              transferEscalationSettings:
                matterSubtype.transferEscalationSettings != null
                  ? {
                      numberOfHoursBetweenEscalations: matterSubtype.transferEscalationSettings.numberOfHoursBetweenEscalations,
                      sendEscalationAtInPt: matterSubtype.transferEscalationSettings.sendEscalationAtInPt,
                      notifyReviewer: matterSubtype.transferEscalationSettings.notifyReviewer,
                      notifyCoordinator: matterSubtype.transferEscalationSettings.notifyCoordinator,
                      notifyBackupCoordinator: matterSubtype.transferEscalationSettings.notifyBackupCoordinator,
                      notifyUsersWithSubtypeAccess: matterSubtype.transferEscalationSettings.notifyUsersWithSubtypeAccess,
                      userIdsToNotify: matterSubtype.transferEscalationSettings.userIdsToNotify as unknown as string[],
                      emailAddressesToNotify: matterSubtype.transferEscalationSettings.emailAddressesToNotify
                    }
                  : {
                      numberOfHoursBetweenEscalations: null,
                      sendEscalationAtInPt: null,
                      notifyReviewer: true,
                      notifyCoordinator: false,
                      notifyBackupCoordinator: false,
                      notifyUsersWithSubtypeAccess: false,
                      userIdsToNotify: [],
                      emailAddressesToNotify: []
                    }
            }
          : {
              enabled: false,
              transferMethod: null,
              transferEmail: null,
              transferEscalationSettings: {
                numberOfHoursBetweenEscalations: null,
                sendEscalationAtInPt: null,
                notifyReviewer: true,
                notifyCoordinator: false,
                notifyBackupCoordinator: false,
                notifyUsersWithSubtypeAccess: false,
                userIdsToNotify: [],
                emailAddressesToNotify: []
              }
            },

      unassignedRequestNotificationSettings:
        matterSubtype.unassignedRequestNotificationSettings != null
          ? {
              enabled: matterSubtype.unassignedRequestNotificationSettings.enabled,
              numberOfHoursBeforeNotification: matterSubtype.unassignedRequestNotificationSettings.numberOfHoursBeforeNotification,
              notifyCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyCoordinator,
              notifyBackupCoordinator: matterSubtype.unassignedRequestNotificationSettings.notifyBackupCoordinator,
              notifyUsersWithSubtypeAccess: matterSubtype.unassignedRequestNotificationSettings.notifyUsersWithSubtypeAccess,
              userIdsToNotify: matterSubtype.unassignedRequestNotificationSettings.userIdsToNotify as unknown as string[],
              emailAddressesToNotify: matterSubtype.unassignedRequestNotificationSettings.emailAddressesToNotify
            }
          : // probably not necessary since API will do it but if conversion wasn't run this will kick in
            {
              enabled: false,
              numberOfHoursBeforeNotification: 48,
              notifyCoordinator: true,
              notifyBackupCoordinator: true,
              notifyUsersWithSubtypeAccess: true,
              userIdsToNotify: [],
              emailAddressesToNotify: []
            },
      accountLocationType: matterSubtype.accountLocationType,
      accountLocationState: matterSubtype.accountLocationState,
      accountLocationEnabled: matterSubtype.accountLocationType != null,
      enableExemptionTrackingFields: matterSubtype.enableExemptionTrackingFields,
      defaultExemptionTrackingState: matterSubtype.defaultExemptionTrackingState,
      enableRestrictionStateIssuerWarning: matterSubtype.enableRestrictionStateIssuerWarning,
      restrictionIssuerWarningStates: matterSubtype.restrictionIssuerWarningStates,
      onlyOutsideCouncilDelivery: matterSubtype.onlyOutsideCouncilDelivery,
      portalReferenceField: matterSubtype.portalReferenceField,
      automaticReviewerSettings:
        matterSubtype.automaticReviewerSettings != null
          ? {
              enabled: matterSubtype.automaticReviewerSettings.enabled,
              autoAssignReviewerType: matterSubtype.automaticReviewerSettings.autoAssignReviewerType,
              weighted: matterSubtype.automaticReviewerSettings.weighted,
              reviewers:
                matterSubtype.automaticReviewerSettings.reviewers?.map(o => ({
                  reviewerId: o.reviewerId ?? null,
                  weight: o.weight ?? null
                })) ?? []
            }
          : // probably not necessary since API will do it but if conversion wasn't run this will kick in
            {
              enabled: false,
              autoAssignReviewerType: AutoAssignReviewerType.RoundRobin,
              weighted: false,
              reviewers: []
            }
    };
  }
  fromListModel(matterSubtype: MatterSubtypeListModel): Required<DropdownMatterSubtype> {
    return {
      recordRetentionPolicy: undefined,
      order: 0,
      enableRfpaCertificateFields: undefined,
      id: this.helpers.fromApiId(matterSubtype.id),
      matterType: matterSubtype.type,
      enableCostRecovery: undefined,
      matterTypeName: undefined,
      isActive: typeof matterSubtype.isActive == 'boolean' ? matterSubtype.isActive : (matterSubtype.isActive as string).toLowerCase() == 'true',
      name: matterSubtype.name,
      enableIssuingAuthorityFields: undefined,
      enableNoJurisdictionWarning: undefined,
      enableNotDomesticatedWarning: undefined,
      garnishmentMultiStateJurisdictionWarningSettings: undefined,
      bsaNotificationSettings: undefined,
      servingPartyInstructionSettings: undefined,
      responseDocumentTypeSettings: undefined,
      legalAuthorityTypeSettings: undefined,
      caseTypeSettingsEnabled: undefined,
      civilCaseSubtypeSettings: undefined,
      criminalCaseSubtypeSettings: undefined,
      regulatoryCaseSubtypeSettings: undefined,
      otherCaseSubtypeSettings: undefined,
      disclosureProhibitedTrackingSettings: undefined,
      deliveryPortalAcknowledgmentSettings: undefined,
      notifiedTeamsTrackingSettings: undefined,
      docExtractorSettings: undefined,

      transferSettings:
        matterSubtype.transferMethod != null
          ? {
              enabled: true,
              transferMethod: matterSubtype.transferMethod,
              transferEmail: matterSubtype.transferEmail,
              transferEscalationSettings: undefined
            }
          : {
              enabled: false,
              transferMethod: undefined,
              transferEmail: undefined,
              transferEscalationSettings: undefined
            },

      unassignedRequestNotificationSettings: undefined,
      accountLocationType: undefined,
      accountLocationState: undefined,
      accountLocationEnabled: undefined,
      enableExemptionTrackingFields: undefined,
      defaultExemptionTrackingState: undefined,
      enableRestrictionStateIssuerWarning: undefined,
      restrictionIssuerWarningStates: undefined,
      automaticReviewerSettings: undefined,
      onlyOutsideCouncilDelivery: undefined,
      portalReferenceField: undefined,
      defaultPortalDurationInDays: undefined
    };
  }
  toDeleteModel(model: any): any {
    return null;
  }
  fromHistory(model: any): any {
    return model;
  }

  fromGetNewModel(): Required<DropdownMatterSubtype> {
    const matterSubtype: Required<DropdownMatterSubtype> = {
      recordRetentionPolicy: null,
      id: ObjectHelper.getNewId(),
      order: 0,
      isActive: true,
      name: '',
      matterType: null,
      matterTypeName: '',
      enableIssuingAuthorityFields: false,
      enableNoJurisdictionWarning: false,
      enableNotDomesticatedWarning: false,
      garnishmentMultiStateJurisdictionWarningSettings: {
        enabled: false,
        includeIssuingAuthorityState: false,
        includeDebtorState: false,
        includeAccountLocationState: false
      },
      bsaNotificationSettings: {
        enabled: false,
        notificationTiming: null,
        notificationDaysAfterPortal: null
      },
      servingPartyInstructionSettings: undefined,
      responseDocumentTypeSettings: undefined,
      legalAuthorityTypeSettings: undefined,
      caseTypeSettingsEnabled: undefined,
      civilCaseSubtypeSettings: undefined,
      criminalCaseSubtypeSettings: undefined,
      regulatoryCaseSubtypeSettings: undefined,
      otherCaseSubtypeSettings: undefined,
      disclosureProhibitedTrackingSettings: {
        enabled: false,
        defaultDisclosureProhibited: false
      },
      deliveryPortalAcknowledgmentSettings: {
        enabled: false,
        acknowledgmentText: null
      },
      notifiedTeamsTrackingSettings: undefined,
      docExtractorSettings: undefined,

      enableRfpaCertificateFields: false,
      enableCostRecovery: undefined,

      transferSettings: {
        enabled: false,
        transferMethod: null,
        transferEmail: '',
        transferEscalationSettings: {
          numberOfHoursBetweenEscalations: 0,
          sendEscalationAtInPt: null,
          notifyReviewer: true,
          notifyCoordinator: false,
          notifyBackupCoordinator: false,
          notifyUsersWithSubtypeAccess: false,
          userIdsToNotify: [],
          emailAddressesToNotify: []
        }
      },
      unassignedRequestNotificationSettings: {
        emailAddressesToNotify: [],
        enabled: true,
        notifyBackupCoordinator: true,
        notifyCoordinator: true,
        notifyUsersWithSubtypeAccess: true,
        numberOfHoursBeforeNotification: 48,
        userIdsToNotify: []
      },
      accountLocationEnabled: true,
      accountLocationType: null,
      accountLocationState: null,
      enableExemptionTrackingFields: true,
      defaultExemptionTrackingState: null,
      enableRestrictionStateIssuerWarning: false,
      restrictionIssuerWarningStates: null,
      onlyOutsideCouncilDelivery: undefined,
      portalReferenceField: undefined,
      automaticReviewerSettings: {
        enabled: false,
        autoAssignReviewerType: AutoAssignReviewerType.RoundRobin,
        weighted: false,
        reviewers: []
      },
      defaultPortalDurationInDays: 14
    };
    return matterSubtype;
  }
}
