import { Injectable } from '@angular/core';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { ResendPortalInvite } from '../models/app/resend-portal-invite';

@Injectable({
  providedIn: 'root'
})
export class ResendPortalInviteAdapter extends ApiDataAdapter<SafariObject> {
  toUpdateModel(o: ResendPortalInvite): any {
    return {};
  }
}
