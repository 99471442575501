import { Injectable } from '@angular/core';

import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { DocumentRequestPortalActivityModel } from '../../_lpms-api-models.generated';
import { PortalActivity } from '../models/app/portal-activity';

@Injectable({
  providedIn: 'root'
})
export class PortalActivityAdapter extends ApiDataAdapter<PortalActivity> {
  fromListModel(o: DocumentRequestPortalActivityModel): Required<PortalActivity> {
    return {
      data: o.data,
      date: this.helpers.fromApiDate(o.date, false),
      id: null,
      type: o.type
    };
  }
}
