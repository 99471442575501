import { Injectable } from '@angular/core';
import * as redux from '../../../../redux/index';
import { IOtherMatterEditPageUiState } from '../../../../redux/state.interface';
import { defaultMatterEditPageUiState, LpmsMatterPageUiReduxObject } from '../../../matter-edit/redux/state';

export const defaultOtherEditPageUiState: IOtherMatterEditPageUiState = {
  ...defaultMatterEditPageUiState
};

@Injectable({ providedIn: 'root' })
export class LpmsOtherMatterEditPageUiReduxObject extends LpmsMatterPageUiReduxObject<IOtherMatterEditPageUiState> {
  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.otherEditPageState);
    super('OtherEditPage', getPageState);
    super.addState({ ...defaultOtherEditPageUiState, persistent: { ...defaultOtherEditPageUiState.persistent } }).finalize();
  }
}
