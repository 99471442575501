import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { LibraryFolderListModel, LibraryFolderListUpdateModel, ListLibraryFolderListModel } from '../../_lpms-api-models.generated';
import { LibraryFolderList } from '../models/app/library-folder-list';
export class LibraryFolderListAdapterContext extends ApiCallContext {
  id: string;
}
@Injectable({
  providedIn: 'root'
})
export class LibraryFolderListAdapter extends ApiDataAdapter<LibraryFolderList> {
  constructor() {
    super();
  }
  toDeleteModel(model: any): any {
    return null;
  }
  fromGetNewModel(context?: ApiCallContext): SafariRequired<LibraryFolderList> {
    return {
      id: '0',
      name: '',
      folderNames: [],
      dateUpdated: null,
      updatedBy: null,
      matterTypes: [],
      matterSubtypeIds: [],
      matterAttachmentTypes: []
    };
  }
  toCreateModel(o: LibraryFolderList): SafariRequired<LibraryFolderListUpdateModel> {
    return {
      name: o.name,
      folderNames: o.folderNames,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as number[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes
    };
  }

  toUpdateModel(o: LibraryFolderList): SafariRequired<LibraryFolderListUpdateModel> {
    return {
      name: o.name,
      folderNames: o.folderNames,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as number[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes
    };
  }

  fromGetModel(o: LibraryFolderListModel): SafariRequired<LibraryFolderList> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      folderNames: o.folderNames,
      dateUpdated: null,
      updatedBy: null,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as string[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes
    };
  }
  fromListModel(o: ListLibraryFolderListModel): SafariRequired<LibraryFolderList> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      folderNames: o.folderNames,
      dateUpdated: o.dateUpdated,
      updatedBy: o.updatedBy,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as string[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes
    };
  }
}
