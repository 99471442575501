import { Injectable } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';
import { LpmsInfoRequestEditPageUiReduxObject } from '../matter/document-request/document-request-edit/redux/state';
import { LpmsGarnishmentEditPageUiReduxObject } from '../matter/garnishment/garnishment-edit/redux/state';
import { LpmsLitigationEditPageUiReduxObject } from '../matter/litigation/litigation-edit/redux/state';
import { LpmsMatterCreatePageUiReduxObject } from '../matter/matter-create/components/matter-create/redux/state';
import { LpmsMatterListPageUiReduxObject } from '../matter/matter-list/redux/state';
import { LpmsOtherMatterEditPageUiReduxObject } from '../matter/other-matter/other-matter-edit/redux/state';
import { LpmsReportEditPageReduxUiObject } from '../reports/preconfigured-report-edit/redux/state';
import { ServingPartyListPageUiReduxObject } from '../serving-party/serving-party-list/redux/state';

import { LpmsCompanyEntityEditEditPageReduxUiObject } from '../company-entity/company-entity-edit/redux/state';
import { LpmsInboundEmailsListPageReduxUiObject } from '../company/intake-email/redux/state';
import { LpmsRegisteredAgentSettingsPageReduxUiObject } from '../company/registered-agent/redux/state';
import { LpmsServeportPortalEdtPageReduxUiObject } from '../company/serveport-portals/serveport-portal-edit/redux/state';
import { LpmsSettingsGeneralEditPageReduxUiObject } from '../company/settings-general-edit/redux/state';
import { LpmsInvoiceTemplateEditPageReduxUiObject } from '../invoice-templates/invoice-template-edit/redux/state';
import { LpmsInvoiceTemplateListPageReduxUiObject } from '../invoice-templates/invoice-template-list/redux/state';
import { LpmsLibraryDocumentEditPageReduxUiObject } from '../library/library-document-edit/redux/state';
import { LpmsLibraryDocumentListPageReduxUiObject } from '../library/library-document-list/redux/state';
import { LpmsLibraryTemplateEditPageReduxUiObject } from '../library/library-template-edit/redux/state';
import { LpmsLibraryTemplateListPageReduxUiObject } from '../library/library-template-list/redux/state';
import { LpmsMatterListConfigPageReduxUiObject } from '../matter-list-config/components/redux/state';
import { LpmsMatterSubtypeEditPageReduxUiObject } from '../matter-subtype/matter-subtype-edit/redux/state';
import { LpmsMatterSubtypeListPageReduxUiObject } from '../matter-subtype/matter-subtype-list/redux/state';
import { LpmsServingPartyEditPageReduxUiObject } from '../serving-party/serving-party-edit/redux/state';
import { LpmsMatterSearchPageReduxUiObject } from '../shared/components/matter-search/redux/state';
import { LpmsSubfolderGroupEditPageReduxUiObject } from '../subfolder-groups/subfolder-group-edit/redux/state';
import { LpmsSubfolderGroupListPageReduxUiObject } from '../subfolder-groups/subfolder-group-list/redux/state';
import { LpmsCoUserEditPageReduxUiObject } from '../user/co-user-edit/redux/state';
import { LpmsCoUserListPageReduxUiObject } from '../user/co-user-list/redux/state';
import { ILpmsUiState } from './state.interface';

/* __SAFARI_SCHEMATICS (IMPORTS)__ */

// Technically this service only exists for LPMS feature, but because its used during the initialization
// it can't be declared as provided in FeatureLpmsUiModule since it would result in a dependency to itself before initializing
@Injectable()
export class LpmsUiReducerService {
  constructor(
    private _infoRequestEditPageUiReduxObject: LpmsInfoRequestEditPageUiReduxObject,
    private _garnishmentEditPageUiReduxObject: LpmsGarnishmentEditPageUiReduxObject,
    private _otherMatterEditPageUiReduxObject: LpmsOtherMatterEditPageUiReduxObject,
    private _litigationEditPageUiReduxObject: LpmsLitigationEditPageUiReduxObject,
    private _matterCreatePageUiReduxObject: LpmsMatterCreatePageUiReduxObject,
    private _matterListPageUiReduxObject: LpmsMatterListPageUiReduxObject,
    private _reportEditPageReduxUiObject: LpmsReportEditPageReduxUiObject,
    private _matterSearchPageReduxObject: LpmsMatterSearchPageReduxUiObject,
    private _serveportPortalEditPageReduxObject: LpmsServeportPortalEdtPageReduxUiObject,
    private _servingPartyListPageReduxObject: ServingPartyListPageUiReduxObject,
    private _inakeEmailEditPageReduxObject: LpmsInboundEmailsListPageReduxUiObject,
    private _registeredAgentSettingsPageReduxObject: LpmsRegisteredAgentSettingsPageReduxUiObject,
    private _settingsGeneralEditPageReduxObject: LpmsSettingsGeneralEditPageReduxUiObject,
    private _companyEditPageUiReduxObject: LpmsCompanyEntityEditEditPageReduxUiObject,
    private _companyListPageUiReduxObject: LpmsCompanyEntityEditEditPageReduxUiObject,
    private _invoiceTemplateEditPageUiReduxObject: LpmsInvoiceTemplateEditPageReduxUiObject,
    private _invoiceTemplateListPageUiReduxObject: LpmsInvoiceTemplateListPageReduxUiObject,
    private _libraryDocumentListPageUiReduxObject: LpmsLibraryDocumentListPageReduxUiObject,
    private _libraryDocumentEditPageUiReduxObject: LpmsLibraryDocumentEditPageReduxUiObject,
    private _libraryTemplateListPageUiReduxObject: LpmsLibraryTemplateListPageReduxUiObject,
    private _libraryTemplateEditPageUiReduxObject: LpmsLibraryTemplateEditPageReduxUiObject,
    private _customPicklistConfigListPageUiReduxObject: LpmsLibraryTemplateListPageReduxUiObject,
    private _customPicklistConfigEditPageUiReduxObject: LpmsLibraryTemplateEditPageReduxUiObject,
    private _matterListConfigPageUiReduxObject: LpmsMatterListConfigPageReduxUiObject,
    private _matterSubtypeEditPageUiReduxObject: LpmsMatterSubtypeEditPageReduxUiObject,
    private _matterSubtypeListPageUiReduxObject: LpmsMatterSubtypeListPageReduxUiObject,
    private _servingPartyEditPageReduxObject: LpmsServingPartyEditPageReduxUiObject,
    private _subfolderrGroupEditPageReduxObject: LpmsSubfolderGroupEditPageReduxUiObject,
    private _subfolderrGroupListPageReduxObject: LpmsSubfolderGroupListPageReduxUiObject,
    private _coUserEditPageReduxObject: LpmsCoUserEditPageReduxUiObject,
    private _coUserListPageReduxObject: LpmsCoUserListPageReduxUiObject
  ) {}
  get reducers() {
    /* EXAMPLE OF ADDING NEW MODEL FROM API - LOCATIONS THAT NEED TO BE UPDATED */
    const reducers: ActionReducerMap<ILpmsUiState> = {
      pages: combineReducers({
        matterSearch: UI_REDUCER(this._matterSearchPageReduxObject),
        matterListPageState: UI_REDUCER(this._matterListPageUiReduxObject),
        matterCreatePageState: UI_REDUCER(this._matterCreatePageUiReduxObject),
        infoRequestEditPageState: UI_REDUCER(this._infoRequestEditPageUiReduxObject),
        garnishmentEditPageState: UI_REDUCER(this._garnishmentEditPageUiReduxObject),
        otherEditPageState: UI_REDUCER(this._otherMatterEditPageUiReduxObject),
        litigationEditPageState: UI_REDUCER(this._litigationEditPageUiReduxObject),
        reportEditPageState: UI_REDUCER(this._reportEditPageReduxUiObject),
        servingPartyListPageState: UI_REDUCER(this._servingPartyListPageReduxObject),
        serveportPortalEditPage: UI_REDUCER(this._serveportPortalEditPageReduxObject),
        intakeEmailEditPage: UI_REDUCER(this._inakeEmailEditPageReduxObject),
        registeredAgentSettingsPage: UI_REDUCER(this._registeredAgentSettingsPageReduxObject),
        settingsGeneralEditPage: UI_REDUCER(this._settingsGeneralEditPageReduxObject),
        companyEntityEditPage: UI_REDUCER(this._companyEditPageUiReduxObject),
        companyEntityListPage: UI_REDUCER(this._companyListPageUiReduxObject),
        invoiceTemplateEditPage: UI_REDUCER(this._invoiceTemplateEditPageUiReduxObject),
        invoiceTemplateListPage: UI_REDUCER(this._invoiceTemplateListPageUiReduxObject),
        libraryDocumentListPage: UI_REDUCER(this._libraryDocumentListPageUiReduxObject),
        libraryDocumentEditPage: UI_REDUCER(this._libraryDocumentEditPageUiReduxObject),
        libraryTemplateEditPage: UI_REDUCER(this._libraryTemplateEditPageUiReduxObject),
        libraryTemplateListPage: UI_REDUCER(this._libraryTemplateListPageUiReduxObject),
        customPicklistConfigListPage: UI_REDUCER(this._customPicklistConfigListPageUiReduxObject),
        customPicklistConfigEditPage: UI_REDUCER(this._customPicklistConfigEditPageUiReduxObject),
        matterListConfigPage: UI_REDUCER(this._matterListConfigPageUiReduxObject),
        matterSubtypeEditPage: UI_REDUCER(this._matterSubtypeEditPageUiReduxObject),
        matterSubtypeListPage: UI_REDUCER(this._matterSubtypeListPageUiReduxObject),
        servingPartyEditPage: UI_REDUCER(this._servingPartyEditPageReduxObject),
        subfolderGroupEditPage: UI_REDUCER(this._subfolderrGroupEditPageReduxObject),
        subfolderGroupListPage: UI_REDUCER(this._subfolderrGroupListPageReduxObject),
        coUserEditPage: UI_REDUCER(this._coUserEditPageReduxObject),
        coUserListPage: UI_REDUCER(this._coUserListPageReduxObject)
      })
    };
    return reducers;
  }
}
