import { Injectable } from '@angular/core';

import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { cloneDeep } from 'lodash-es';
import { InvoiceTemplateLineItemModel, InvoiceTemplateModel, InvoiceTemplateUpdateModel, ListInvoiceTemplateModel } from '../../_lpms-api-models.generated';
import { InvoiceTemplate } from '../models/app/invoice-template';

@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplateAdapter extends ApiDataAdapter<InvoiceTemplate> {
  toCreateModel(o: InvoiceTemplate): Required<InvoiceTemplateUpdateModel> {
    return {
      description: o.description,
      isActive: o.isActive,
      lineItems: cloneDeep(o.lineItems),
      name: o.name
    };
  }

  toUpdateModel(o: InvoiceTemplate): Required<InvoiceTemplateUpdateModel> {
    return {
      description: o.description,
      isActive: o.isActive,
      lineItems: cloneDeep(o.lineItems),
      name: o.name
    };
  }

  fromGetModel(o: InvoiceTemplateModel): Required<InvoiceTemplate> {
    return {
      description: o.description,
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      lineItems: cloneDeep(o.lineItems) as Required<InvoiceTemplateLineItemModel>[],
      name: o.name,
      dateCreated: null,
      dateUpdated: null,
      totalAmount: 0
    };
  }
  fromListModel(o: ListInvoiceTemplateModel): Required<InvoiceTemplate> {
    return {
      totalAmount: o.totalAmount,
      description: o.description,
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      lineItems: null,
      name: o.name,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false)
    };
  }
  fromGetNewModel(): Required<InvoiceTemplate> {
    return {
      dateCreated: null,
      dateUpdated: null,
      description: null,
      id: ObjectHelper.getNewId(),
      isActive: true,
      lineItems: [
        {
          description: null,
          amount: 0
        }
      ],
      name: '',
      totalAmount: 0
    };
  }
}
