import { Injectable } from '@angular/core';
import { PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../../redux/index';

export class ServingPartyEditPageUiState extends PageUiState {}
@Injectable({ providedIn: 'root' })
export class LpmsServingPartyEditPageReduxUiObject extends SafariReduxPageUiObject<ServingPartyEditPageUiState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.servingPartyEditPage);
    super('Lpms', 'Serving Party Edit', getPageState, defaultPageUiState);
  }
}
