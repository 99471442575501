import { Injectable } from '@angular/core';
import { ApiCallContext, ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { LibraryDocumentModel, LibraryDocumentUpdateModel } from '../../_lpms-api-models.generated';
import { LibraryDocument } from '../models/app/library-document';

@Injectable({
  providedIn: 'root'
})
export class LibraryDocumentAdapter extends ApiDataAdapter<LibraryDocument> {
  toUpdateModel(o: LibraryDocument, context?: ApiCallContext): Required<LibraryDocumentUpdateModel> {
    return {
      name: o.name
    };
  }
  fromGetModel(o: LibraryDocumentModel): Required<LibraryDocument> {
    return {
      dateUpdated: o.dateUpdated,
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      updatedBy: o.updatedBy,
      // This comes back as string so make sure to turn it to int otherwise sorting will be funky
      sizeInBytes: +o.sizeInBytes
    };
  }
  fromListModel(o: LibraryDocumentModel): Required<LibraryDocument> {
    return {
      dateUpdated: o.dateUpdated,
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      updatedBy: o.updatedBy,
      // This comes back as string so make sure to turn it to int otherwise sorting will be funky
      sizeInBytes: +o.sizeInBytes
    };
  }
  toDeleteModel(model: any): any {
    return model;
  }
  fromGetNewModel(): Required<LibraryDocument> {
    return {
      dateUpdated: null,
      id: ObjectHelper.getNewId(),
      name: null,
      updatedBy: null,
      sizeInBytes: 0
    };
  }
}
