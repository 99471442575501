import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterTemplateFieldValueModel } from '../../_lpms-api-models.generated';
import { TemplateField } from '../models/app/template-field';

@Injectable({
  providedIn: 'root'
})
export class TemplateFieldApiAdapter extends ApiDataAdapter<TemplateField> {
  fromListModel(o: MatterTemplateFieldValueModel): Required<TemplateField> {
    return {
      id: this.helpers.fromApiId(o.field),
      field: o.field,
      value: o.value
    };
  }
}
