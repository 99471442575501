/* eslint-disable no-restricted-syntax -- framework */
/**
 * DO NOT EXPORT THIS FILE IN INDEX.TS
 * THERE IS NO REASON FOR WEBAPP OR ANYTHING ELSE TO KNOW ABOUT THIS
 */
import { inject, InjectionToken } from '@angular/core';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, AttachmentLinkAdapter, CrudService, DropdownService, FileObjectAdapter, HelperObjectAdapterPOST, ServiceConfiguration } from '@safarilaw-webapp/shared/crud';
import { CompanyEntity } from '../company-entity/models/app/company-entity';
import { CompanySettings } from '../company-settings/models/app/company-settings';

import { DocumentAlert } from '../document-alert/models/app/document-alert';
import { DocumentRequest } from '../document-request/models/app/document-request';
import { DropdownMatterSubtypeUser } from '../dropdown/dropdown-matter-subtype-user/models/app/dropdown-matter-subtype-user';
import { DropdownMatterSubtype } from '../dropdown/dropdown-matter-subtype/models/app/dropdown-matter-subtype';
import { Garnishment } from '../garnishment/models/app/garnishment';
import { InvoiceTemplate } from '../invoice-template/models/app/invoice-template';
import { InvoiceTemplatePreview } from '../invoice-template/models/app/invoice-template-preview';
import { LibraryDocument } from '../library/models/app/library-document';
import { LibraryTemplate } from '../library/models/app/library-template';
import { Litigation } from '../litigation/models/app/litigation';
import { MatterCount } from '../matter-count/models/app/matter-count';
import { MatterSearch } from '../matter-search/models/matter-search';
import { Matter } from '../matter/models/app/matter';
import { OtherMatter } from '../other-matter/models/app/other-matter';
import { PortalActivity } from '../portal-activity/models/app/portal-activity';
import { ServingPartySearch } from '../serving-party-search/models/serving-party-search';
import { ServingParty } from '../serving-party/models/app/serving-party';

import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import { AllowedUserAdapter } from '../allowed-user/adapters/allowed-user.adapter';
import { AttachmentFolderAdapter } from '../attachment-folder/adapters/attachment-folder.adapter';
import { AttachmentAnswerMetadataAdapter, AttachmentMetadataAdapter } from '../attachment-metadata/adapters/attachment-metadata-adapter';
import { AttachmentTemplateAdapter } from '../attachment-template/adapters/attachment-template-adapter-document-request';
import { ChangeSubtypeAdapter } from '../change-subtype/adapters/change-subtype-adapter';
import { CompanyEntityAdapter } from '../company-entity/adapters/company-entity-adapter';
import { CompanyPicklistAdapter } from '../company-picklist/adapters/company-picklist-adapter';
import { CompanyPicklist } from '../company-picklist/models/app/company-picklist';
import { CompanySettingsAdapter } from '../company-settings/adapters/company-settings-adapter';
import { CorrespondenceAttachmentAdapter } from '../correspondence-attachment/adapters/correspondence-attachment.adapter';
import { CorrespondenceAdapter } from '../correspondence/adapters/correspondence-adapter';
import { CorrespondenceResendAdapter } from '../correspondence/adapters/correspondence-resend.adapter';
import { DocumentAlertAdapter } from '../document-alert/adapters/document-alert-adapter';
import { DocumentRequestAdapter } from '../document-request/adapters/document-request-adapter';
import { DropdownApiAdapter } from '../dropdown/dropdown-adapter/dropdown-adapter';
import { DropdownMatterSubtypeUserAdapter } from '../dropdown/dropdown-matter-subtype-user/adapters/dropdown-matter-subtype-user.adapter';
import { DropdownMatterSubtypeAdapter } from '../dropdown/dropdown-matter-subtype/adapters/dropdown-matter-subtype.adapter';
import { MatterType } from '../enums';
import { LPMS_TRANSFER_DIALOG_OBJECT } from '../feature-lpms-data-access.module';
import { GarnishmentAdapter } from '../garnishment/adapters/garnishment-adapter';
import { InvoiceTemplateAdapter } from '../invoice-template/adapters/invoice-template-adapter';
import { InvoiceTemplatePreviewAdapter } from '../invoice-template/adapters/invoice-template-preview-adapter';
import { LibraryDocumentAdapter } from '../library/adapters/library-document-adapter';
import { LibraryFolderListAdapter } from '../library/adapters/library-folder-list-adapter';
import { LibraryTemplateAdapter } from '../library/adapters/library-template-adapter';
import { LibraryFolderList } from '../library/models/app/library-folder-list';
import { LinkAdapter } from '../link/adapters/link.adapter';
import { LitigationAdapter } from '../litigation/adapters/litigation-adapter';
import { MatterCountAdapter } from '../matter-count/adapters/matter-count-adapter';
import { MatterSearchAdapter } from '../matter-search/adapters/matter-search-adapter';
import { LookupAdapter } from '../matter/adapters/lookup-adapter';
import { AllMattersAdapter } from '../matter/adapters/matter-adapter';
import { NoteAdapter } from '../notes/adapters/note-adapter';
import { OrganizationSearchAdapter } from '../organization-search/adapters/organization-search-adapter';
import { OrganizationSearch } from '../organization-search/models/organization-search';
import { OrganizationAdapter } from '../organization/adapters/organization-adapter';
import { Organization } from '../organization/models/app/organization';
import { OtherMatterAdapter } from '../other-matter/adapters/other-matter-adapter';
import { ParticipantAdapter } from '../participant/adapters/participant-adapter';
import { PortalActivityAdapter } from '../portal-activity/adapters/portal-activity-adapter';
import { RecentAdapter } from '../recent/adapters/recent.adapter';
import { ResendPortalInviteAdapter } from '../resend-portal-invite/adapters/resend-portal-invite-adapter';
import { ServingPartySearchAdapter } from '../serving-party-search/adapters/serving-party-search-adapter';
import { ServingPartyAdapter } from '../serving-party/adapters/serving-party-adapter';
import { StarAdapter } from '../star/adapters/star.adapter';
import { SubjectSearchAdapter } from '../subject-search/adapters/subject-search-adapter';
import { TemplateFieldApiAdapter } from '../template-field/adapters/template-field-adapter';
import { TransferActivityAdapter } from '../transfer-activity/adapters/transfer-activity.adapter';
import { TransferAdapter } from '../transfer/adapters/transfer-adapter';
import { Transfer } from '../transfer/models/app/transfer';
import { UpdatePortalAdapter } from '../update-portal/adapters/update-portal-adapter';

import { AttachmentFolderMoverAdapter } from '../attachment-folder/adapters/attachment-folder-mover.adapter';
import { AttachmentMoverAdapter } from '../attachment-metadata/adapters/attachment-mover-adapter';
import { MatterReduxObjectType } from './injectable-object-tokens';

const matterWhitelist = [
  'deliveryStatus',
  'alerts',
  'financialAttachments',
  'servedAttachments',
  'correspondenceAttachments',
  'internalAttachments',
  'responseAttachments',
  'participants',
  'noteCount',
  'answerAttachments',
  'collectionAttachments',
  'isStarred',
  'linkCount',
  'unpaidInvoiceAttachmentId'
];

export const COMPANY_ENTITY_SERVICE_TOKEN = new InjectionToken<CrudService<CompanyEntity>>('COMPANY_ENTITY_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<CompanyEntity>(
      inject(CompanyEntityAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'companyEntities', [], null, {
        logo: `${inject(AppConfigurationService).urls.apiRootLpms}companies/{0}/entities/{1}/logo`
      })
    )
});

export const INVOICE_TEMPLATE_SERVICE_TOKEN = new InjectionToken<CrudService<InvoiceTemplate>>('INVOICE_TEMPLATE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<InvoiceTemplate>(inject(InvoiceTemplateAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'invoiceTemplates'))
});
export const INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN = new InjectionToken<CrudService<InvoiceTemplatePreview>>('INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<InvoiceTemplatePreview>(
      inject(InvoiceTemplatePreviewAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'invoiceTemplates/{0}/preview')
    )
});
export const DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN = new InjectionToken<CrudService<InvoiceTemplatePreview>>('DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<InvoiceTemplatePreview>(
      inject(InvoiceTemplatePreviewAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/invoicePreview')
    )
});
export const MATTER_COUNTS_SERVICE_TOKEN = new InjectionToken<CrudService<MatterCount>>('MATTER_COUNTS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<MatterCount>(inject(MatterCountAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'my/counts'))
});
export const DROPDOWN_SERVICE_TOKEN = new InjectionToken<DropdownService>('DROPDOWN_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new DropdownService(inject(HttpClient), inject(AppConfigurationService).urls.apiRootLpms, inject(DropdownApiAdapter))
});
export const STARRED_MATTER_SERVICE_TOKEN = new InjectionToken<CrudService<Matter>>('STARRED_MATTER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Matter>(
      inject(StarAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'my/starred', null, {
        // Note the endpoint override. Usually the service would expect delete endpoint to also be my/starred
        // from which it would derived my/starred/{ID} based on the matters ID. However, delete star endpoint
        // is a bit weird in that its POST endpoint differs quite a bit from delete. Delete expects
        // my/starred/{type}/{id} (probably because at this time we can't add body to DELETE methods. There is
        // a pending research to address this so if it works out maybe we can update this....)
        // So... to get this to fire we'll override delete endpoint like this
        // and pass hierarchical ID [type,id] when deleting. This will generate the proper endpoint
        delete: 'my/starred/{0}'
      })
    )
});
export const RECENT_MATTER_SERVICE_TOKEN = new InjectionToken<CrudService<Matter>>('RECENT_MATTER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<Matter>(inject(RecentAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'my/recent', null))
});
export const ALL_MATTERS_SERVICE_TOKEN = new InjectionToken<CrudService<Matter>>('ALL_MATTERS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<Matter>(inject(AllMattersAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matters', null))
});
export const LIBRARY_TEMPLATE_SERVICE_TOKEN = new InjectionToken<CrudService<LibraryTemplate>>('LIBRARY_TEMPLATE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<LibraryTemplate>(
      inject(LibraryTemplateAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'library/templates')
    )
});
export const LIBRARY_DOCUMENT_SERVICE_TOKEN = new InjectionToken<CrudService<LibraryDocument>>('LIBRARY_DOCUMENT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<LibraryDocument>(
      inject(LibraryDocumentAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'library/documents', [], { put: 'library/documents/{0}/metadata' })
    )
});
export const LIBRARY_FOLDER_LIST_SERVICE_TOKEN = new InjectionToken<CrudService<LibraryFolderList>>('LIBRARY_FOLDER_LIST_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<LibraryFolderList>(
      inject(LibraryFolderListAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'library/folderlists')
    )
});
export const DROPDOWN_MATTER_SUBTYPE_SERVICE_TOKEN = new InjectionToken<CrudService<DropdownMatterSubtype>>('DROPDOWN_MATTER_SUBTYPE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<DropdownMatterSubtype>(
      inject(DropdownMatterSubtypeAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matterSubtypes')
    )
});
export const DROPDOWN_MATTER_SUBTYPE_USER_SERVICE_TOKEN = new InjectionToken<CrudService<DropdownMatterSubtypeUser>>('DROPDOWN_MATTER_SUBTYPE_USER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<DropdownMatterSubtypeUser>(
      inject(DropdownMatterSubtypeUserAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matterSubtypes/{0}/users')
    )
});
export const MATTER_ALERTS_SERVICE_TOKEN = new InjectionToken<CrudService<DocumentAlert>>('MATTER_ALERTS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<DocumentAlert>(inject(DocumentAlertAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matters/alerts'))
});
export const MATTER_LOOKUP_SERVICE_TOKEN = new InjectionToken<CrudService<Matter>>('MATTER_LOOKUP_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<Matter>(inject(LookupAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matters/lookup'))
});
export const CHANGE_SUBTYPE_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('CHANGE_SUBTYPE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(ChangeSubtypeAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'matters/{0}/changeSubtype')
    )
});
export const EXTEND_PORTAL_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('EXTEND_PORTAL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(ChangeSubtypeAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/extendPortalAccess')
    )
});
export const RESEND_ANSWER_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('CONFIRM_RECEIPT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(HelperObjectAdapterPOST),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'garnishments/{0}/attachments/{1}/resend')
    )
});
export const SEND_PORTAL_REMINDER_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('SEND_PORTAL_REMINDER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(ChangeSubtypeAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/sendPortalReminder')
    )
});

export const UPDATE_PORTAL_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('UPDATE_PORTAL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(UpdatePortalAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/portal')
    )
});
export const RESEND_PORTAL_INVITE_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('RESEND_PORTAL_INVITE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(ResendPortalInviteAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/portal/resendInvite')
    )
});

export const LITIGATION_SERVICE_TOKEN = new InjectionToken<CrudService<Litigation>>('LITIGATION_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Litigation>(
      inject(LitigationAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'litigations', matterWhitelist)
    )
});

export const OTHER_MATTER_SERVICE_TOKEN = new InjectionToken<CrudService<OtherMatter>>('OTHER_MATTER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<OtherMatter>(
      inject(OtherMatterAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'otherMatters', matterWhitelist)
    )
});

//
export const GARNISHMENT_SERVICE_TOKEN = new InjectionToken<CrudService<Garnishment>>('GARNISHMENT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Garnishment>(
      inject(GarnishmentAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'garnishments', matterWhitelist)
    )
});

//
export const DOCUMENT_REQUEST_SERVICE_TOKEN = new InjectionToken<CrudService<DocumentRequest>>('DOCUMENT_REQUEST_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<DocumentRequest>(
      inject(DocumentRequestAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests', [
        ...matterWhitelist,
        ...['portalStatus', 'portalRecipient.paymentDate', 'portalRecipient.lastAccessDate', 'portalRecipient.hasPaid']
      ])
    )
});

export const DOCUMENT_REQUEST_PORTAL_ACTIVITY_SERVICE_TOKEN = new InjectionToken<CrudService<PortalActivity>>('DOCUMENT_REQUEST_PORTAL_ACTIVITY_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<PortalActivity>(
      inject(PortalActivityAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'documentRequests/{0}/portalActivity')
    )
});
export const LPMS_COMPANY_SETTINGS_SERVICE_TOKEN = new InjectionToken<CrudService<CompanySettings>>('LPMS_COMPANY_SETTINGS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<CompanySettings>(inject(CompanySettingsAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'settings'))
});
export const SERVING_PARTY_SERVICE_TOKEN = new InjectionToken<CrudService<ServingParty>>('SERVING_PARTY_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<ServingParty>(inject(ServingPartyAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'servingParties'))
});
export const MATTER_SEARCH_SERVICE_TOKEN = new InjectionToken<CrudService<MatterSearch>>('MATTER_SEARCH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<MatterSearch>(inject(MatterSearchAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'search/matters'))
});

export const ORGANIZATION_SEARCH_SERVICE_TOKEN = new InjectionToken<CrudService<OrganizationSearch>>('ORGANIZATION_SEARCH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<OrganizationSearch>(
      inject(OrganizationSearchAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'search/organizations')
    )
});

export const ORGANIZATION_SERVICE_TOKEN = new InjectionToken<CrudService<Organization>>('ORGANIZATION_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Organization>(inject(OrganizationAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'organizations'))
});

export const SERVING_PARTY_SEARCH_SERVICE_TOKEN = new InjectionToken<CrudService<ServingPartySearch>>('SERVING_PARTY_SEARCH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<ServingPartySearch>(
      inject(ServingPartySearchAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'search/servingPartyContacts')
    )
});

export const GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'reports/matters/attachments/link')
    )
});
export const GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'reports/matters/reviewerHistory/link')
    )
});
export const GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'reports/matters/participants/link')
    )
});
export const GENERATE_REPORTS_MATTER_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_MATTER_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'reports/matters/link')
    )
});
export const GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'reports/garnishments/ledgerTransactions/link')
    )
});

export const TRANSFER_SERVICE_TOKEN = new InjectionToken<CrudService<Transfer>>('TRANSFER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Transfer>(inject(TransferAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'garnishments/{0}/ledger'))
});
export const TRANSFER_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('TRANSFER_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'garnishments/{0}/ledger/{1}/link')
    )
});
export const LIBRARY_DOCUMENT_ATTACHMENT_LINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('LIBRARY_DOCUMENT_ATTACHMENT_LINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'library/documents/{0}/link')
    )
});
export const COMPANY_PICKLIST_SERVICE_TOKEN = new InjectionToken<CrudService<CompanyPicklist>>('COMPANY_PICKLIST_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<CompanyPicklist>(
      inject(CompanyPicklistAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'companyPicklists/{0}'),
      inject(LPMS_TRANSFER_DIALOG_OBJECT)
    )
});

export const LIBRARY_DOCUMENT_FILE_OBJECT_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('LIBRARY_DOCUMENT_FILE_OBJECT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'library/documents'),
      inject(LPMS_TRANSFER_DIALOG_OBJECT)
    )
});
export const COMPANY_ENTITY_LOGO_FILE_OBJECT_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('COMPANY_ENTITY_LOGO_FILE_OBJECT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'companyEntities/{0}/logo'),
      inject(LPMS_TRANSFER_DIALOG_OBJECT)
    )
});
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/

/* *******************************************************************************
 * *******************************************************************************
 *
 * CODE BELOW IS EXCLUSIVELY FOR MATTER REDUX OBJECTS. THESE ARE CHILD OBJECTS FOR 4 MATTER TYPES
 * THAT ARE ALMOST IDENTICAL BUT HAVE DIFFERENT ENDPOINTS. INSTEAD OF COPY-PASTING A TON OF REPETITIVE
 * INJECTION STATEMENTS WE NOW USE A HANDFUL OF AUTOMATION FUNCITONS BELOW TO REDUCE REPETITION
 *
 * IMPORTANT: ALL SERVICES HAVE TO HAVE NORMAL/CONSISTENT API ENDPOINTS. WE CAN'T HAVE SERVICES THAT VARY
 * PUT/POST ENDPOINTS ETC.
 */

// matterServiceMap is used by matter redux object generation
export const matterServiceMap: Map<string, InjectionToken<any>> = new Map<string, InjectionToken<any>>();

// Set parent endpoints for each matter type
const endpointMappings = new Map<MatterType, string>([
  [MatterType.DocumentRequest, 'documentRequests'],
  [MatterType.Garnishment, 'garnishments'],
  [MatterType.Other, 'otherMatters'],
  [MatterType.Litigation, 'litigations']
]);
/**
 * This function loops through all 4 matter types and generates one child object per each matter type.
 * objectName is used to generate a well-known name for the service in the map using convention, so that
 * redux objects can find the one they need during injection.
 *
 * @param objectName Childname of the object (correspondence, attachment, note, link, etc)
 * @param apiAdapter Adapter for the object
 * @param childUrl API URL part after the parent URL (e.g. 'notes', 'correspondence{1}/attachments', 'links', etc)
 */
const addServiceToMatterReduxServiceMappings = <T extends SafariObject>(objectName: MatterReduxObjectType, apiAdapter: ApiDataAdapter<T>, childUrl: string, hasFileTransfer = false) => {
  for (const key of Object.keys(MatterType).filter(x => isNaN(x as any))) {
    const endpoint = endpointMappings.get(MatterType[key]);
    const fullName = (key + '_' + objectName.toString()).toUpperCase() + '_REDUX_SERVICE_TOKEN';
    const token = new InjectionToken<CrudService<T>>(fullName, {
      providedIn: 'root',
      factory: () =>
        new CrudService<T>(
          apiAdapter,
          inject(ApplicationInsightsService),
          new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, `${endpoint}/{0}/${childUrl}`),
          hasFileTransfer ? inject(LPMS_TRANSFER_DIALOG_OBJECT) : null
        )
    });
    matterServiceMap.set(fullName, token);
  }
};

/**
 * Add any new matter children here to have them automatically added to the matterServiceMap
 */
export const setMatterServices = () => {
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.Note, new NoteAdapter(), 'notes');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.Participant, new ParticipantAdapter(), 'participants');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.Link, new LinkAdapter(), 'links', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.TransferActivity, new TransferActivityAdapter(), 'transferActivities');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.Correspondence, new CorrespondenceAdapter(), 'correspondence');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.CorrespondenceAttachment, new CorrespondenceAttachmentAdapter(), 'correspondence/{1}/attachments', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.CorrespondenceAttachmentFileObject, new FileObjectAdapter(), 'correspondence/{1}/attachments', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentTemplate, new AttachmentTemplateAdapter(), 'attachmentTemplates');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.TemplateField, new TemplateFieldApiAdapter(), 'templateFields');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AllowedUser, new AllowedUserAdapter(), 'users');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.CorrespondenceResend, new CorrespondenceResendAdapter(), 'correspondence/{1}/resend');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentMetadata, new AttachmentMetadataAdapter(new AttachmentAnswerMetadataAdapter()), 'attachments', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentFileObject, new FileObjectAdapter(), 'attachments', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentFolder, new AttachmentFolderAdapter(), 'attachmentFolders', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentFolderMover, new AttachmentFolderMoverAdapter(), 'attachmentFolders/move', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.AttachmentMover, new AttachmentMoverAdapter(), 'attachments/move', true);
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.SubjectSearch, new SubjectSearchAdapter(), 'integrations/subjectSearches');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.SubjectSearchTempLink, new AttachmentLinkAdapter(), 'integrations/subjectSearches/{1}/link');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.CorrespondenceAttachmentTempLink, new AttachmentLinkAdapter(), 'correspondence/{1}/attachments/{2}/link');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.MatterAttachmentTempLink, new AttachmentLinkAdapter(), 'attachments/{1}/link');
  addServiceToMatterReduxServiceMappings(MatterReduxObjectType.MatterAttachmentZipLink, new AttachmentLinkAdapter(), 'attachments/zip');
};
