import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import * as api from '../models/api/dropdown-matter-status-filter';
import * as app from '../models/app/dropdown-matter-status-filter';

@Injectable({
  providedIn: 'root'
})
export class DropdownMatterStatusFilterAdapter extends ApiDropdownAdapter {
  fromListModel(model: api.DropdownMatterStatusFilter): Required<app.DropdownMatterStatusFilter> {
    return {
      ...super.fromListModel(model),

      isMatterTypeRestricted: model.isMatterTypeRestricted,
      matterTypes: model.matterTypes
    };
  }
}
