import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { OrganizationModelPagedResponseModel } from '../../_lpms-api-models.generated';
import { OrganizationAdapter } from '../../organization/adapters/organization-adapter';
import { Organization } from '../../organization/models/app/organization';
import { OrganizationSearch, OrganizationSearchRequest, OrganizationSearchResponse } from '../models/organization-search';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSearchAdapter extends ApiDataAdapter<OrganizationSearch> {
  constructor(private _organizationAdapter: OrganizationAdapter) {
    super();
  }

  toCreateModel(o: any): SafariRequired<OrganizationSearchRequest> {
    return {
      query: o.query,
      top: o.top,
      skip: o.skip,
      orderBy: o.orderBy,
      id: null
    };
  }

  fromGetModel(list: OrganizationModelPagedResponseModel): SafariRequired<OrganizationSearchResponse> {
    const response: OrganizationSearchResponse = {
      id: null,
      items: []
    };
    list.items.forEach(organization => {
      response.items.push(this._organizationAdapter.fromListModel(organization as Organization));
    });
    return response;
  }
}
