import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { BulkMoveMatterAttachmentFolderModel } from '../../_lpms-api-models.generated';
import { AttachmentFolderMover } from '../models/app/attachment-folder-mover';
import { SafariBulkObject } from '@safarilaw-webapp/shared/common-objects-models';

@Injectable({
  providedIn: 'root'
})
export class AttachmentFolderMoverAdapter extends ApiDataAdapter<AttachmentFolderMover> {
  toUpdateMultipleModel(o: SafariBulkObject<AttachmentFolderMover>): SafariRequired<BulkMoveMatterAttachmentFolderModel> {
    return {
      attachmentFolders: o.objects.map(obj => ({
        attachmentFolderId: this.helpers.toApiId(obj.id),
        destinationType: obj.destinationType
      }))
    };
  }
}
