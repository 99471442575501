import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { CorrespondenceModel, CreateCorrespondenceModel, ReplyToOption } from '../../_lpms-api-models.generated';
import { Correspondence } from '../models/app/correspondence';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenceAdapter extends ApiDataAdapter<Correspondence> {
  toCreateModel(o: Correspondence): SafariRequired<CreateCorrespondenceModel> {
    return {
      body: o.body,
      fileName: o.fileName,
      recipientName: o.recipientName,
      subject: o.subject,
      to: o.to,
      type: o.type,
      isDraft: o.isDraft,
      replyToOption: o.replyToOption as ReplyToOption,
      cc: o.cc
    };
  }
  toUpdateModel(o: Correspondence): SafariRequired<CreateCorrespondenceModel> {
    return {
      body: o.body,
      fileName: o.fileName,
      recipientName: o.recipientName,
      subject: o.subject,
      to: o.to,
      type: o.type,
      isDraft: o.isDraft,
      replyToOption: o.replyToOption as ReplyToOption,
      cc: o.cc
    };
  }
  fromGetModel(o: CorrespondenceModel): SafariRequired<Correspondence> {
    return {
      body: o.body,
      createdBy: o.createdBy,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      updatedBy: o.updatedBy,
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      dateSent: this.helpers.fromApiDate(o.dateSent, false),
      errorMessage: o.errorMessage,
      fileName: o.fileName,
      from: o.from,
      id: this.helpers.fromApiId(o.id),
      recipientName: o.recipientName,
      status: o.status,
      subject: o.subject,
      to: o.to,
      type: o.type,
      recipientIsServingParty: o.recipientIsServingParty,
      attachmentMetadataId: this.helpers.fromApiId(o.attachmentMetadataId),
      isDraft: o.isDraft,
      replyToOption: o.replyToOption,
      replyTo: o.replyTo,
      attachmentCount: o.attachmentCount,
      cc: o.cc
    };
  }
  fromListModel(o: CorrespondenceModel): SafariRequired<Correspondence> {
    return {
      body: o.body,
      createdBy: o.createdBy,
      updatedBy: o.updatedBy,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      dateSent: this.helpers.fromApiDate(o.dateSent, false),
      errorMessage: o.errorMessage,
      fileName: o.fileName,
      from: o.from,
      id: this.helpers.fromApiId(o.id),
      recipientName: o.recipientName,
      status: o.status,
      subject: o.subject,
      to: o.to,
      type: o.type,
      recipientIsServingParty: o.recipientIsServingParty,
      isDraft: o.isDraft,
      attachmentMetadataId: this.helpers.fromApiId(o.attachmentMetadataId),
      attachmentCount: o.attachmentCount,
      replyToOption: o.replyToOption,
      replyTo: o.replyTo,
      cc: o.cc
    };
  }
  toDeleteModel(o: any) {
    return o;
  }
}
