import { Injectable } from '@angular/core';

import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, PatchObject, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DateTime } from 'luxon';
import { EnterLitigationDataModel, LitigationModel, MatterType, ReceiveLitigationModel } from '../../_lpms-api-models.generated';
import { LitigationStatus } from '../../enums';
import { Litigation } from '../../litigation/models/app/litigation';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { Matter } from '../../matter/models/app/matter';
import { MatterAdapterHelper } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class LitigationAdapter extends ApiDataAdapter<Litigation> {
  constructor(
    private __matterAdapterHelper: MatterAdapterHelper,
    private _matterAdapter: MatterAdapter
  ) {
    super();
  }
  fromGetNewModel(): SafariRequired<Litigation> {
    return {
      dateAnswerDue: null,

      attachmentTemplates: [],

      id: ObjectHelper.getNewId(),

      entityServedId: null,
      otherNameServed: null,
      safariId: '',
      servingPartyContact: null,
      servingPartyEmail: null,
      servingPartyAdditionalEmail: null,
      servingPartyPhone: null,
      servingPartyPhoneExtension: null,
      servingPartyFax: null,
      servingPartyMobile: null,
      caseNumber: null,
      dateFiled: null,
      dateReceived: DateTime.now().toISO(),

      defendants: null,

      documentTitle: null,
      servingPartyOrgType: 0,
      servingPartyOrgName: null,
      servingPartyOrgAddress: null,
      issuingAuthorityType: 0,
      issuingAuthorityName: null,
      issuingAuthorityAddress: null,
      forumContactAddress: null,
      methodOfService: null,
      plaintiffs: null,
      matterName: null,
      receivedBy: null,
      status: null,
      subjects: [],
      matterSubtypeId: null,
      reviewerId: null,
      type: MatterType.Litigation,

      forumTypeAndName: '',

      isStarred: false,
      otherInfo: '',

      noteCount: 0,
      linkCount: 0,
      alerts: [],

      hasEntityNameDiscrepancy: null,
      hasRestrictedAccess: null,
      internalReferenceId: null,

      stateReceived: null,
      financialNotes: null,
      issuingAuthority: null,
      forumSameAsIssuingAuthority: false,
      noJurisdictionResolution: null,
      notDomesticatedServiceResolution: null,
      answerAttachments: []
    } as any;
  }

  toUpdatePartialModel(formModel: Partial<Litigation>, originalObject: Partial<Litigation>): PatchObject {
    return super.getPatchObject(formModel);
  }
  toCreateModel(litigation: Matter): Required<ReceiveLitigationModel> {
    return {
      ...this._matterAdapter.toCreateModel(litigation),
      dateAnswerDue: null
    };
  }

  toUpdateModel(litigation: Litigation): Omit<Required<EnterLitigationDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] } {
    const retVal: Omit<Required<EnterLitigationDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] } = {
      ...this._matterAdapter.toUpdateModel(litigation),
      status: litigation.status as LitigationStatus,
      closedReason: litigation.closedReason,
      dateAnswerDue: litigation.dateAnswerDue ? this.helpers.toApiDate(litigation.dateAnswerDue) : litigation.dateAnswerDue,
      amountClaimed: litigation.amountClaimed,
      judgmentAmountCollected: litigation.judgmentAmountCollected,
      judgmentAmountPaid: litigation.judgmentAmountPaid,
      judgmentSought: litigation.judgmentSought
    };

    return retVal;
  }

  fromGetModel(litigation: Omit<LitigationModel, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] }): Required<Litigation> {
    const retVal = {
      ...this._matterAdapter.fromCommonGetModel(litigation),

      type: MatterType.Litigation,
      dateAnswerDue: this.helpers.fromApiDate(litigation.dateAnswerDue),
      amountClaimed: litigation.amountClaimed,
      judgmentAmountCollected: litigation.judgmentAmountCollected,
      judgmentAmountPaid: litigation.judgmentAmountPaid,
      judgmentSought: litigation.judgmentSought,
      answerAttachments: []
    };

    return retVal;
  }
  fromHistory(model: any): any {
    return model;
  }
}
