import { Inject, Injectable } from '@angular/core';

import { GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN, MatterReduxObjectType, getMatterReduxObject } from './injectable-object-tokens';

import { MatterType } from '../_lpms-api-models.generated';

import { MatterMapperBase } from './matter-redux-object';

@Injectable({
  providedIn: 'root'
})
export class GarnishmentMapper extends MatterMapperBase {
  constructor(
    @Inject(GARNISHMENT_MAIN_REDUX_OBJECT_TOKEN) garnishment,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.TransferActivity)) transferActivity,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.Note)) note,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentTemplate)) attachmentTemplate,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.Correspondence)) correspondence,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.CorrespondenceResend)) correspondenceResend,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AllowedUser)) allowedUser,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.Link)) link,

    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.CorrespondenceAttachment)) correspondenceAttachment,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.CorrespondenceAttachmentFileObject)) correspondenceAttachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.Participant)) participant,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.TemplateField)) templateField,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentMetadata)) attachmentMetadata,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentFileObject)) attachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentFolder)) attachmentFolder,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.SubjectSearch)) subjectSearch,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.SubjectSearchTempLink)) subjectSearchTempLink,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.CorrespondenceAttachmentTempLink)) correspondenceAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.MatterAttachmentTempLink)) matterAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.MatterAttachmentZipLink)) matterAttachmentZipLink,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentFolderMover)) attachmentFolderMover,
    @Inject(getMatterReduxObject(MatterType.Garnishment, MatterReduxObjectType.AttachmentMover)) attachmentMover
  ) {
    super(
      garnishment,
      transferActivity,
      note,
      attachmentTemplate,
      correspondence,
      correspondenceResend,
      allowedUser,
      link,
      correspondenceAttachment,
      correspondenceAttachmentFileObject,
      participant,
      templateField,
      attachmentMetadata,
      attachmentFileObject,
      attachmentFolder,
      subjectSearch,
      subjectSearchTempLink,
      correspondenceAttachmentTempLink,
      matterAttachmentTempLink,
      matterAttachmentZipLink,
      attachmentFolderMover,
      attachmentMover
    );
  }
}
