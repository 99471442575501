import { Injectable } from '@angular/core';
import { MixinConstructor } from '@safarilaw-webapp/shared/common-objects-models';

import { DropdownInvoiceTemplate } from '../../dropdown/dropdown-invoice-template/models/app/dropdown-invoice-template';
import { DropdownInvoiceType } from '../../dropdown/dropdown-invoice-type/models/app/dropdown-invoice-type';
import { InvoiceType } from '../../enums';

export const billingFeaturesMixin = <B extends MixinConstructor>(Base: B) =>
  class BillingFeaturesMixin extends Base {
    getSelectedInvoiceType(invoiceTypeList: DropdownInvoiceType[], invoiceTemplateDropdownList: DropdownInvoiceTemplate[], invoiceTypeId?: number): DropdownInvoiceType | DropdownInvoiceTemplate {
      if (invoiceTypeList && invoiceTemplateDropdownList && invoiceTypeId) {
        return this.getAllInvoiceTypes(invoiceTypeList, invoiceTemplateDropdownList).find(o => +o.id == invoiceTypeId);
      }
      return undefined;
    }

    isManualInvoice(invoiceType: DropdownInvoiceType | DropdownInvoiceTemplate): boolean {
      const requiresPaywall = (invoiceType as any)?.requiresPaywall;
      return this.invoiceHasCostRecovery(invoiceType) && !(requiresPaywall == null || requiresPaywall);
    }

    isPortalInvoice(invoiceType: DropdownInvoiceType | DropdownInvoiceTemplate): boolean {
      const requiresPaywall = (invoiceType as any)?.requiresPaywall;
      return this.invoiceHasCostRecovery(invoiceType) && requiresPaywall != null && requiresPaywall;
    }

    invoiceHasCostRecovery(invoiceType: DropdownInvoiceType | DropdownInvoiceTemplate): boolean {
      const requiresPaywall = (invoiceType as any)?.requiresPaywall;
      return (requiresPaywall !== undefined && requiresPaywall) || this.invoiceAllowsCustomAmount(invoiceType);
    }

    invoiceAllowsCustomAmount(invoiceType: DropdownInvoiceType | DropdownInvoiceTemplate): boolean {
      const allowsCustomInvoiceAmount = (invoiceType as any)?.allowsCustomInvoiceAmount;
      return allowsCustomInvoiceAmount !== undefined && allowsCustomInvoiceAmount;
    }

    invoiceHasCustomNarrative(invoiceType: DropdownInvoiceType | DropdownInvoiceTemplate): boolean {
      return invoiceType !== undefined && +invoiceType.id === +InvoiceType.AutoGenerated_Custom;
    }

    getCustomInvoiceTypes(invoiceTemplateDropdownList: DropdownInvoiceTemplate[]): DropdownInvoiceType[] {
      return [...invoiceTemplateDropdownList].map(
        (o: DropdownInvoiceTemplate) =>
          ({
            ...o,
            isActive: o.isActive,
            name: `${o.name}: $${o.totalAmount.toFixed(2)}`,
            requiresPaywall: true,
            allowsCustomInvoiceAmount: false,
            isAutoGenerated: true
          }) as DropdownInvoiceType
      );
    }

    getAllInvoiceTypes(invoiceTypeList: DropdownInvoiceType[], invoiceTemplateDropdownList: DropdownInvoiceTemplate[]): (DropdownInvoiceType | DropdownInvoiceTemplate)[] {
      return [...invoiceTypeList, ...this.getCustomInvoiceTypes(invoiceTemplateDropdownList)];
    }

    getInvoiceTypesSafariGenerated(invoiceTypeList: DropdownInvoiceType[], invoiceTemplateDropdownList: DropdownInvoiceTemplate[]): (DropdownInvoiceType | DropdownInvoiceTemplate)[] {
      const invoiceTypes = invoiceTypeList.filter(o => o.requiresPaywall && o.isAutoGenerated && +o.id !== InvoiceType.AutoGenerated_Template);
      return [...this.getCustomInvoiceTypes(invoiceTemplateDropdownList), ...invoiceTypes];
    }

    getInvoiceTypesUploaded(invoiceTypeList: DropdownInvoiceType[]): DropdownInvoiceType[] {
      return invoiceTypeList.filter(o => o.requiresPaywall && !o.isAutoGenerated);
    }

    getInvoiceTypesOther(invoiceTypeList: DropdownInvoiceType[]): DropdownInvoiceType[] {
      return invoiceTypeList.filter(o => !o.requiresPaywall && !o.isAutoGenerated);
    }
  };

@Injectable({
  providedIn: 'root'
})
export class DocumentRequestBillingUtility extends billingFeaturesMixin(class {}) {}
