import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { cloneDeep } from 'lodash-es';
import { CompanyEntityListModel, CompanyEntityUpdateModel, CreateCompanyEntityModel, State } from '../../_lpms-api-models.generated';
import { CompanyEntity } from '../models/app/company-entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyEntityAdapter extends ApiDataAdapter<CompanyEntity> {
  toUpdateModel(o: CompanyEntity): Required<CompanyEntityUpdateModel> {
    return {
      employerIdentificationNumber: o.employerIdentificationNumber,
      address: o.address
        ? {
            city: o.address.city,
            country: o.address.country,
            state: o.address.state,
            street1: o.address.street1,
            street2: o.address.street2,
            zipCode: o.address.zipCode
          }
        : null,
      billingAddressIsSameAsAddress: o.billingAddressIsSameAsAddress,
      billingAddress: o.billingAddress
        ? {
            city: o.billingAddress.city,
            country: o.billingAddress.country,
            state: o.billingAddress.state,
            street1: o.billingAddress.street1,
            street2: o.billingAddress.street2,
            zipCode: o.billingAddress.zipCode
          }
        : null,
      invoiceContactEmail: o.invoiceContactEmail,
      invoiceContactName: o.invoiceContactName,
      isActive: o.isActive,
      fullName: o.fullName,
      shortName: o.shortName,
      reviewerIsSupportContact: o.reviewerIsSupportContact,
      deliveryPortalContactEmail: o.deliveryPortalContactEmail,
      deliveryPortalContactName: o.deliveryPortalContactName,
      useCompanyLogo: o.useCompanyLogo,
      isPreferred: o.isPreferred,
      serviceAcceptedInAllStates: o.serviceAcceptedInAllStates,
      serviceAcceptedStates: cloneDeep(o.serviceAcceptedStates) as unknown as State[],
      allowServePortAgencyService: o.allowServePortAgencyService,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- API Naming
      matterSubtypeId_AgencyPortal_Levy: this.helpers.toApiId(o.agencyPortalLevyMatterSubtypeId)
    };
  }
  toCreateModel(o: CompanyEntity): Required<CreateCompanyEntityModel> {
    return {
      employerIdentificationNumber: o.employerIdentificationNumber,
      address: o.address
        ? {
            city: o.address.city,
            country: o.address.country,
            state: o.address.state,
            street1: o.address.street1,
            street2: o.address.street2,
            zipCode: o.address.zipCode
          }
        : null,
      billingAddressIsSameAsAddress: o.billingAddressIsSameAsAddress,
      billingAddress: o.billingAddress
        ? {
            city: o.billingAddress.city,
            country: o.billingAddress.country,
            state: o.billingAddress.state,
            street1: o.billingAddress.street1,
            street2: o.billingAddress.street2,
            zipCode: o.billingAddress.zipCode
          }
        : null,
      invoiceContactEmail: o.invoiceContactEmail,
      invoiceContactName: o.invoiceContactName,

      fullName: o.fullName,
      shortName: o.shortName,
      reviewerIsSupportContact: o.reviewerIsSupportContact,
      deliveryPortalContactEmail: o.deliveryPortalContactEmail,
      deliveryPortalContactName: o.deliveryPortalContactName,
      isActive: o.isActive,
      isPreferred: o.isPreferred,
      serviceAcceptedInAllStates: o.serviceAcceptedInAllStates,
      serviceAcceptedStates: cloneDeep(o.serviceAcceptedStates) as unknown as State[],
      allowServePortAgencyService: o.allowServePortAgencyService,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- API Naming
      matterSubtypeId_AgencyPortal_Levy: this.helpers.toApiId(o.agencyPortalLevyMatterSubtypeId)
    };
  }
  fromListModel(o: CompanyEntityListModel): Required<CompanyEntity> {
    return {
      id: this.helpers.fromApiId(o.id),
      employerIdentificationNumber: o.employerIdentificationNumber,
      address: o.address
        ? {
            city: o.address.city,
            country: o.address.country,
            state: o.address.state,
            street1: o.address.street1,
            street2: o.address.street2,
            zipCode: o.address.zipCode
          }
        : (o.address as any), // this will be either NULL or undef, whatever came back from the API
      billingAddressIsSameAsAddress: o.billingAddressIsSameAsAddress,
      billingAddress: o.billingAddress
        ? {
            city: o.billingAddress.city,
            country: o.billingAddress.country,
            state: o.billingAddress.state,
            street1: o.billingAddress.street1,
            street2: o.billingAddress.street2,
            zipCode: o.billingAddress.zipCode
          }
        : null,
      invoiceContactEmail: o.invoiceContactEmail,
      invoiceContactName: o.invoiceContactName,
      isActive: o.isActive,
      fullName: o.fullName,
      shortName: o.shortName,
      reviewerIsSupportContact: o.reviewerIsSupportContact,
      deliveryPortalContactEmail: o.deliveryPortalContactEmail,
      deliveryPortalContactName: o.deliveryPortalContactName,
      isPreferred: o.isPreferred,
      useCompanyLogo: undefined,
      serviceAcceptedInAllStates: o.serviceAcceptedInAllStates,
      serviceAcceptedStates: cloneDeep(o.serviceAcceptedStates),
      allowServePortAgencyService: o.allowServePortAgencyService,
      agencyPortalLevyMatterSubtypeId: this.helpers.fromApiId(o.matterSubtypeId_AgencyPortal_Levy)
    };
  }
  fromGetModel(o: CompanyEntityListModel): Required<CompanyEntity> {
    return {
      id: this.helpers.fromApiId(o.id),
      employerIdentificationNumber: o.employerIdentificationNumber,
      address: o.address
        ? {
            city: o.address.city,
            country: o.address.country,
            state: o.address.state,
            street1: o.address.street1,
            street2: o.address.street2,
            zipCode: o.address.zipCode
          }
        : null,
      billingAddressIsSameAsAddress: o.billingAddressIsSameAsAddress,
      billingAddress: o.billingAddress
        ? {
            city: o.billingAddress.city,
            country: o.billingAddress.country,
            state: o.billingAddress.state,
            street1: o.billingAddress.street1,
            street2: o.billingAddress.street2,
            zipCode: o.billingAddress.zipCode
          }
        : null,
      invoiceContactEmail: o.invoiceContactEmail,
      invoiceContactName: o.invoiceContactName,
      isActive: o.isActive,
      fullName: o.fullName,
      shortName: o.shortName,
      reviewerIsSupportContact: o.reviewerIsSupportContact,
      deliveryPortalContactEmail: o.deliveryPortalContactEmail,
      deliveryPortalContactName: o.deliveryPortalContactName,
      useCompanyLogo: o.useCompanyLogo,
      isPreferred: o.isPreferred,
      serviceAcceptedInAllStates: o.serviceAcceptedInAllStates,
      serviceAcceptedStates: cloneDeep(o.serviceAcceptedStates),
      allowServePortAgencyService: o.allowServePortAgencyService,
      agencyPortalLevyMatterSubtypeId: this.helpers.fromApiId(o.matterSubtypeId_AgencyPortal_Levy)
    };
  }
  fromGetNewModel(): Required<CompanyEntity> {
    const result: Required<CompanyEntity> = {
      employerIdentificationNumber: null,
      address: {
        street1: null,
        street2: null,
        state: null,
        city: null,
        zipCode: null,
        country: null
      },
      billingAddressIsSameAsAddress: true,
      billingAddress: {
        street1: null,
        street2: null,
        state: null,
        city: null,
        zipCode: null,
        country: null
      },
      id: ObjectHelper.getNewId(),
      invoiceContactEmail: null,
      invoiceContactName: null,
      isActive: true,
      fullName: null,
      shortName: null,
      reviewerIsSupportContact: true,
      deliveryPortalContactEmail: null,
      deliveryPortalContactName: null,
      useCompanyLogo: true,
      isPreferred: false,

      serviceAcceptedInAllStates: true,
      serviceAcceptedStates: null,
      allowServePortAgencyService: false,
      agencyPortalLevyMatterSubtypeId: null
    };
    return result;
  }
}
