import { Injectable } from '@angular/core';

import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { CompanySettingsModel, GarnishmentToDoPaymentInclusionState } from '../../_lpms-api-models.generated';
import { CompanySettings } from '../models/app/company-settings';

@Injectable({ providedIn: 'root' })
export class CompanySettingsAdapter extends ApiDataAdapter<CompanySettings> {
  toUpdateModel(formModel: CompanySettings): Required<CompanySettingsModel> {
    return {
      enableGarnishmentPaymentAccounts: formModel.enableGarnishmentPaymentAccounts,
      enableRfpa: formModel.enableRfpa,
      rfpaStates: formModel.rfpaStates,
      seizedFundsAccountNumber: formModel.seizedFundsAccountNumber,
      processingFeeAccountNumber: formModel.processingFeeAccountNumber,
      processingFeeDefaultAmount: formModel.processingFeeDefaultAmount,
      defaultGarnishmentPaymentAccount: undefined, // Deprecated; Will be removed,
      thresholdForDisablingAutoAnswer: formModel.thresholdForDisablingAutoAnswer,
      paymentStatusDaysBeforePaymentDate: formModel.paymentStatusShownInActiveUnresolved == GarnishmentToDoPaymentInclusionState.NumberOfDays ? formModel.paymentStatusDaysBeforePaymentDate : null,
      paymentStatusShownInActiveUnresolved: formModel.paymentStatusShownInActiveUnresolved,
      processingFeeMaximumAmount: formModel.processingFeeMaximumAmount
    };
  }

  fromGetModel(getModel: CompanySettingsModel): Required<CompanySettings> {
    /* ID property cannot be missing or this class cannot be used with CrudService.
     * ID property cannot be provided, because we merge this over the Company object, which has a real ID value that must be preserved */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Hack to work around missing ID property.
    return {
      enableGarnishmentPaymentAccounts: getModel.enableGarnishmentPaymentAccounts,
      enableRfpa: getModel.enableRfpa,
      rfpaStates: getModel.rfpaStates,
      id: SafariObject.NOID,
      seizedFundsAccountNumber: getModel.seizedFundsAccountNumber,
      processingFeeAccountNumber: getModel.processingFeeAccountNumber,
      processingFeeDefaultAmount: getModel.processingFeeDefaultAmount,
      thresholdForDisablingAutoAnswer: getModel.thresholdForDisablingAutoAnswer,
      paymentStatusDaysBeforePaymentDate: getModel.paymentStatusDaysBeforePaymentDate,
      paymentStatusShownInActiveUnresolved: getModel.paymentStatusShownInActiveUnresolved,
      processingFeeMaximumAmount: getModel.processingFeeMaximumAmount
    };
  }
}
