import { Injectable } from '@angular/core';

import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CreateReportDefinitionModel, ReportDefinitionModel, UpdateReportDefinitionModel } from '../../_lpms-api-models.generated';
import { Report } from '../models/app/report';

@Injectable({
  providedIn: 'root'
})
export class ReportAdapter extends ApiDataAdapter<Report> {
  constructor() {
    super();
  }
  toCreateModel(report: Report): Required<CreateReportDefinitionModel> {
    return {
      name: report.name,
      reportSchema: report.reportSchema == null ? null : JSON.stringify(report.reportSchema),
      type: report.type
    };
  }

  toUpdateModel(report: Report): Required<UpdateReportDefinitionModel> {
    return {
      name: report.name,
      reportSchema: report.reportSchema == null ? null : JSON.stringify(report.reportSchema)
    };
  }
  fromGetModel(report: ReportDefinitionModel): SafariRequired<Report> {
    return {
      id: this.helpers.fromApiId(report.id),
      name: report.name,
      reportSchema: JSON.parse(report.reportSchema),
      isCustom: true,
      type: report.type,
      dateCreated: null,
      dateModified: null
    };
  }
  fromListModel(report: ReportDefinitionModel): SafariRequired<Report> {
    return {
      id: this.helpers.fromApiId(report.id),
      name: report.name,
      reportSchema: undefined,
      isCustom: true,
      type: report.type,
      dateCreated: this.helpers.fromApiDate(report.dateCreated),
      dateModified: this.helpers.fromApiDate(report.dateUpdated)
    };
  }
  toDeleteModel(o: any) {
    return o;
  }
}
