import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterListModel } from '../../_lpms-api-models.generated';
import { Matter, MatterForList } from '../models/app/matter';
import { MatterAdapter } from './matter-adapter';

@Injectable({
  providedIn: 'root'
})
export class LookupAdapter extends ApiDataAdapter<Matter> {
  constructor(private _matterAdapter: MatterAdapter) {
    super();
  }

  fromGetModel(matter: MatterListModel): Required<MatterForList> {
    return this._matterAdapter.fromListModel(matter);
  }
}
