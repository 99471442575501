import { Injectable } from '@angular/core';

import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { AttachmentTemplateListModel, AttachmentTemplateModel, CreateAttachmentTemplateModel, UpdateAttachmentTemplateModel } from '../../_lpms-api-models.generated';
import { AttachmentTemplate } from '../models/app/attachment-template';

@Injectable({
  providedIn: 'root'
})
export class AttachmentTemplateAdapter extends ApiDataAdapter<AttachmentTemplate> {
  toCreateModel(o: AttachmentTemplate): SafariRequired<CreateAttachmentTemplateModel> {
    return {
      name: o.fileName,
      body: o.body,
      attachmentType: o.attachmentType,
      isDraft: o.isDraft
    };
  }
  toUpdateModel(o: AttachmentTemplate): SafariRequired<UpdateAttachmentTemplateModel> {
    return {
      name: o.fileName,
      body: o.body,
      isDraft: o.isDraft
    };
  }
  fromGetModel(o: AttachmentTemplateModel): SafariRequired<AttachmentTemplate> {
    return {
      id: this.helpers.fromApiId(o.id),
      fileName: o.name,
      body: o.body,
      attachmentType: o.attachmentType,
      createdBy: o.createdBy,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      updatedBy: o.updatedBy,
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      attachmentMetadataId: o.attachmentMetadataId?.toString(),
      isDraft: o.attachmentMetadataId == null // Manually mapping this until API is updated tow ork like Correspondence
    };
  }
  fromListModel(o: AttachmentTemplateListModel): SafariRequired<AttachmentTemplate> {
    return {
      id: this.helpers.fromApiId(o.id),
      fileName: o.name,
      body: undefined,
      attachmentType: o.attachmentType,
      createdBy: o.createdBy,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      updatedBy: o.updatedBy,
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      attachmentMetadataId: this.helpers.fromApiId(o.attachmentMetadataId),
      isDraft: o.attachmentMetadataId == null // Manually mapping this until API is updated tow ork like Correspondence
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
}
