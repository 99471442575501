import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, NoHiddenProps, SafariRequired } from '@safarilaw-webapp/shared/crud';
import {
  AnswerMetadataModel,
  DocumentRequestAttachmentModel,
  GarnishmentAttachmentModel,
  UpdateDocumentRequestAttachmentModel,
  UpdateGarnishmentAttachmentModel
} from '../../_lpms-api-models.generated';
import { AnswerMetadata, AttachmentMetadata } from '../models/app/attachment-metadata';
@Injectable({
  providedIn: 'root'
})
export class AttachmentAnswerMetadataAdapter extends ApiDataAdapter<AnswerMetadata> {
  toUpdateModel(o: AnswerMetadata): SafariRequired<AnswerMetadataModel> {
    if (o == null) {
      return null;
    }
    return {
      status: o.status,
      dateSent: this.helpers.toApiDate(o.dateSent),
      answerType: o.answerType,
      fundsHeld: o.fundsHeld,
      deliveryMethod: o.deliveryMethod,
      recipient: o.recipient,
      autoCommunicationStatus: o.autoCommunicationStatus,
      autoCommunicationErrorMessage: o.autoCommunicationErrorMessage
    };
  }
  fromGetModel(model: any, context?: ApiCallContext): Required<NoHiddenProps<AnswerMetadata>> {
    if (model == null) {
      return null;
    }
    return {
      status: model.status,
      dateSent: this.helpers.fromApiDate(model.dateSent),
      answerType: model.answerType,
      fundsHeld: model.fundsHeld,
      deliveryMethod: model.deliveryMethod,
      recipient: model.recipient,
      autoCommunicationStatus: model.autoCommunicationStatus,
      autoCommunicationErrorMessage: model.autoCommunicationErrorMessage
    };
  }
}
@Injectable({
  providedIn: 'root'
})
export class AttachmentMetadataAdapter extends ApiDataAdapter<AttachmentMetadata> {
  constructor(private _answerMetadataAdapter: AttachmentAnswerMetadataAdapter) {
    super();
  }
  toUpdateModel(o: AttachmentMetadata): SafariRequired<UpdateDocumentRequestAttachmentModel & UpdateGarnishmentAttachmentModel> {
    return {
      comments: o.comments,
      answerMetadata: this._answerMetadataAdapter.toUpdateModel(o.answerMetadata),
      name: o.name,
      performOcr: o.performOcr,
      type: o.type,
      attachmentFolderId: this.helpers.toApiId(o.attachmentFolderId)
    };
  }
  fromGetModel(o: DocumentRequestAttachmentModel & GarnishmentAttachmentModel): SafariRequired<AttachmentMetadata> {
    return {
      comments: o.comments,
      contentType: o.contentType,
      createdBy: o.createdBy,
      dateCreated: o.dateCreated,
      dateFirstAccessedByServingParty: o.dateFirstAccessedByServingParty,
      dateFirstDeliveredToPortal: o.dateFirstDeliveredToPortal,
      dateUpdated: o.dateUpdated,
      id: this.helpers.fromApiId(o.id),
      isAutogenerated: o.isAutogenerated,
      isOcrProcessing: o.isOcrProcessing,
      isExtractionProcessing: o.isExtractionProcessing,
      name: o.name,
      performOcr: undefined,
      sizeInBytes: o.sizeInBytes,
      type: o.type,
      updatedBy: o.updatedBy,
      attachmentFolderId: this.helpers.fromApiId(o.attachmentFolderId),
      answerMetadata: o.answerMetadata == null ? null : this._answerMetadataAdapter.fromGetModel(o.answerMetadata),
      extractionResult: o.extractionResult
    };
  }
  fromListModel(o: DocumentRequestAttachmentModel & GarnishmentAttachmentModel, context: ApiCallContext): SafariRequired<AttachmentMetadata> {
    return {
      comments: o.comments,
      contentType: o.contentType,
      createdBy: o.createdBy,
      dateCreated: o.dateCreated,
      dateFirstAccessedByServingParty: o.dateFirstAccessedByServingParty,
      dateFirstDeliveredToPortal: o.dateFirstDeliveredToPortal,
      dateUpdated: o.dateUpdated,
      id: this.helpers.fromApiId(o.id),
      isAutogenerated: o.isAutogenerated,
      isOcrProcessing: o.isOcrProcessing,
      isExtractionProcessing: o.isExtractionProcessing,
      name: o.name,
      performOcr: undefined,
      sizeInBytes: o.sizeInBytes,
      type: o.type,
      updatedBy: o.updatedBy,
      answerMetadata: this._answerMetadataAdapter.fromGetModel(o.answerMetadata),
      attachmentFolderId: this.helpers.fromApiId(o.attachmentFolderId, context.__parentId),
      extractionResult: o.extractionResult
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
}
