import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { MatterUserModel } from '../../_lpms-api-models.generated';
import { AllowedUser } from '../models/app/allowed-user';

@Injectable({
  providedIn: 'root'
})
export class AllowedUserAdapter extends ApiDataAdapter<AllowedUser> {
  fromListModel(o: MatterUserModel): SafariRequired<AllowedUser> {
    return {
      email: o.email,
      firstName: o.firstName,
      fullName: o.fullName,
      id: this.helpers.fromApiId(o.id),
      hasAccessToMatterSubtype: o.hasAccessToMatterSubtype,
      hasAdminAccess: o.isAdmin || o.isOwner,
      isParticipant: o.isParticipant,
      name: o.fullName,
      lastName: o.lastName,
      participationLevel: o.participationLevel
    };
  }
}
