import { Injectable } from '@angular/core';

import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, PatchObject, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DateTime } from 'luxon';
import { EnterOtherMatterDataModel, MatterType, OtherMatterModel, ReceiveOtherMatterModel } from '../../_lpms-api-models.generated';
import { OtherMatterStatus } from '../../enums';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { Matter } from '../../matter/models/app/matter';
import { MatterAdapterHelper } from '../../utils';
import { OtherMatter } from '../models/app/other-matter';

@Injectable({
  providedIn: 'root'
})
export class OtherMatterAdapter extends ApiDataAdapter<OtherMatter> {
  constructor(
    private __matterAdapterHelper: MatterAdapterHelper,
    private _matterAdapter: MatterAdapter
  ) {
    super();
  }
  fromGetNewModel(): SafariRequired<OtherMatter> {
    return {
      dateAnswerDue: null,

      attachmentTemplates: [],

      id: ObjectHelper.getNewId(),

      entityServedId: null,
      otherNameServed: null,
      safariId: '',
      servingPartyContact: null,
      servingPartyEmail: null,
      servingPartyAdditionalEmail: null,
      servingPartyPhone: null,
      servingPartyPhoneExtension: null,
      servingPartyFax: null,
      servingPartyMobile: null,
      caseNumber: null,
      dateFiled: null,
      dateReceived: DateTime.now().toISO(),
      defendants: null,
      documentTitle: null,
      servingPartyOrgType: 0,
      servingPartyOrgName: null,
      servingPartyOrgAddress: null,
      issuingAuthorityType: 0,
      issuingAuthorityName: null,
      issuingAuthorityAddress: null,
      forumContactAddress: null,
      methodOfService: null,
      plaintiffs: null,
      matterName: null,
      receivedBy: null,
      status: null,
      subjects: [],
      matterSubtypeId: null,
      reviewerId: null,
      type: MatterType.Other,

      forumTypeAndName: '',

      isStarred: false,
      otherInfo: '',

      noteCount: 0,
      linkCount: 0,
      alerts: [],

      hasEntityNameDiscrepancy: null,
      hasRestrictedAccess: null,
      internalReferenceId: null,

      stateReceived: null,
      financialNotes: null,
      issuingAuthority: null,
      noJurisdictionResolution: null,
      forumSameAsIssuingAuthority: false,
      notDomesticatedServiceResolution: null,

      participants: [],
      answerAttachments: []
    } as any;
  }

  toUpdatePartialModel(formModel: Partial<OtherMatter>, originalObject: Partial<OtherMatter>): PatchObject {
    return super.getPatchObject(formModel);
  }
  toCreateModel(garnishment: Matter): Required<ReceiveOtherMatterModel> {
    return {
      ...this._matterAdapter.toCreateModel(garnishment),
      dateAnswerDue: null
    };
  }

  toUpdateModel(otherMatter: OtherMatter): Omit<Required<EnterOtherMatterDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] } {
    const retVal: Omit<Required<EnterOtherMatterDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] } = {
      ...this._matterAdapter.toUpdateModel(otherMatter),
      status: otherMatter.status as OtherMatterStatus,
      closedReason: otherMatter.closedReason,
      dateAnswerDue: otherMatter.dateAnswerDue ? this.helpers.toApiDate(otherMatter.dateAnswerDue) : otherMatter.dateAnswerDue
    };
    return retVal;
  }

  fromGetModel(otherMatter: Omit<OtherMatterModel, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] }): Required<OtherMatter> {
    const retVal = {
      ...this._matterAdapter.fromCommonGetModel(otherMatter),

      type: MatterType.Other,
      dateAnswerDue: this.helpers.fromApiDate(otherMatter.dateAnswerDue),
      answerAttachments: []
    };
    return retVal;
  }
  fromHistory(model: any): any {
    return model;
  }
}
