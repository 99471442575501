import { Injectable } from '@angular/core';
import { ApiCallContext, ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CompanyPicklistModel, UpdateCompanyPicklistModel } from '../../_lpms-api-models.generated';
import { CompanyPicklist } from '../models/app/company-picklist';

@Injectable({
  providedIn: 'root'
})
export class CompanyPicklistAdapter extends ApiDataAdapter<CompanyPicklist> {
  toCreateModel(formModel: CompanyPicklist, context?: ApiCallContext): SafariRequired<any> {
    return {
      isActive: formModel.isActive,
      name: formModel.name,
      description: formModel.description
    };
  }
  toUpdateModel(o: CompanyPicklist, context?: ApiCallContext): SafariRequired<UpdateCompanyPicklistModel> {
    return {
      isActive: o.isActive,
      name: o.name,
      description: o.description
    };
  }
  fromGetModel(o: CompanyPicklistModel): SafariRequired<CompanyPicklist> {
    return {
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      name: o.name,
      description: o.description
    };
  }
  fromListModel(o: CompanyPicklistModel): SafariRequired<CompanyPicklist> {
    return {
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      name: o.name,
      description: o.description
    };
  }
  fromGetNewModel(): SafariRequired<CompanyPicklist> {
    return {
      id: ObjectHelper.getNewId(),
      isActive: true,
      name: null,
      description: null
    };
  }
}
