import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { MatterListModelPagedResponseModel } from '../../_lpms-api-models.generated';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { MatterSearch, MatterSearchRequest, MatterSearchResponse } from '../models/matter-search';

// TODO: Fix "any" and replace with the actual model
@Injectable({
  providedIn: 'root'
})
export class MatterSearchAdapter extends ApiDataAdapter<MatterSearch> {
  constructor(private _matterAdapter: MatterAdapter) {
    super();
  }

  // TODO: Looks like there is no API model at this time. Once there is we need to update
  // o: MatterSearch to o: MatterSearchModel or whatever it is
  toCreateModel(o: any): SafariRequired<MatterSearchRequest> {
    return {
      enableAdvancedSearch: o.enableAdvancedSearch,
      matterTypeFilter: o.matterTypeFilter,
      query: o.query,
      statusFilter: o.statusFilter,
      top: o.top,
      userProfileFilter: o.userProfileFilter,
      id: undefined
    };
  }

  fromGetModel(list: MatterListModelPagedResponseModel): SafariRequired<MatterSearchResponse> {
    const response: MatterSearchResponse = {
      id: null,
      items: []
    };
    list.items.forEach(matter => {
      response.items.push(this._matterAdapter.fromListModel(matter));
    });
    return response;
  }
}
