import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { IdResultModel, ResendCorrespondenceModel } from '../../_lpms-api-models.generated';
import { CorrespondenceResend } from '../models/app/correspondence-resend';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenceResendAdapter extends ApiDataAdapter<CorrespondenceResend> {
  toCreateModel(o: CorrespondenceResend): SafariRequired<ResendCorrespondenceModel> {
    return {
      isDraft: o.isDraft,
      recipientName: o.recipientName,
      replyToOption: o.replyToOption,
      to: o.to,
      type: o.type,
      cc: o.cc
    };
  }
  fromGetModel(o: IdResultModel, context?: ApiCallContext): SafariRequired<CorrespondenceResend> {
    return {
      id: this.helpers.fromApiId(o.id),
      isDraft: undefined,
      recipientName: undefined,
      replyToOption: undefined,
      to: undefined,
      type: undefined,
      cc: undefined
    };
  }
}
