import { Inject, Injectable } from '@angular/core';

import { ISafariObjectState, SafariReduxApiObject } from '@safarilaw-webapp/shared/redux';

import { CorrespondenceResend } from '../correspondence/models/app/correspondence-resend';

import { MatterType } from '../_lpms-api-models.generated';
import { LITIGATION_MAIN_REDUX_OBJECT_TOKEN, MatterReduxObjectType, getMatterReduxObject } from './injectable-object-tokens';

import { MatterMapperBase } from './matter-redux-object';

@Injectable({
  providedIn: 'root'
})
export class LitigationMapper extends MatterMapperBase {
  constructor(
    @Inject(LITIGATION_MAIN_REDUX_OBJECT_TOKEN) litigation,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.TransferActivity)) transferActivity,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.Note)) note,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentTemplate)) attachmentTemplate,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.Correspondence)) correspondence,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.CorrespondenceResend))
    correspondenceResend: SafariReduxApiObject<ISafariObjectState<CorrespondenceResend>, CorrespondenceResend>,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AllowedUser)) allowedUser,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.Link)) link,

    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.CorrespondenceAttachment)) correspondenceAttachment,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.CorrespondenceAttachmentFileObject)) correspondenceAttachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.Participant)) participant,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.TemplateField)) templateField,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentMetadata)) attachmentMetadata,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentFileObject)) attachmentFileObject,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentFolder)) attachmentFolder,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.SubjectSearch)) subjectSearch,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.SubjectSearchTempLink)) subjectSearchTempLink,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.CorrespondenceAttachmentTempLink)) correspondenceAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.MatterAttachmentTempLink)) matterAttachmentTempLink,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.MatterAttachmentZipLink)) matterAttachmentZipLink,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentFolderMover)) attachmentFolderMover,
    @Inject(getMatterReduxObject(MatterType.Litigation, MatterReduxObjectType.AttachmentMover)) attachmentMover
  ) {
    super(
      litigation,
      transferActivity,
      note,
      attachmentTemplate,
      correspondence,
      correspondenceResend,
      allowedUser,
      link,
      correspondenceAttachment,
      correspondenceAttachmentFileObject,
      participant,
      templateField,
      attachmentMetadata,
      attachmentFileObject,
      attachmentFolder,
      subjectSearch,
      subjectSearchTempLink,
      correspondenceAttachmentTempLink,
      matterAttachmentTempLink,
      matterAttachmentZipLink,
      attachmentFolderMover,
      attachmentMover
    );
  }
}
