import * as API from '../lib/_lpms-api-models.generated';

/**
 * This is a web only property that specfies type of document passed into matterdocument
 * We can't use things like instanceof (other than File) because objects are just simple
 * JSON payloads , not instances of anything
 * At some point we might want to think about adding some sort of class ID to every document
 * adapter and have that feed object types during GET expansion, but for now we'll be using this
 */
export enum MatterDocumentType {
  Folder = 0,
  File = 1,
  Correspondence = 2,
  AttachmentTemplate = 3,
  FileMetadata = 4
}

export enum MatterListingType {
  AllTypes = 0,
  AllAssignedToMe = 3,
  AllRecent = 4,
  AllUnassigned = 5,
  AllStarred = 12,
  Alerts = 13
}

export enum GarnishmentType {
  Creditor = 1,
  Federal = 2,
  State = 3
}

/**
 * Identifies the maximum value of InvoiceTypeEnum. Needed to distinguish InvoiceTypes from InvoiceTemplateIds
 * when mapping back from the form.
 */
export const INVOICE_TYPE_ENUM_MAX = 5;

export enum ReplyTo {
  CurrentUser = 1,
  Reviewer = 2,
  ResponseSupportContact = 3,
  None = 4
}

export enum TemplateSubmitType {
  Draft = 1,
  Finalize = 2
}

export enum NotificationPreferences {
  Reviewer = 4,
  Participant = 5
}

// These two are "special". They don't exist in the API as regular
// dropdowns so we have to use invidual dropdown load method
enum DropdownTypeExtended {
  /* NOTE: This endpoint requires a matter type to be specified but it will actually return all users, regardless of the matter type specified.
   * isActive property indicates whether the user can be a reviewer for the given matter type. At present, this is role specific and not
   * dependent upon the matter type, but it could be in the future. */
  Reviewers = 'reviewers',
  Participants = 'participants'
}

export const DropdownType = { ...API.PicklistType, ...DropdownTypeExtended };
export type DropdownType = typeof DropdownType;

export type DocumentRequestClosedReason = API.InformationRequestClosedReason;
export const DocumentRequestClosedReason = API.InformationRequestClosedReason;

export type GarnishmentClosedReason = API.GarnishmentClosedReason;
export const GarnishmentClosedReason = API.GarnishmentClosedReason;
export type MatterType = API.MatterType;
export const MatterType = API.MatterType;
export type ReportType = API.ReportType;
export const ReportType = API.ReportType;
export type LibraryTemplateCategory = API.LibraryTemplateCategory;
export const LibraryTemplateCategory = API.LibraryTemplateCategory;
export type ServingPartyPhoneType = API.ServingPartyPhoneType;
export const ServingPartyPhoneType = API.ServingPartyPhoneType;
export type CreationSourceType = API.CreationSourceType;
export const CreationSourceType = API.CreationSourceType;
export type TransferActivityType = API.TransferActivityType;
export const TransferActivityType = API.TransferActivityType;
export type PortalActivityType = API.PortalActivityType;
export const PortalActivityType = API.PortalActivityType;
export type ServingPartyOrgType = API.ServingPartyOrgType;
export const ServingPartyOrgType = API.ServingPartyOrgType;
export type RfpaCertificateStatus = API.RfpaCertificateStatus;
export const RfpaCertificateStatus = API.RfpaCertificateStatus;
export type CommunicationStatus = API.CommunicationStatus;
export const CommunicationStatus = API.CommunicationStatus;
export type CommunicationType = API.CommunicationType;
export const CommunicationType = API.CommunicationType;
export type TransferMethod = API.TransferMethod;
export const TransferMethod = API.TransferMethod;
export type AccessRight = API.AccessRight;
export const AccessRight = API.AccessRight;
export type DocumentRequestStatus = API.InformationRequestStatus;
export const DocumentRequestStatus = API.InformationRequestStatus;
export type GarnishmentStatus = API.GarnishmentStatus;
export const GarnishmentStatus = API.GarnishmentStatus;
export type OtherMatterStatus = API.OtherMatterStatus;
export const OtherMatterStatus = API.OtherMatterStatus;
export type OtherMatterClosedReason = API.OtherMatterClosedReason;
export const OtherMatterClosedReason = API.OtherMatterClosedReason;
export type LitigationStatus = API.LitigationStatus;
export const LitigationStatus = API.LitigationStatus;
export type LitigationClosedReason = API.LitigationClosedReason;
export const LitigationClosedReason = API.LitigationClosedReason;
export type MatterAttachmentType = API.MatterAttachmentType;
export const MatterAttachmentType = API.MatterAttachmentType;
export type InvoiceType = API.InvoiceType;
export const InvoiceType = API.InvoiceType;
export type ActionAlertType = API.ActionAlertType;
export const ActionAlertType = API.ActionAlertType;
export type InformationRequestPortalStatus = API.InformationRequestPortalStatus;
export const InformationRequestPortalStatus = API.InformationRequestPortalStatus;
export type IssuingAuthorityType = API.IssuingAuthorityType;
export const IssuingAuthorityType = API.IssuingAuthorityType;
export type PayeeIdentity = API.PayeeIdentity;
export const PayeeIdentity = API.PayeeIdentity;
export type TemplateFieldEnum = API.TemplateField;
export const TemplateFieldEnum = API.TemplateField;
export type AccountLocationType = API.AccountLocationType;
export const AccountLocationType = API.AccountLocationType;
export type ExemptionTrackingState = API.ExemptionTrackingState;
export const ExemptionTrackingState = API.ExemptionTrackingState;
export type AccountExemptionType = API.AccountExemptionType;
export const AccountExemptionType = API.AccountExemptionType;
export type AutoAssignReviewerType = API.AutoAssignReviewerType;
export const AutoAssignReviewerType = API.AutoAssignReviewerType;
export const PortalReferenceField = API.PortalReferenceField;
export type PortalReferenceField = API.PortalReferenceField;
export const MatterField = API.MatterField;
export type MatterField = API.MatterField;
export type DeliveryStatus = API.InformationRequestPortalStatus;
export const DeliveryStatus = API.InformationRequestPortalStatus;
export type DeliveryMethodType = API.DeliveryMethod;
export const DeliveryMethodType = API.DeliveryMethod;
export type BsaNotificationTiming = API.BsaNotificationTiming;
export const BsaNotificationTiming = API.BsaNotificationTiming;
export type BsaNotificationRequired = API.BsaNotificationRequired;
export const BsaNotificationRequired = API.BsaNotificationRequired;
export type ActiveFilter = API.ActiveFilter;
export const ActiveFilter = API.ActiveFilter;
export type MatterStatusFilter = API.MatterStatusFilter;
export const MatterStatusFilter = API.MatterStatusFilter;
export type SubjectType = API.SubjectType;
export const SubjectType = API.SubjectType;
export type CaseType = API.CaseType;
export const CaseType = API.CaseType;
export type SignalRMatterEvent = API.SignalRMatterEvent;
export const SignalRMatterEvent = API.SignalRMatterEvent;

export type SubjectSearchStatus = API.SubjectSearchStatus;
export const SubjectSearchStatus = API.SubjectSearchStatus;
export type SubjectSearchType = API.SubjectSearchType;
export const SubjectSearchType = API.SubjectSearchType;
export type GarnishmentRequestActionType = API.GarnishmentRequestActionType;
export const GarnishmentRequestActionType = API.GarnishmentRequestActionType;
export type GarnishmentHoldType = API.GarnishmentHoldType;
export const GarnishmentHoldType = API.GarnishmentHoldType;
export type GarnishmentLedgerTransactionTransferFilter = API.GarnishmentLedgerTransactionTransferFilter;
export const GarnishmentLedgerTransactionTransferFilter = API.GarnishmentLedgerTransactionTransferFilter;
export type GarnishmentAutoAnswerDeliveryMethod = API.GarnishmentAutoAnswerDeliveryMethod;
export const GarnishmentAutoAnswerDeliveryMethod = API.GarnishmentAutoAnswerDeliveryMethod;
export type GarnishmentToDoPaymentInclusionState = API.GarnishmentToDoPaymentInclusionState;
export const GarnishmentToDoPaymentInclusionState = API.GarnishmentToDoPaymentInclusionState;

// Doesn't seem that the API exposes this enum so we'll declare it here
export enum MatterStatusGroup {
  ToDo = 1,
  OnHold = 2,
  Delivery = 3,
  Portal = 4,
  Closed = 5
}
export type MatterStatus = DocumentRequestStatus | GarnishmentStatus | OtherMatterStatus | LitigationStatus;
export type MatterClosedReason = GarnishmentClosedReason | DocumentRequestClosedReason | OtherMatterClosedReason | LitigationClosedReason;

export type GarnishmentLedgerTransactionType = API.GarnishmentLedgerTransactionType;
export const GarnishmentLedgerTransactionType = API.GarnishmentLedgerTransactionType;

export type GarnishmentLedgerTransactionTransferStatus = API.GarnishmentLedgerTransactionTransferStatus;
export const GarnishmentLedgerTransactionTransferStatus = API.GarnishmentLedgerTransactionTransferStatus;

export type UserProfileFilter = API.UserProfileFilter;
export const UserProfileFilter = API.UserProfileFilter;

export type GarnishmentProcessingType = API.GarnishmentProcessingType;
export const GarnishmentProcessingType = API.GarnishmentProcessingType;

export type SecurityRole = API.SecurityRole;
export const SecurityRole = API.SecurityRole;

export type AnswerStatus = API.AnswerStatus;
export const AnswerStatus = API.AnswerStatus;

export type GarnishmentAnswerType = API.GarnishmentAnswerType;
export const GarnishmentAnswerType = API.GarnishmentAnswerType;

export type AnswerDeliveryMethod = API.AnswerDeliveryMethod;
export const AnswerDeliveryMethod = API.AnswerDeliveryMethod;

export type DocExtractionResult = API.DocExtractionResult;
export const DocExtractionResult = API.DocExtractionResult;

export type MethodOfService = API.MethodOfService;
export const MethodOfService = API.MethodOfService;
