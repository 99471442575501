import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { OrganizationModel } from '../../_lpms-api-models.generated';
import { Organization } from '../models/app/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationAdapter extends ApiDataAdapter<Organization> {
  fromListModel(o: Organization): SafariRequired<Organization> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name
    };
  }
  toCreateModel(o: Organization): SafariRequired<Omit<Organization, 'id'>> {
    return {
      name: o.name
    };
  }
  fromGetModel(o: OrganizationModel): SafariRequired<Organization> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name
    };
  }
  fromGetNewModel(): SafariRequired<Organization> {
    return {
      id: ObjectHelper.getNewId(),
      name: ''
    };
  }
  toDeleteModel(model: any): any {
    return null;
  }
}
