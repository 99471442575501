import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownOtherClosedReasonList } from '../models/api/dropdown-other-closed-reason-list';
import { DropdownOtherClosedReason } from '../models/app/dropdown-other-closed-reason';

@Injectable({
  providedIn: 'root'
})
export class DropdownOtherClosedReasonAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownOtherClosedReasonList): Required<DropdownOtherClosedReason> {
    return {
      ...super.fromListModel(model),

      isManualReason: model.isManualReason,
      requireSoftRequiredFields: model.requireSoftRequiredFields
    };
  }
}
