/* eslint-disable @typescript-eslint/no-shadow -- ignore annooying TS lint error*/
import { ISafariObjectState, ReduxDataAccessObject, SafariReduxApiObject } from '@safarilaw-webapp/shared/redux';
import { AttachmentFolder as AttachmentFolderModel } from '../attachment-folder/models/app/attachment-folder';
import { AttachmentMetadata as AttachmentMetadataModel } from '../attachment-metadata/models/app/attachment-metadata';
import { CorrespondenceResend as CorrespondenceResendModel } from '../correspondence/models/app/correspondence-resend';
import { Link } from '../link/models/app/link';
import { Note } from '../notes/models/app/note';
import { Participant } from '../participant/models/app/participant';
import { TransferActivity } from '../transfer-activity/models/app/transfer-activity';

import { AttachmentLink, FileObject } from '@safarilaw-webapp/shared/common-objects-models';
import { AllowedUser } from '../allowed-user/models/app/allowed-user';
import { AttachmentTemplate } from '../attachment-template/models/app/attachment-template';
import { CorrespondenceAttachment } from '../correspondence-attachment/models/app/correspondence-attachment';
import { Correspondence } from '../correspondence/models/app/correspondence';
import { SubjectSearch } from '../subject-search/models/app/subject-search';
import { TemplateField } from '../template-field/models/app/template-field';
import { AttachmentFolderMover } from '../attachment-folder/models/app/attachment-folder-mover';
import { AttachmentMover } from '../attachment-metadata/models/app/attachment-mover';
export class MatterMapperBase extends ReduxDataAccessObject {
  constructor(
    public Matter: SafariReduxApiObject<any, any>,
    public TransferActivity: SafariReduxApiObject<ISafariObjectState<TransferActivity>, TransferActivity>,

    public Note: SafariReduxApiObject<ISafariObjectState<Note>, Note>,
    public AttachmentTemplate: SafariReduxApiObject<ISafariObjectState<AttachmentTemplate>, AttachmentTemplate>,
    public Correspondence: SafariReduxApiObject<ISafariObjectState<Correspondence>, Correspondence>,
    public CorrespondenceResend: SafariReduxApiObject<ISafariObjectState<CorrespondenceResendModel>, CorrespondenceResendModel>,
    public AllowedUser: SafariReduxApiObject<ISafariObjectState<AllowedUser>, AllowedUser>,
    public Link: SafariReduxApiObject<ISafariObjectState<Link>, Link>,
    public CorrespondenceAttachment: SafariReduxApiObject<ISafariObjectState<CorrespondenceAttachment>, CorrespondenceAttachment>,
    public CorrespondenceAttachmentFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>,
    public Participant: SafariReduxApiObject<ISafariObjectState<Participant>, Participant>,
    public TemplateField: SafariReduxApiObject<ISafariObjectState<TemplateField>, TemplateField>,
    public AttachmentMetadata: SafariReduxApiObject<ISafariObjectState<AttachmentMetadataModel>, AttachmentMetadataModel>,
    public AttachmentFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>,
    public AttachmentFolder: SafariReduxApiObject<ISafariObjectState<AttachmentFolderModel>, AttachmentFolderModel>,
    public SubjectSearch: SafariReduxApiObject<ISafariObjectState<SubjectSearch>, SubjectSearch>,
    public SubjectSearchTempLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    public CorrespondenceAttachmentTempLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    public MatterAttachmentTempLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    public MatterAttachmentZipLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    public AttachmentFolderMover: SafariReduxApiObject<ISafariObjectState<AttachmentFolderMover>, AttachmentFolderMover>,
    public AttachmentMover: SafariReduxApiObject<ISafariObjectState<AttachmentMover>, AttachmentMover>
  ) {
    super();
  }
}
