import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { UpdatePortalModel } from '../../_lpms-api-models.generated';
import { UpdatePortal } from '../models/app/update-portal';

@Injectable({
  providedIn: 'root'
})
export class UpdatePortalAdapter extends ApiDataAdapter<UpdatePortal> {
  toUpdateModel(o: UpdatePortal): SafariRequired<UpdatePortalModel> {
    return {
      sendNewAttachments: o.sendNewAttachments,
      expirationDate: o.expirationDate
    };
  }
}
