import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { BulkMoveMatterAttachmentsModel } from '../../_lpms-api-models.generated';

import { SafariBulkObject } from '@safarilaw-webapp/shared/common-objects-models';
import { AttachmentMover } from '../models/app/attachment-mover';

@Injectable({
  providedIn: 'root'
})
export class AttachmentMoverAdapter extends ApiDataAdapter<AttachmentMover> {
  toUpdateMultipleModel(o: SafariBulkObject<AttachmentMover>): SafariRequired<BulkMoveMatterAttachmentsModel> {
    return {
      attachments: o.objects.map(obj => ({
        attachmentId: this.helpers.toApiId(obj.id),
        destinationFolderId: this.helpers.toApiId(obj.destinationFolderId),
        destinationType: obj.destinationType
      }))
    };
  }
}
