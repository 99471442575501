import { Injectable } from '@angular/core';
import * as redux from '../../../../redux/index';
import { ILitigationEditPageUiState } from '../../../../redux/state.interface';
import { defaultMatterEditPageUiState, LpmsMatterPageUiReduxObject } from '../../../matter-edit/redux/state';

export const defaultLitigationEditPageUiState: ILitigationEditPageUiState = {
  ...defaultMatterEditPageUiState
};

@Injectable({ providedIn: 'root' })
export class LpmsLitigationEditPageUiReduxObject extends LpmsMatterPageUiReduxObject<ILitigationEditPageUiState> {
  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.litigationEditPageState);
    super('LitigationEditPage', getPageState);
    super.addState({ ...defaultLitigationEditPageUiState, persistent: { ...defaultLitigationEditPageUiState.persistent } }).finalize();
  }
}
