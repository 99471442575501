import { Injectable } from '@angular/core';

import { SafariBulkObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { BulkMatterLinksModel, CreateLinkModel, MatterLinkModel, MatterLinkNotDuplicateModel } from '../../_lpms-api-models.generated';
import { Link } from '../models/app/link';

@Injectable({
  providedIn: 'root'
})
export class LinkAdapter extends ApiDataAdapter<Link> {
  fromGetModel(o: MatterLinkModel): SafariRequired<Link> {
    return {
      matterType: null,
      matterSubtypeId: o.matterSubtypeId,
      caseNumber: o.caseNumber,
      closedReason: o.closedReason,
      dateReceived: this.helpers.fromApiDate(o.dateReceived),
      currentDueDate: this.helpers.fromApiDate(o.currentDueDate),
      id: this.helpers.fromApiId(o.id),
      isAutogenerated: o.isAutogenerated,
      isPossibleDuplicate: o.isPossibleDuplicate,
      servingPartyContact: o.servingPartyContact,
      servingPartyOrgName: o.servingPartyOrgName,
      stateReceived: o.stateReceived,
      portalStatus: o.informationRequestPortalStatus,
      safariId: o.safariId,
      status: o.status,
      subjects: o.subjects ? o.subjects.map(x => ({ name: x.name })) : []
    };
  }
  toCreateModel(o: Link): Required<CreateLinkModel> {
    return {
      matterId: this.helpers.toApiId(o.id)
    };
  }
  toUpdateModel(o: Link): MatterLinkNotDuplicateModel {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- API name
      isPossibleDuplicate: false
    };
  }
  fromListModel(o: MatterLinkModel): SafariRequired<Link> {
    return {
      id: this.helpers.fromApiId(o.id),
      matterType: o.type,
      isAutogenerated: o.isAutogenerated,
      isPossibleDuplicate: o.isPossibleDuplicate,
      stateReceived: o.stateReceived,
      matterSubtypeId: o.matterSubtypeId,
      safariId: o.safariId,
      subjects: o.subjects ? o.subjects.map(x => ({ name: x.name })) : [],
      caseNumber: o.caseNumber,
      servingPartyContact: o.servingPartyContact,
      servingPartyOrgName: o.servingPartyOrgName,
      status: o.status,
      portalStatus: o.informationRequestPortalStatus,
      closedReason: o.closedReason,
      dateReceived: this.helpers.fromApiDate(o.dateReceived),
      currentDueDate: this.helpers.fromApiDate(o.currentDueDate)
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
  toDeleteMultipleModel(o: SafariBulkObject<Link>) {
    return {
      matterIds: o.objects.map(x => this.helpers.toApiId(x.id))
    };
  }
  toUpdateMultipleModel(o: SafariBulkObject<Link>): SafariRequired<BulkMatterLinksModel> {
    return {
      matterIds: o.objects.map(x => this.helpers.toApiId(x.id))
    };
  }
}
