/* eslint-disable no-restricted-syntax -- framework use*/
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, importProvidersFrom, inject, InjectionToken, provideAppInitializer, provideEnvironmentInitializer } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, Router, RouteReuseStrategy, withDisabledInitialNavigation, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
// I think NX linter is getting confused here. It thinks that the import shouldn't be allowed because we are already lazy-loading
// other /common-pages/xyz modules inside app.routing.ts, so it thinks it would be a waste to also compile the whole module staticaly in this import.
// However, these are standalone components, not one large common-pages module
// so the only real static import we have is the InitBaseComponent, not the entire common-pages module (we don't even have a common-pages module anymore)
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { GATEWAY_TIMEOUT_URLS } from '@safarilaw-webapp/common-pages/gateway-timeout';
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { SUPPORT_EMAIL } from '@safarilaw-webapp/common-pages/page-error';
import { FeatureCoManageDataAccessModule } from '@safarilaw-webapp/feature/co-manage/data-access';
import { LpmsAuthService, LpmsNavbarService, LpmsUserVerificationService } from '@safarilaw-webapp/feature/lpms/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { ILpmsUiState, LpmsUiReducerService } from '@safarilaw-webapp/feature/lpms/ui/redux';
import { AppCustomPreloader, NavbarPermissionsService, NavbarService, SharedAppBootstrapStoreAccessModule } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { AuthInterceptorService, AuthService, SharedAuthStoreAccessModule, UserVerificationService } from '@safarilaw-webapp/shared/auth/store-access';
import { ModalDialogService, SharedDialogStoreAccessModule } from '@safarilaw-webapp/shared/dialog/store-access';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { CHUNK_RETRY_COUNT_PARAM, ErrorDialogComponent, ErrorHandlerService, HttpErrorInterceptorService } from '@safarilaw-webapp/shared/error-handling';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { SafariDefaultReuseStategy, SharedRoutingUtilityStoreAccessModule } from '@safarilaw-webapp/shared/routing-utility/store-access';
import { SharedUiKitStoreAccessModule } from '@safarilaw-webapp/shared/ui-kit/store-access';
import { HotkeyModule } from 'angular2-hotkeys';
import { BlockUIModule } from 'ng-block-ui';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { environmentImports } from './app/app.module.env.imports';
import { routes } from './app/app.routing';
import { LpmsConfigurationService } from './app/configuration/lpms-configuration.service';
import { LpmsNavbarPermissionsService } from './app/navbar/services/lpms-navbar-permissions/lpms-navbar-permissions.service';
import { environment } from './environments/environment';
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ILpmsUiState>>('Lpms Reducers');

const initialAppConfigFactory =
  (appConfigService: AppConfigurationService, loggerService: LoggerService): (() => Observable<boolean>) =>
  (): Observable<boolean> =>
    appConfigService.overrideConfiguration().pipe(tap(() => loggerService.initializeElmahLogging()));

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
      withPreloading(AppCustomPreloader),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top'
      }),
      withDisabledInitialNavigation()
    ),
    importProvidersFrom(
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////
      // Angular imports for browser functionality
      ////////////////////////////////////////////////////////////
      BrowserModule,
      BrowserAnimationsModule,
      ////////////////////////////////////////////////////////////
      // NGX Modal module (base of our dialogs)
      // It can potentially pop up during bootstrapping
      // So we'll add it here
      ////////////////////////////////////////////////////////////
      ModalModule.forRoot(),
      ////////////////////////////////////////////////////////////
      // NGRX required root modules for store and effects
      ////////////////////////////////////////////////////////////
      StoreModule.forRoot(
        {},
        {
          runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true
          }
        }
      ),
      EffectsModule.forRoot([]),
      ////////////////////////////////////////////////////////////
      // Other 3RD party potentially needed during bootstrapping
      ////////////////////////////////////////////////////////////
      BlockUIModule.forRoot(),
      ToastrModule.forRoot(),
      HotkeyModule.forRoot(),
      ////////////////////////////////////////////////////////////
      // Service worker
      ////////////////////////////////////////////////////////////
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.nonprod, registrationStrategy: 'registerImmediately' }),

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////
      // Safari common modules needed for bootstrapping
      ////////////////////////////////////////////////////////////
      SharedDialogStoreAccessModule,
      SharedAuthStoreAccessModule,
      SharedRoutingUtilityStoreAccessModule,
      FeatureCoManageDataAccessModule,
      SharedAppBootstrapStoreAccessModule,
      SharedUiKitStoreAccessModule,
      ////////////////////////////////////////////////////////////
      // Safari project specific modules
      ////////////////////////////////////////////////////////////
      StoreModule.forFeature('lpms', REDUCER_TOKEN),

      [...environmentImports]
    ),
    provideHttpClient(withInterceptorsFromDi()),
    LpmsUiReducerService,
    { provide: REDUCER_TOKEN, deps: [LpmsUiReducerService], useFactory: (service: LpmsUiReducerService) => service.reducers },

    { provide: UserVerificationService, useClass: LpmsUserVerificationService },
    {
      provide: AuthService,
      useClass: LpmsAuthService
    },
    {
      provide: NavbarService,
      useClass: LpmsNavbarService
    },
    {
      provide: GATEWAY_TIMEOUT_URLS,
      useValue: 'safarilaw.com / *.safarilaw.com'
    },

    {
      provide: SUPPORT_EMAIL,
      useValue: 'support@safarisop.com'
    },

    provideAppInitializer(() => {
      const initializerFn = initialAppConfigFactory(inject(AppConfigurationService), inject(LoggerService));
      return initializerFn();
    }),
    provideEnvironmentInitializer(() => {
      inject(ModalDialogService).registerComponent(ErrorDialogComponent.ClassId, ErrorDialogComponent);

      const router = inject(Router);

      const url = new URL(window.location.href);

      // Don't blindly navigate to init. In normal circumstances the module should never load with
      // "init" as its startup path, but it could if this happened during chunkload error. So if that's
      // the case make sure you pass the chunk count
      if (url.pathname.toLowerCase() == '/init') {
        const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
        if (!isNaN(chunkCount)) {
          void router.navigate(['init'], {
            queryParams: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- used seldomly, so I m just skipping eslint here, instead of config
              __chcn: chunkCount
            }
          });
          return;
        }
      }
      // Otherwise this is a normal flow so go to init component but don't change the location
      void router.navigate(['/init'], { skipLocationChange: true });
    }),

    provideAppInitializer(() => {
      const initializerFn = initialAppConfigFactory(inject(AppConfigurationService), inject(LoggerService));
      return initializerFn();
    }),
    {
      provide: AppConfigurationService,
      useClass: LpmsConfigurationService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: NavbarPermissionsService,
      useClass: LpmsNavbarPermissionsService
    },
    {
      provide: RouteReuseStrategy,
      useClass: SafariDefaultReuseStategy
    }
  ]
}).catch(err => {
  throw err;
});
