import { Injectable } from '@angular/core';
import { MatterType } from '@safarilaw-webapp/feature/lpms/data-access';
import { PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../../redux/index';

export class IMatterListPageState extends PageUiState {
  toggleStar: { selected: { id: string; star: boolean; documentType: MatterType }[] };
  changeReviewer: { selected: { id: string; documentType: MatterType }[] };
}

export const defaultMatterListPageUiState = {
  toggleStar: null,
  changeReviewer: null,
  ...defaultPageUiState
};
@Injectable({ providedIn: 'root' })
export class LpmsMatterListPageUiReduxObject extends SafariReduxPageUiObject<IMatterListPageState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  requestToggleStar = super.addMessage('Request Toggle Star', state => state.toggleStar, { toggleStar: this.fromAction() });
  requestChangeReviewer = super.addMessage('Request Change Reviewer', state => state.changeReviewer, { changeReviewer: this.fromAction() });
  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.matterListPageState);
    super('Lpms', 'Matter List Page', getPageState, defaultMatterListPageUiState);
  }
}
