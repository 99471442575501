import { Injectable } from '@angular/core';
import { MatterAttachmentFilters, MatterFilters, MatterReviewerHistoryFilters } from '@safarilaw-webapp/feature/lpms/data-access';
import { PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../../redux/index';

export class IReportEditPageUiState extends PageUiState {
  runReportRequest: { runReport: boolean };
  reportFileLoadSuccess: { filePreviewSrc: string };
  reportFiltersRequest: {
    filters: MatterFilters & MatterReviewerHistoryFilters & MatterAttachmentFilters;
  };
}

export const defaultReportEditPageUiState: IReportEditPageUiState = {
  runReportRequest: undefined,
  reportFileLoadSuccess: undefined,
  reportFiltersRequest: null,
  ...defaultPageUiState
};

@Injectable({ providedIn: 'root' })
export class LpmsReportEditPageReduxUiObject extends SafariReduxPageUiObject<IReportEditPageUiState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  setReportFilters = super.addMessage('Set Report Filters', state => state.reportFiltersRequest, { reportFiltersRequest: this.fromAction() });
  setRunReport = super.addMessage('Set Run Report', state => state.runReportRequest, { runReportRequest: this.fromAction() });
  loadReportFileSuccess = super.addMessage('Load Report File Success', state => state.reportFileLoadSuccess, { reportFileLoadSuccess: this.fromAction() });
  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.reportEditPageState);
    super('Lpms', 'Report Edit Page', getPageState, defaultReportEditPageUiState);
  }
}
