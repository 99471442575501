import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownGarnishmentClosedReasonList } from '../models/api/dropdown-garnishment-closed-reason-list';
import { DropdownGarnishmentClosedReason } from '../models/app/dropdown-garnishment-closed-reason';

@Injectable({
  providedIn: 'root'
})
export class DropdownGarnishmentClosedReasonAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownGarnishmentClosedReasonList): Required<DropdownGarnishmentClosedReason> {
    return {
      ...super.fromListModel(model),

      isManualReason: model.isManualReason,
      requireSoftRequiredFields: model.requireSoftRequiredFields,
      requireSoftRequired6Fields: model.requireSoftRequired6Fields,
      isAvailableForX9: model.isAvailableForX9,
      answerType: model.answerType,
      answerTypeName: model.answerTypeName
    };
  }
}
