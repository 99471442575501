import { Injectable } from '@angular/core';

import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, PatchObject, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DateTime } from 'luxon';
import {
  Country,
  EnterGarnishmentDataModel,
  GarnishmentAccountModel,
  GarnishmentModel,
  GarnishmentPaymentModel,
  MatterType,
  PayeeIdentity,
  ReceiveGarnishmentModel,
  State
} from '../../_lpms-api-models.generated';
import { GarnishmentStatus } from '../../enums';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { Matter } from '../../matter/models/app/matter';
import { MatterAdapterHelper } from '../../utils';
import { Garnishment, GarnishmentAccount, GarnishmentPayment } from '../models/app/garnishment';

@Injectable({
  providedIn: 'root'
})
export class GarnishmentAdapter extends ApiDataAdapter<Garnishment> {
  constructor(
    private __matterAdapterHelper: MatterAdapterHelper,
    private _matterAdapter: MatterAdapter
  ) {
    super();
  }
  fromGetNewModel(): SafariRequired<Garnishment> {
    return {
      forumSameAsIssuingAuthority: false,
      dateAnswerDue: null,
      datePaymentDue: null,
      answerAttachments: [],

      attachmentTemplates: [],

      id: ObjectHelper.getNewId(),

      entityServedId: null,
      otherNameServed: null,
      safariId: '',
      servingPartyContact: null,
      servingPartyEmail: null,
      servingPartyAdditionalEmail: null,
      servingPartyPhone: null,
      servingPartyPhoneExtension: null,
      servingPartyFax: null,
      servingPartyMobile: null,
      caseNumber: null,
      dateFiled: null,
      dateReceived: DateTime.now().toISO(),

      defendants: null,

      documentTitle: null,
      servingPartyOrgType: 0,
      servingPartyOrgName: null,
      servingPartyOrgAddress: null,
      issuingAuthorityType: 0,
      issuingAuthorityName: null,
      issuingAuthorityAddress: null,
      forumContactAddress: null,
      methodOfService: null,
      plaintiffs: null,
      matterName: null,
      receivedBy: null,
      status: null,
      subjects: [],
      matterSubtypeId: null,
      reviewerId: null,
      type: MatterType.Garnishment,

      forumTypeAndName: '',

      releaseDate: null,
      isStarred: false,
      otherInfo: '',

      noteCount: 0,
      linkCount: 0,
      alerts: [],

      hasEntityNameDiscrepancy: null,
      hasRestrictedAccess: null,
      internalReferenceId: null,

      stateReceived: null,
      financialNotes: null,
      issuingAuthority: null,
      releaseAmount: 0,
      releaseNotes: '',
      judgmentAmount: 0,
      participants: [],
      accounts: [],
      payments: [],
      legalOrderFeeOrPrepaidAmount: 0,
      processingFee: 0,

      noJurisdictionResolution: null,
      multiStateJurisdictionResolution: null,
      notDomesticatedServiceResolution: null,
      restrictionStateIssuerResolution: null,

      payeeIdentity: null,
      requestActionType: null,
      holdType: null,
      minimalThresholdAmount: null,
      autoAnswerDeliveryMethod: null
    } as any;
  }
  toUpdatePartialModel(formModel: Partial<Garnishment>, originalObject: Partial<Garnishment>): PatchObject {
    return super.getPatchObject(formModel);
  }
  toCreateModel(garnishment: Matter): Required<ReceiveGarnishmentModel> {
    return {
      ...this._matterAdapter.toCreateModel(garnishment),

      dateAnswerDue: undefined,
      datePaymentDue: undefined
    };
  }

  toUpdateModel(garnishment: Garnishment): Omit<Required<EnterGarnishmentDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & {
    caseSubtypeIds: string[];
    notifiedTeamIds: string[];
  } {
    const retVal: Omit<Required<EnterGarnishmentDataModel>, 'caseSubtypeIds' | 'notifiedTeamIds'> & {
      caseSubtypeIds: string[];
      notifiedTeamIds: string[];
    } = {
      ...this._matterAdapter.toUpdateModel(garnishment),
      levyOrderAmount: garnishment.levyOrderAmount,
      status: garnishment.status as GarnishmentStatus,
      closedReason: garnishment.closedReason,
      dateAnswerDue: garnishment.dateAnswerDue ? this.helpers.toApiDate(garnishment.dateAnswerDue) : garnishment.dateAnswerDue,
      datePaymentDue: garnishment.datePaymentDue ? this.helpers.toApiDate(garnishment.datePaymentDue) : garnishment.datePaymentDue,
      releaseDate: garnishment.releaseDate ? this.helpers.toApiDate(garnishment.releaseDate) : garnishment.releaseDate,

      releaseAmount: garnishment.releaseAmount,
      releaseNotes: garnishment.releaseNotes,
      legalOrderFeeOrPrepaidAmount: garnishment.legalOrderFeeOrPrepaidAmount,
      isRemittanceRequested: garnishment.isRemittanceRequested,
      creditorName: garnishment.creditorName,
      processingFee: garnishment.processingFee,
      payeeIdentity: garnishment.payeeIdentity,
      requestActionType: garnishment.requestActionType,
      holdType: garnishment.holdType,
      minimalThresholdAmount: garnishment.minimalThresholdAmount,
      payeeName: garnishment.payeeIdentity == PayeeIdentity.OtherParty ? garnishment.payeeName : typeof garnishment.payeeIdentity == 'undefined' ? undefined : null,
      payeeAddress:
        garnishment.payeeIdentity == PayeeIdentity.OtherParty
          ? garnishment.payeeAddress
            ? {
                city: garnishment.payeeAddress.city,
                country: garnishment.payeeAddress.country as Country,
                state: garnishment.payeeAddress.state as State,
                street1: garnishment.payeeAddress.street1,
                street2: garnishment.payeeAddress.street2,
                zipCode: garnishment.payeeAddress.zipCode
              }
            : garnishment.payeeAddress
          : typeof garnishment.payeeIdentity == 'undefined'
            ? undefined
            : null,
      creditorAddress: garnishment.creditorAddress
        ? {
            city: garnishment.creditorAddress.city,
            country: garnishment.creditorAddress.country as Country,
            state: garnishment.creditorAddress.state as State,
            street1: garnishment.creditorAddress.street1,
            street2: garnishment.creditorAddress.street2,
            zipCode: garnishment.creditorAddress.zipCode
          }
        : garnishment.creditorAddress,
      accounts: garnishment.accounts ? this.parseAccountsForUpdate(garnishment.accounts) : garnishment.accounts,
      payments: garnishment.payments ? this.parsePaymentsForUpdate(garnishment.payments) : garnishment.payments,

      multiStateJurisdictionResolution: garnishment.multiStateJurisdictionResolution,
      restrictionStateIssuerResolution: garnishment.restrictionStateIssuerResolution,
      autoAnswerDeliveryMethod: garnishment.autoAnswerDeliveryMethod
    };

    return retVal;
  }

  fromGetModel(garnishment: Omit<GarnishmentModel, 'caseSubtypeIds' | 'notifiedTeamIds'> & { caseSubtypeIds: string[]; notifiedTeamIds: string[] }): Required<Garnishment> {
    const retVal: Required<Garnishment> = {
      ...this._matterAdapter.fromCommonGetModel(garnishment),
      levyOrderAmount: garnishment.levyOrderAmount,
      releaseDate: this.helpers.fromApiDate(garnishment.releaseDate),
      answerAttachments: [],
      payeeIdentity: garnishment.payeeIdentity,
      requestActionType: garnishment.requestActionType,
      holdType: garnishment.holdType,
      minimalThresholdAmount: garnishment.minimalThresholdAmount,
      type: MatterType.Garnishment,
      releaseAmount: garnishment.releaseAmount,
      releaseNotes: garnishment.releaseNotes,
      isRemittanceRequested: garnishment.isRemittanceRequested,
      processingType: garnishment.processingType,
      legalOrderFeeOrPrepaidAmount: garnishment.legalOrderFeeOrPrepaidAmount,
      processingFee: garnishment.processingFee,
      dateAnswerDue: this.helpers.fromApiDate(garnishment.dateAnswerDue),
      datePaymentDue: this.helpers.fromApiDate(garnishment.datePaymentDue),
      accounts: this.parseAccountsForGet(garnishment.accounts),
      payments: this.parsePaymentsForGet(garnishment.payments),
      creditorName: garnishment.creditorName,
      creditorAddress: garnishment.creditorAddress
        ? {
            city: garnishment.creditorAddress.city,
            country: garnishment.creditorAddress.country,
            state: garnishment.creditorAddress.state,
            street1: garnishment.creditorAddress.street1,
            street2: garnishment.creditorAddress.street2,
            zipCode: garnishment.creditorAddress.zipCode
          }
        : null,
      payeeName: garnishment.payeeName,
      payeeAddress: garnishment.payeeAddress
        ? {
            city: garnishment.payeeAddress.city,
            country: garnishment.payeeAddress.country,
            state: garnishment.payeeAddress.state,
            street1: garnishment.payeeAddress.street1,
            street2: garnishment.payeeAddress.street2,
            zipCode: garnishment.payeeAddress.zipCode
          }
        : null,
      multiStateJurisdictionResolution: garnishment.multiStateJurisdictionResolution,
      restrictionStateIssuerResolution: garnishment.restrictionStateIssuerResolution,
      autoAnswerDeliveryMethod: garnishment.autoAnswerDeliveryMethod
    };

    return retVal;
  }
  public parseAccountsForUpdate(accounts: GarnishmentAccount[]): SafariRequired<GarnishmentAccountModel>[] {
    const parsedAccounts: SafariRequired<GarnishmentAccountModel>[] = [];
    for (const account of accounts || []) {
      const parsedaccount: SafariRequired<GarnishmentAccountModel> = {
        accountNumber: account.number,
        accountType: account.type,
        seizedAmount: account.seizedAmount,
        notes: account.notes,
        locationState: account.locationState,
        exemptionType: account.exemptionType,
        exemptionStateLaw: account.exemptionStateLaw,
        exemptionNotes: account.exemptionNotes,
        enableAutomatedTransfer: account.enableAutomatedTransfer,
        accountBalance: account.accountBalance,
        exemptionAmount: account.exemptionAmount,
        offsetAmount: account.offsetAmount
      };

      parsedAccounts.push(parsedaccount);
    }

    return parsedAccounts;
  }
  public parseAccountsForGet(accounts: GarnishmentAccountModel[]): SafariRequired<GarnishmentAccount>[] {
    const parsedAccounts: SafariRequired<GarnishmentAccount>[] = [];
    for (const account of accounts || []) {
      const parsedaccount: SafariRequired<GarnishmentAccount> = {
        number: account.accountNumber,
        type: account.accountType,
        seizedAmount: account.seizedAmount,
        notes: account.notes,
        locationState: account.locationState,
        exemptionType: account.exemptionType,
        exemptionStateLaw: account.exemptionStateLaw,
        exemptionNotes: account.exemptionNotes,
        enableAutomatedTransfer: account.enableAutomatedTransfer,
        accountBalance: account.accountBalance,
        exemptionAmount: account.exemptionAmount,
        offsetAmount: account.offsetAmount,
        processingFee: undefined,
        releasedAmount: undefined
      };

      parsedAccounts.push(parsedaccount);
    }

    return parsedAccounts;
  }
  public parsePaymentsForUpdate(payments: GarnishmentPayment[]) {
    const parsedAccounts: GarnishmentPaymentModel[] = [];
    for (const payment of payments || []) {
      const parsedaccount: GarnishmentPaymentModel = {
        accountNumber: payment.accountNumber,
        checkNumber: payment.checkNumber,
        amount: payment.paymentAmount,
        date: this.helpers.toApiDate(payment.paymentDate)
      };

      parsedAccounts.push(parsedaccount);
    }

    return parsedAccounts;
  }
  public parsePaymentsForGet(payments: GarnishmentPaymentModel[]) {
    const parsedAccounts: GarnishmentPayment[] = [];
    for (const payment of payments || []) {
      const parsedaccount: GarnishmentPayment = {
        accountNumber: payment.accountNumber,
        checkNumber: payment.checkNumber,
        paymentAmount: payment.amount,
        paymentDate: this.helpers.fromApiDate(payment.date)
      };

      parsedAccounts.push(parsedaccount);
    }

    return parsedAccounts;
  }
  fromHistory(model: any): any {
    return model;
  }
}
