/* eslint-disable no-restricted-syntax -- framework calls , inject is OK */
import { HttpClient } from '@angular/common/http';
import { inject, InjectionToken, NgModule } from '@angular/core';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { ApiCallContext, IdName, SafariReduxTransferDialogObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SearchService } from '@safarilaw-webapp/shared/crud';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';

import '@safarilaw-webapp/shared/utils';

import { fileTransferReduxObject } from './redux/file-transfer';

import { getEffectTokens } from './redux/injectable-effect-tokens';

import { MatterType } from './_lpms-api-models.generated';
import {
  AttachmentLinkName,
  GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN,
  getMatterReduxObject,
  LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN,
  MatterReduxObjectType,
  setMatterReduxObjects,
  TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN
} from './redux/injectable-object-tokens';
import { setMatterServices } from './redux/injectable-service-tokens';
import { ReducerService } from './redux/reducer.service';
import { FEATURE_STATE, ILpmsState } from './redux/state.interface';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ILpmsState>>('Lpms DataAccess Reducers');
export const LPMS_TRANSFER_DIALOG_OBJECT = new InjectionToken<SafariReduxTransferDialogObject>('Lpms FileTransfer Object', { factory: () => fileTransferReduxObject, providedIn: 'root' });
export class SearchApiAdapter extends ApiDataAdapter<IdName> {
  fromListModel(model: any, context: ApiCallContext) {
    throw new Error('Not implemented.');
    return null;
  }
}
// This needs to be called right away so that the map is ready before token injections start
setMatterServices();
setMatterReduxObjects();
@NgModule({
  imports: [StoreModule.forFeature(FEATURE_STATE, REDUCER_TOKEN)],
  providers: [
    [
      { provide: REDUCER_TOKEN, deps: [ReducerService], useFactory: (service: ReducerService) => service.reducers },
      { provide: USER_PROVIDED_EFFECTS, useValue: getEffectTokens(), multi: true },

      {
        provide: SearchService,
        useFactory: (http: HttpClient, appConfig: AppConfigurationService) => new SearchService(http, appConfig.urls.apiRootLpms, new SearchApiAdapter()),
        deps: [HttpClient, AppConfigurationService]
      }
    ]
  ]
})
export class FeatureLpmsDataAccessModule {
  constructor() {
    const ledgerAttachmentLinkName: string = inject(TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN).toString();
    const ledgerAttachmentReportLinkName: string = inject(GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN).toString();
    const matterAttachmentLinkName: string = inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.MatterAttachmentTempLink)).toString().replace('DocumentRequest', '***');
    const matterAttachmentZipLinkName: string = inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.MatterAttachmentZipLink)).toString().replace('DocumentRequest', '***');
    const correspondenceAttachmentLinkName: string = inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.CorrespondenceAttachmentTempLink))
      .toString()
      .replace('DocumentRequest', '***');
    const subjectSearchAttachmentLinkName: string = inject(getMatterReduxObject(MatterType.DocumentRequest, MatterReduxObjectType.SubjectSearchTempLink)).toString().replace('DocumentRequest', '***');
    const libraryDocumentAttachmentLink = inject(LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN).toString();
    AttachmentLinkName['_correspondenceAttachment'] = correspondenceAttachmentLinkName;
    AttachmentLinkName['_subjectSearchAttachment'] = subjectSearchAttachmentLinkName;
    AttachmentLinkName['_matterAttachment'] = matterAttachmentLinkName;
    AttachmentLinkName['_matterAttachmentZip'] = matterAttachmentZipLinkName;
    AttachmentLinkName['_transferLedgerAttachment'] = ledgerAttachmentLinkName;
    AttachmentLinkName['_transferLedgerReport'] = ledgerAttachmentReportLinkName;
    AttachmentLinkName['_libraryDocumentAttachmentLink'] = libraryDocumentAttachmentLink;
  }
}
