import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterCount } from '../models/app/matter-count';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';

class MatterCountRead {
  alertCount: number;
  unassignedCount: number;
  assignedToMeCount: number;
}
@Injectable({
  providedIn: 'root'
})
export class MatterCountAdapter extends ApiDataAdapter<MatterCount> {
  fromGetModel(o: MatterCountRead): Required<MatterCount> {
    return {
      id: SafariObject.NOID,
      alertCount: o.alertCount,
      assignedToMeCount: o.assignedToMeCount,
      unassignedCount: o.unassignedCount
    };
  }
}
