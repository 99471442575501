import { Injectable } from '@angular/core';

import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterAdapterHelper } from '../../utils';

import { MatterAlertListModel } from '../../_lpms-api-models.generated';
import { DocumentAlert } from '../models/app/document-alert';
@Injectable({
  providedIn: 'root'
})
export class DocumentAlertAdapter extends ApiDataAdapter<DocumentAlert> {
  constructor(private _matterAdapterHelper: MatterAdapterHelper) {
    super();
  }
  fromListModel(o: MatterAlertListModel): Required<DocumentAlert> {
    return {
      alertDate: o.alertDate,
      alertType: o.alertType,
      currentDueDate: this.helpers.fromApiDate(o.currentDueDate),
      id: this.helpers.fromApiId(o.id),
      servingPartyContact: o.servingPartyContact,
      servingPartyEmail: o.servingPartyEmail,
      servingPartyFax: o.servingPartyFax,
      servingPartyOrgName: o.servingPartyOrgName,
      stateReceived: o.stateReceived,
      reviewerId: this.helpers.fromApiId(o.reviewerId),
      deliveryStatus: o.informationRequestPortalStatus,
      safariId: o.safariId,
      status: o.status,
      type: o.type,
      subjects: this._matterAdapterHelper.parseSubjectsForList(o.subjects),
      closedReason: o.closedReason
    };
  }
}
