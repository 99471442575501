import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';

export enum InvoiceTempFileDownloadMode {
  Preview,
  Download,
  ViewInBrowser
}

export class InvoicePreviewApiCallContext extends ApiCallContext {
  downloadMode: InvoiceTempFileDownloadMode;
}
