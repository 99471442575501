import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { InvoiceTemplatePreviewModel } from '../../_lpms-api-models.generated';
import { InvoiceTemplatePreview } from '../models/app/invoice-template-preview';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplatePreviewAdapter extends ApiDataAdapter<InvoiceTemplatePreview> {
  fromGetModel(o: InvoiceTemplatePreviewModel): Required<InvoiceTemplatePreview> {
    return {
      id: SafariObject.NOID,
      templateId: o.templateId ? o.templateId.toString() : o['documentRequestId'].toString(),
      invoiceHtml: o.invoiceHtml
    };
  }
}
