import { IdName, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { IDropdownState, IFileUploadState, ISafariObjectState } from '@safarilaw-webapp/shared/redux';
import { AttachmentFolder } from '../attachment-folder/models/app/attachment-folder';
import { AttachmentMetadata } from '../attachment-metadata/models/app/attachment-metadata';
import { AttachmentTemplate } from '../attachment-template/models/app/attachment-template';

import { createFeatureSelector } from '@ngrx/store';
import { CompanyEntity } from '../company-entity/models/app/company-entity';
import { CompanyPicklist } from '../company-picklist/models/app/company-picklist';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { CorrespondenceAttachment } from '../correspondence-attachment/models/app/correspondence-attachment';
import { Correspondence } from '../correspondence/models/app/correspondence';
import { DocumentAlert } from '../document-alert/models/app/document-alert';
import { DocumentRequest } from '../document-request/models/app/document-request';
import { DropdownMatterSubtypeUser } from '../dropdown/dropdown-matter-subtype-user/models/app/dropdown-matter-subtype-user';
import { DropdownMatterSubtype } from '../dropdown/dropdown-matter-subtype/models/app/dropdown-matter-subtype';
import { Garnishment } from '../garnishment/models/app/garnishment';
import { InvoiceTemplate } from '../invoice-template/models/app/invoice-template';
import { InvoiceTemplatePreview } from '../invoice-template/models/app/invoice-template-preview';
import { LibraryDocument } from '../library/models/app/library-document';
import { LibraryFolderList } from '../library/models/app/library-folder-list';
import { LibraryTemplate } from '../library/models/app/library-template';
import { Link } from '../link/models/app/link';
import { Litigation } from '../litigation/models/app/litigation';
import { MatterCount } from '../matter-count/models/app/matter-count';
import { MatterSearch } from '../matter-search/models/matter-search';
import { Matter } from '../matter/models/app/matter';
import { Note } from '../notes/models/app/note';
import { OrganizationSearch } from '../organization-search/models/organization-search';
import { Organization } from '../organization/models/app/organization';
import { OtherMatter } from '../other-matter/models/app/other-matter';
import { PortalActivity } from '../portal-activity/models/app/portal-activity';
import { Report } from '../reports/models/app/report';
import { ServingPartySearch } from '../serving-party-search/models/serving-party-search';
import { ServingParty } from '../serving-party/models/app/serving-party';
import { SubjectSearch } from '../subject-search/models/app/subject-search';
import { TransferActivity } from '../transfer-activity/models/app/transfer-activity';
import { Transfer } from '../transfer/models/app/transfer';

export const FEATURE_STATE = 'data-access-lpms';
export const getSharedFeatureState = createFeatureSelector<ILpmsState>(FEATURE_STATE);

export const PROJECT_NAME = 'Lpms';

export interface ILpmsStateDropdown extends IDropdownState {
  subpoenatypes: IdName[];
  subpoenastatuses: IdName[];
  states: IdName[];
  countries: IdName[];
  methodofservices: IdName[];
  garnishmenttypes: IdName[];
  servingpartyorgtypes: IdName[];
  issuingauthoritytypes: IdName[];
  deliverymethods: IdName[];
  garnishmentstatuses: IdName[];
  documentsassignedtome: IdName[];
  recentdocuments: IdName[];
  reviewers: IdName[];
}

export interface ILpmsState {
  litigationCombinedState: {
    litigationState: ISafariObjectState<Litigation>;
    litigationNoteState: ISafariObjectState<Note>;
    litigationAttachmentTemplateState: ISafariObjectState<AttachmentTemplate>;
    litigationCorrespondenceState: ISafariObjectState<Correspondence>;
    litigationAllowedUserState: ISafariObjectState<any>;
    litigationLinkState: ISafariObjectState<Link>;
    litigationTemplateFieldState: ISafariObjectState<any>;
    litigationTransferActivityState: ISafariObjectState<TransferActivity>;
    litigationCorrespondenceAttachmentState: ISafariObjectState<CorrespondenceAttachment>;
    litigationAttachmentMetadataState: ISafariObjectState<AttachmentMetadata>;
    litigationAttachmentFolderState: ISafariObjectState<AttachmentFolder>;
    litigationSubjectSearchState: ISafariObjectState<SubjectSearch>;
  };
  transferCombinedState: {
    transferState: ISafariObjectState<Transfer>;
  };
  garnishmentCombinedState: {
    garnishmentState: ISafariObjectState<Garnishment>;
    garnishmentNoteState: ISafariObjectState<Note>;
    garnishmentAttachmentTemplateState: ISafariObjectState<AttachmentTemplate>;
    garnishmentCorrespondenceState: ISafariObjectState<Correspondence>;
    garnishmentAllowedUserState: ISafariObjectState<any>;
    garnishmentLinkState: ISafariObjectState<Link>;
    garnishmentTemplateFieldState: ISafariObjectState<any>;
    garnishmentTransferActivityState: ISafariObjectState<TransferActivity>;
    garnishmentCorrespondenceAttachmentState: ISafariObjectState<CorrespondenceAttachment>;
    garnishmentAttachmentMetadataState: ISafariObjectState<AttachmentMetadata>;
    garnishmentAttachmentFolderState: ISafariObjectState<AttachmentFolder>;
    garnishmentSubjectSearchState: ISafariObjectState<SubjectSearch>;
  };
  otherMatterCombinedState: {
    otherMatterState: ISafariObjectState<OtherMatter>;
    otherMatterNoteState: ISafariObjectState<Note>;
    otherMatterAttachmentTemplateState: ISafariObjectState<AttachmentTemplate>;
    otherMatterCorrespondenceState: ISafariObjectState<Correspondence>;
    otherMatterAllowedUserState: ISafariObjectState<any>;
    otherMatterLinkState: ISafariObjectState<Link>;
    otherMatterTemplateFieldState: ISafariObjectState<any>;
    otherMatterTransferActivityState: ISafariObjectState<TransferActivity>;
    otherMatterCorrespondenceAttachmentState: ISafariObjectState<CorrespondenceAttachment>;
    otherMatterAttachmentMetadataState: ISafariObjectState<AttachmentMetadata>;
    otherMatterAttachmentFolderState: ISafariObjectState<AttachmentFolder>;
    otherMatterSubjectSearchState: ISafariObjectState<SubjectSearch>;
  };

  infoRequestCombinedState: {
    invoiceTemplatePreviewState: ISafariObjectState<InvoiceTemplatePreview>;
    extendPortalState: ISafariObjectState<SafariObject>;
    sendPortalReminderState: ISafariObjectState<SafariObject>;
    updatePortalState: ISafariObjectState<SafariObject>;
    resendPortalInviteState: ISafariObjectState<SafariObject>;

    infoRequestPortalActivityState: ISafariObjectState<PortalActivity>;
    infoRequestNoteState: ISafariObjectState<Note>;
    infoRequestAttachmentTemplateState: ISafariObjectState<AttachmentTemplate>;
    infoRequestCorrespondenceState: ISafariObjectState<Correspondence>;
    infoRequestAllowedUserState: ISafariObjectState<any>;
    infoRequestState: ISafariObjectState<DocumentRequest>;
    infoRequestLinkState: ISafariObjectState<Link>;
    infoRequestTemplateFieldState: ISafariObjectState<any>;
    infoRequestTransferActivityState: ISafariObjectState<TransferActivity>;
    infoRequestCorrespondenceAttachmentState: ISafariObjectState<CorrespondenceAttachment>;
    infoRequestAttachmentMetadataState: ISafariObjectState<AttachmentMetadata>;
    infoRequestAttachmentFolderState: ISafariObjectState<AttachmentFolder>;
    infoRequestSubjectSearchState: ISafariObjectState<SubjectSearch>;
  };

  invoiceTemplateCombinedState: {
    invoiceTemplateState: ISafariObjectState<InvoiceTemplate>;
    invoiceTemplatePreviewState: ISafariObjectState<InvoiceTemplatePreview>;
  };
  dropdownCombinedState: {
    dropdownState: ILpmsStateDropdown;
  };
  matterCombinedState: {
    matterState: ISafariObjectState<Matter>;
    matterCountsState: ISafariObjectState<MatterCount>;
    starredState: ISafariObjectState<Matter>;
    recentState: ISafariObjectState<Matter>;
    lookupState: ISafariObjectState<Matter>;
    changeSubtypeState: ISafariObjectState<SafariObject>;
    searchState: ISafariObjectState<MatterSearch>;
  };
  organizationCombinedState: {
    organizationState: ISafariObjectState<Organization>;
    searchState: ISafariObjectState<OrganizationSearch>;
  };
  servingPartyCombinedState: {
    servingPartyState: ISafariObjectState<ServingParty>;
    searchState: ISafariObjectState<ServingPartySearch>;
  };
  fileCombinedState: {
    fileState: IFileUploadState;
  };
  libraryCombinedState: {
    libraryDocumentState: ISafariObjectState<LibraryDocument>;
  };

  libraryTemplateCombinedState: {
    libraryTemplateState: ISafariObjectState<LibraryTemplate>;
  };
  libraryFolderListCombinedState: {
    libraryFolderListState: ISafariObjectState<LibraryFolderList>;
  };
  companyEntityCombinedState: {
    companyEntityState: ISafariObjectState<CompanyEntity>;
  };
  companyCombinedState: {
    companySettingsState: ISafariObjectState<CompanySettings>;
  };
  matterSubtypeCombinedState: {
    matterSubtypeState: ISafariObjectState<DropdownMatterSubtype>;
    matterSubtypeUserState: ISafariObjectState<DropdownMatterSubtypeUser>;
  };
  documentAlertCombinedState: {
    documentAlertState: ISafariObjectState<DocumentAlert>;
  };

  reportCombinedState: {
    reportState: ISafariObjectState<Report>;
  };
  companyPicklistCombinedState: {
    companyPicklistState: ISafariObjectState<CompanyPicklist>;
  };
}
