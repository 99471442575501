import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterSubtypeUserModel } from '../../../_lpms-api-models.generated';
import { DropdownMatterSubtypeUser } from '../models/app/dropdown-matter-subtype-user';

@Injectable({
  providedIn: 'root'
})
export class DropdownMatterSubtypeUserAdapter extends ApiDataAdapter<DropdownMatterSubtypeUser> {
  fromListModel(o: MatterSubtypeUserModel): Required<DropdownMatterSubtypeUser> {
    return {
      accessRights: o.accessRights,
      email: o.email,
      firstName: o.firstName,
      fullName: o.fullName,
      hasAccessToAllMatterSubtypes: o.hasAccessToAllMatterSubtypes,
      id: this.helpers.fromApiId(o.id),
      isOwner: o.isOwner,
      lastName: o.lastName,
      securityRole: o.securityRole
    };
  }
}
