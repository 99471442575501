import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { ListServingPartyModel, ServingPartyModel, ServingPartyUpdateModel } from '../../_lpms-api-models.generated';
import { ServingParty } from '../models/app/serving-party';

@Injectable({
  providedIn: 'root'
})
export class ServingPartyAdapter extends ApiDataAdapter<ServingParty> {
  fromListModel(o: ListServingPartyModel): SafariRequired<ServingParty> {
    return {
      isActive: o.isActive,
      contact: o.contact,
      email: o.email,
      id: this.helpers.fromApiId(o.id),
      orgAddress:
        o.orgAddress == null
          ? null
          : {
              city: o.orgAddress.city,
              country: o.orgAddress.country,
              state: o.orgAddress.state,
              street1: o.orgAddress.street1,
              street2: o.orgAddress.street2,
              zipCode: o.orgAddress.zipCode
            },
      orgName: o.orgName,
      orgType: o.orgType,
      phoneNumbers:
        o.phoneNumbers == null
          ? null
          : o.phoneNumbers.map(x => ({
              extension: x.extension,
              isPrimary: x.isPrimary,
              number: x.number,
              type: x.type
            })),
      updatedBy: o.updatedBy,
      dateUpdated: o.dateUpdated
    };
  }
  toCreateModel(o: ServingParty): SafariRequired<ServingPartyUpdateModel> {
    return {
      contact: o.contact,
      email: o.email,
      orgAddress: o.orgAddress,
      orgName: o.orgName,
      orgType: o.orgType,
      phoneNumbers: o.phoneNumbers,
      isActive: o.isActive
    };
  }
  toUpdateModel(o: ServingParty): SafariRequired<ServingPartyUpdateModel> {
    return {
      contact: o.contact,
      email: o.email,
      orgAddress: o.orgAddress,
      orgName: o.orgName,
      orgType: o.orgType,
      phoneNumbers: o.phoneNumbers,
      isActive: o.isActive
    };
  }
  fromGetModel(o: ServingPartyModel): SafariRequired<ServingParty> {
    return {
      contact: o.contact,
      email: o.email,
      id: this.helpers.fromApiId(o.id),
      isActive: o.isActive,
      orgAddress:
        o.orgAddress == null
          ? null
          : {
              city: o.orgAddress.city,
              country: o.orgAddress.country,
              state: o.orgAddress.state,
              street1: o.orgAddress.street1,
              street2: o.orgAddress.street2,
              zipCode: o.orgAddress.zipCode
            },
      orgName: o.orgName,
      orgType: o.orgType,
      phoneNumbers: o.phoneNumbers as any,
      updatedBy: o.updatedBy,
      dateUpdated: o.dateUpdated
    };
  }
  fromGetNewModel(): SafariRequired<ServingParty> {
    return {
      isActive: true,
      contact: '',
      email: '',
      id: ObjectHelper.getNewId(),
      orgAddress: null,
      orgName: '',
      orgType: null,
      phoneNumbers: [],
      updatedBy: null,
      dateUpdated: null
    };
  }
  toDeleteModel(model: any): any {
    return null;
  }
}
