import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CreateLedgerTransactionsModel, GarnishmentLedgerTransactionType, LedgerTransactionModel } from '../../_lpms-api-models.generated';
import { Transfer } from '../models/app/transfer';

@Injectable({
  providedIn: 'root'
})
export class TransferAdapter extends ApiDataAdapter<Transfer> {
  fromGetModel(o: LedgerTransactionModel): SafariRequired<Transfer> {
    return {
      amount: o.amount,
      createdBy: o.createdBy,
      date: o.date,
      fromAccountNumber: o.fromAccountNumber,
      id: this.helpers.fromApiId(o.id),
      toAccountNumber: o.toAccountNumber,
      transferStatus: o.transferStatus,
      type: o.type,
      enableAutomatedTransfer: undefined
    };
  }
  fromListModel(o: LedgerTransactionModel): SafariRequired<Transfer> {
    return {
      amount: o.amount,
      createdBy: o.createdBy,
      date: o.date,
      fromAccountNumber: o.fromAccountNumber,
      id: this.helpers.fromApiId(o.id),
      toAccountNumber: o.toAccountNumber,
      transferStatus: o.transferStatus,
      type: o.type,
      enableAutomatedTransfer: undefined
    };
  }

  /**
   * Hack warning - our current framework does not have support for bulkd post/create.
   * To get around it for now we'll just do some "any" hacks.
   * Long term we'll probably need to create a separate "CreateOrUpdateMultipleObjects"
   * set of effects, actions, and adapter methods.
   * (Trying to reuse the existing one and declaring type as either a single or array woudl cause
   * rework on 99% of the objects in the system to cast to a single item in their adapter)
   */
  toCreateModel(transferArray: Transfer): SafariRequired<CreateLedgerTransactionsModel> {
    return {
      transactions: (transferArray as any).transfers.map(x => ({ ...x, accountNumber: x.type == GarnishmentLedgerTransactionType.ReleaseFunds ? x.toAccountNumber : x.fromAccountNumber }))
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
}
