import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, NoHiddenProps } from '@safarilaw-webapp/shared/crud';
import { RichTextEditorGlobalService } from '@safarilaw-webapp/shared/rich-text-editor-global';
import { LibraryTemplateCategory, LibraryTemplateModel, LibraryTemplateUpdateModel, ListLibraryTemplateModel, ReplyToOption } from '../../_lpms-api-models.generated';
import { LibraryTemplate } from '../models/app/library-template';
import { LibraryTemplateAdapterContext } from './library-template-context';

@Injectable({
  providedIn: 'root'
})
export class LibraryTemplateAdapter extends ApiDataAdapter<LibraryTemplate> {
  constructor(private _richTextEditorGlobal: RichTextEditorGlobalService) {
    super();
  }
  toDeleteModel(model: any): any {
    return null;
  }
  fromGetNewModel(context?: ApiCallContext): Required<NoHiddenProps<LibraryTemplate>> {
    return {
      body: '',
      dateUpdated: null,
      editableAfterGeneration: true,
      id: '0',
      name: '',
      fileName: '',
      subject: '',
      updatedBy: null,
      category: null,
      communicationType: null,
      recipientName: null,
      to: null,
      recipientIsServingParty: null,
      replyToOption: null,
      matterTypes: [],
      matterSubtypeIds: [],
      matterAttachmentTypes: [],
      cc: []
    };
  }
  private _getCorrespondenceSection(o: LibraryTemplate) {
    return o.category == LibraryTemplateCategory.Correspondence
      ? {
          communicationType: o.communicationType,
          recipientName: o.recipientName,
          to: o.to,
          recipientIsServingParty: o.recipientIsServingParty,
          replyToOption: o.replyToOption as ReplyToOption,
          subject: o.subject
        }
      : {};
  }
  toCreateModel(o: LibraryTemplate): Required<LibraryTemplateUpdateModel> {
    const correspondenceSection = this._getCorrespondenceSection(o);
    return {
      ...{
        body: o.body,
        editableAfterGeneration: o.editableAfterGeneration,
        name: o.name,
        fileName: o.fileName,
        category: o.category,
        matterTypes: o.matterTypes,
        matterSubtypeIds: o.matterSubtypeIds as unknown as number[], // HACK: type mismatch in generated file - These are strings not numbers
        matterAttachmentTypes: o.matterAttachmentTypes,
        communicationType: undefined,
        recipientIsServingParty: undefined,
        recipientName: undefined,
        replyToOption: undefined,
        to: undefined,
        subject: undefined,
        cc: o.cc
      },
      ...correspondenceSection
    };
  }

  toUpdateModel(o: LibraryTemplate): Required<LibraryTemplateUpdateModel> {
    const correspondenceSection = this._getCorrespondenceSection(o);
    return {
      ...{
        body: o.body,
        editableAfterGeneration: o.editableAfterGeneration,
        name: o.name,
        fileName: o.fileName,
        category: o.category,
        matterTypes: o.matterTypes,
        matterSubtypeIds: o.matterSubtypeIds as unknown as number[], // HACK: type mismatch in generated file - These are strings not numbers
        matterAttachmentTypes: o.matterAttachmentTypes,
        communicationType: undefined,
        recipientName: undefined,
        to: undefined,
        recipientIsServingParty: undefined,
        replyToOption: undefined,
        subject: undefined,
        cc: o.cc
      },
      ...correspondenceSection
    };
  }

  fromGetModel(o: LibraryTemplateModel, context: LibraryTemplateAdapterContext): Required<LibraryTemplate> {
    return {
      body: this._richTextEditorGlobal.reparseHtmlFromApi(o.body),
      editableAfterGeneration: o.editableAfterGeneration,
      id: o.id == null && context != null ? this.helpers.fromApiId(context.id) : this.helpers.fromApiId(o.id),
      name: o.name,
      fileName: o.fileName,
      subject: o.subject,
      dateUpdated: null,
      updatedBy: null,
      category: o.category,
      communicationType: o.communicationType,
      recipientName: o.recipientName,
      to: o.to,
      recipientIsServingParty: o.recipientIsServingParty,
      replyToOption: o.replyToOption,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as string[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes,
      cc: o.cc
    };
  }
  fromListModel(o: ListLibraryTemplateModel): Required<LibraryTemplate> {
    return {
      body: null,
      editableAfterGeneration: undefined,
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      fileName: undefined,
      subject: undefined,
      dateUpdated: o.dateUpdated,
      updatedBy: o.updatedBy,
      category: o.category,
      communicationType: undefined,
      recipientIsServingParty: undefined,
      recipientName: undefined,
      replyToOption: undefined,
      to: undefined,
      matterTypes: o.matterTypes,
      matterSubtypeIds: o.matterSubtypeIds as unknown as string[], // HACK: type mismatch in generated file - These are strings not numbers
      matterAttachmentTypes: o.matterAttachmentTypes,
      cc: undefined
    };
  }
}
