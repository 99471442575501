import { ApplicationRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Observable, combineLatest, map, take } from 'rxjs';
import { SafariUiAccordionGroupReduxObject, SafariUiAccordionReduxObject } from '../../../../state/actions/layout-actions';
import { Accordion } from '../safari-accordion/safari-accordion.component';

import { AsyncPipe } from '@angular/common';
import { SharedObservablesDirective } from '../../../../shared/directives/shared-observables/shared-observables.directive';

@Component({
  selector: 'sl-ui-kit-safari-accordion-group',
  templateUrl: './safari-accordion-group.component.html',
  styleUrls: ['./safari-accordion-group.component.scss'],
  imports: [SharedObservablesDirective, AsyncPipe]
})
export class SafariAccordionGroupComponent implements OnInit, OnDestroy {
  hasAnyExpandedAccordions$: Observable<boolean>;
  private _openTwistiesKey: Hotkey | Hotkey[];
  private _closeTwistiesKey: Hotkey | Hotkey[];
  @HostBinding('attr.id')
  @Input()
  id: SafariObjectId;
  @Input()
  expandHotkey = 'ctrl+shift+]';
  @Input()
  collapseHotkey = 'ctrl+shift+[';

  private _twisties: Accordion[] = [];

  constructor(
    private _hotkeyService: HotkeysService,
    private _appRef: ApplicationRef,
    private _reduxWrapperService: ReduxWrapperService,
    private _accordionReduxObject: SafariUiAccordionReduxObject,
    private _accordionControllerReduxObject: SafariUiAccordionGroupReduxObject
  ) {}

  public get twisties(): Accordion[] {
    return this._twisties;
  }

  @Input()
  public set twisties(value: Accordion[]) {
    // TODO: We'll probably need to figure out how to make this foolproof so people can't assign accordions that
    // will never exist. The problem we can't simlpy check NULL state as most accordion states won't even be set yet
    // (controller will come first) and what the controller does when it sees NULL state for the accordion its passed to it,
    // is that it precreates a default state for it , so that when the accordion is rendered for the first time it already
    // has the state the controller wants it to have. So not 100% sure how we'd do that, maybe we can have some timer or something
    // that we start here and if the accoridon doesn't show up in that time we throw an error or something. But then we also
    // need to figure out how to know if the accordion "showed up". Maybe an extra property in the state like "initialized" that
    // the accordion sets once it goes through its ngOnInit ? (similar to what datatable does)
    this._twisties = value;
    this.twisties.forEach(twisty => {
      this._reduxWrapperService
        .getGenericSelector(this._accordionReduxObject.default.selectors.getState(twisty.id))
        .pipe(take(1))
        .subscribe(state => {
          // NOTE: We only want to set this if state = null. If state != null then it means it's coming back
          // from either
          // a) Save/refresh (we preserve accordion states during save so we want to make sure that is reflected)
          // b) Reassigning twisties input property. That usually happens with dynamic accordions like subjects
          //    In this case we don't want to blast away those that already have their state set via user interaction
          //    but the new ones will be added with default state
          if (state == null) {
            this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.setActive({ payload: { id: twisty.id, active: twisty.isActive } }));
            if (twisty.isExpanded) {
              this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpanded({ payload: { id: twisty.id } }));
            } else {
              this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapsed({ payload: { id: twisty.id } }));
            }
          }
        });
    });

    const allAccordionSelectors: Observable<Accordion>[] = [];

    this.twisties.forEach(twisty => {
      allAccordionSelectors.push(this._reduxWrapperService.getGenericSelector(this._accordionReduxObject.default.selectors.getState(twisty.id)));
    });

    this.hasAnyExpandedAccordions$ = combineLatest(allAccordionSelectors).pipe(
      map(accordionStates => {
        const expandedAccordions = accordionStates.find(o => o && o.isActive && o.isExpanded);

        const hasAnyExpandedAccordions = expandedAccordions != null;
        return hasAnyExpandedAccordions;
      })
    );
  }

  getToggleClass(hasAnyExpandedAccordions) {
    if (hasAnyExpandedAccordions == null) {
      return 'd-none';
    }
    if (hasAnyExpandedAccordions == false) {
      return 's_ico-expand_fill text-info float-right pr-0';
    } else {
      return 's_ico-collapse_fill text-info float-right pr-0';
    }
  }
  ngOnDestroy(): void {
    if (this._openTwistiesKey != null) {
      this._hotkeyService.remove(this._openTwistiesKey);
    }
    if (this._closeTwistiesKey != null) {
      this._hotkeyService.remove(this._closeTwistiesKey);
    }
  }
  ngOnInit() {
    if (this.expandHotkey) {
      this._openTwistiesKey = this._hotkeyService.add(
        new Hotkey(this.expandHotkey, (event: KeyboardEvent): boolean => {
          this.expandAllTwisties();
          return false; // Prevent bubbling
        })
      );
    }
    if (this.collapseHotkey) {
      this._closeTwistiesKey = this._hotkeyService.add(
        new Hotkey(this.collapseHotkey, (event: KeyboardEvent): boolean => {
          this.closeAllTwisties();
          return false; // Prevent bubbling
        })
      );
    }
  }
  toggleAllTwisties(hasAnyExpandedAccordions) {
    // This logic suffers from the same problem as subject expand/collapse. Basically
    // if a user manually collapses or expands all twisties  this code
    // will have no idea and the first click will do nothing.
    // A better solution would be to monitor the state of twisties and change this icon based on that
    // Then the icon would also auto-magically change even when the user manually expands/collapses all twisties.
    // But the main problem we have with that right now is that we have ngif-ed twisties and the state has no idea about
    // that so we'd have to figure some way of telling the state that a particular accordion was ng-ifed out exsitance
    // Due to changing from delivery to other status for example, or due to compliance warning change, etc
    // Something to look into in the future...
    if (hasAnyExpandedAccordions) {
      this.closeAllTwisties();
    } else {
      this.expandAllTwisties();
    }
  }
  closeAllTwisties() {
    this.twisties.forEach(twisty => {
      this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapse({ payload: { id: twisty.id } }));
    });
    this._reduxWrapperService.dispatchGenericAction(
      this._accordionControllerReduxObject.default.actions.accordionControllerCollapsed({
        payload: {
          id: this.id
        }
      })
    );
  }

  expandAllTwisties() {
    this.twisties.forEach(twisty => {
      this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpand({ payload: { id: twisty.id } }));
    });
    this._reduxWrapperService.dispatchGenericAction(
      this._accordionControllerReduxObject.default.actions.accordionControllerExpanded({
        payload: {
          id: this.id
        }
      })
    );
  }
}
