import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { ListServingPartyModelPagedResponse } from '../../_lpms-api-models.generated';
import { ServingPartyAdapter } from '../../serving-party/adapters/serving-party-adapter';
import { ServingPartySearch, ServingPartySearchRequest, ServingPartySearchResponse } from '../models/serving-party-search';

@Injectable({
  providedIn: 'root'
})
export class ServingPartySearchAdapter extends ApiDataAdapter<ServingPartySearch> {
  constructor(private _servingPartyAdapter: ServingPartyAdapter) {
    super();
  }

  toCreateModel(o: any): SafariRequired<ServingPartySearchRequest> {
    return {
      query: o.query,
      top: o.top,
      skip: o.skip,
      orderBy: o.orderBy,
      activeFilter: o.activeFilter,
      id: null
    };
  }

  fromGetModel(list: ListServingPartyModelPagedResponse): SafariRequired<ServingPartySearchResponse> {
    const response: ServingPartySearchResponse = {
      id: null,
      items: []
    };
    list.items.forEach(servingParty => {
      response.items.push(this._servingPartyAdapter.fromListModel(servingParty));
    });
    return response;
  }
}
