/* eslint-disable @typescript-eslint/no-shadow -- TS can be pretty annoying when 
  figuring out "shadowing". In this case our property names are same as imported objects
  so it gets confused, even though there is no actual shadowing happening. Disabling for the whole
  file  */
import { Inject, Injectable } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { AttachmentLink, FileObject, SafariObject, SafariReduxTransferDialogObject } from '@safarilaw-webapp/shared/common-objects-models';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { DROPDOWN_REDUCER, ISafariObjectState, REDUCER, ReduxDataAccessObject, SafariReduxApiObject } from '@safarilaw-webapp/shared/redux';
import { CompanyEntity } from '../company-entity/models/app/company-entity';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { DocumentAlert } from '../document-alert/models/app/document-alert';
import { DropdownMatterSubtypeUser } from '../dropdown/dropdown-matter-subtype-user/models/app/dropdown-matter-subtype-user';
import { DropdownMatterSubtype } from '../dropdown/dropdown-matter-subtype/models/app/dropdown-matter-subtype';
import { LPMS_TRANSFER_DIALOG_OBJECT } from '../feature-lpms-data-access.module';
import { InvoiceTemplate } from '../invoice-template/models/app/invoice-template';
import { InvoiceTemplatePreview } from '../invoice-template/models/app/invoice-template-preview';
import { LibraryDocument } from '../library/models/app/library-document';
import { LibraryFolderList } from '../library/models/app/library-folder-list';
import { LibraryTemplate } from '../library/models/app/library-template';
import { MatterCount } from '../matter-count/models/app/matter-count';
import { MatterSearch } from '../matter-search/models/matter-search';
import { Matter } from '../matter/models/app/matter';
import { OrganizationSearch } from '../organization-search/models/organization-search';
import { Organization } from '../organization/models/app/organization';
import { Report } from '../reports/models/app/report';
import { ServingPartySearch } from '../serving-party-search/models/serving-party-search';
import { ServingParty } from '../serving-party/models/app/serving-party';
import { Transfer } from '../transfer/models/app/transfer';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- this is some weird LINT bug. The object is used
import { DocumentRequestMapper } from './document-request-mapper-object';
import * as fileUpload from './file-transfer';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- this is some weird LINT bug. The object is used
import { GarnishmentMapper } from './garnishment-redux-objects';
import {
  ALL_MATTERS_REDUX_OBJECT_TOKEN,
  CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN,
  COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  COMPANY_ENTITY_REDUX_OBJECT_TOKEN,
  COMPANY_PICKLIST_REDUX_OBJECT_TOKEN,
  DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN,
  DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  EXTEND_PORTAL_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_OBJECT_TOKEN,
  INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN,
  INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN,
  LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN,
  LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN,
  LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN,
  MATTER_ALERTS_REDUX_OBJECT_TOKEN,
  MATTER_COUNTS_REDUX_OBJECT_TOKEN,
  MATTER_LOOKUP_REDUX_OBJECT_TOKEN,
  MATTER_SEARCH_REDUX_OBJECT_TOKEN,
  ORGANIZATION_REDUX_OBJECT_TOKEN,
  ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN,
  RECENT_MATTER_REDUX_OBJECT_TOKEN,
  REPORT_REDUX_OBJECT_TOKEN,
  RESEND_ANSWER_REDUX_OBJECT_TOKEN,
  RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN,
  SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN,
  SERVING_PARTY_REDUX_OBJECT_TOKEN,
  SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN,
  STARRED_MATTER_REDUX_OBJECT_TOKEN,
  TRANSFER_REDUX_OBJECT_TOKEN,
  TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN,
  UPDATE_PORTAL_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- this is some weird LINT bug. The object is used
import { LitigationMapper } from './litigation-redux-objects';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- this is some weird LINT bug. The object is used
import { OtherMatterMapper } from './other-matter-redux-objects';

import { CompanyPicklist } from '../company-picklist/models/app/company-picklist';
import { ILpmsState } from './state.interface';

@Injectable({ providedIn: 'root' })
export class MatterMapper extends ReduxDataAccessObject {
  constructor(
    public DocumentRequestMapper: DocumentRequestMapper,

    public LitigationMapper: LitigationMapper,

    public OtherMatterMapper: OtherMatterMapper,

    public GarnishmentMapper: GarnishmentMapper
  ) {
    super();
  }
}
@Injectable({ providedIn: 'root' })
export class LpmsDataAccessObject extends ReduxDataAccessObject {
  /* EXAMPLE OF ADDING NEW MODEL FROM API - LOCATIONS THAT NEED TO BE UPDATED */
  constructor(
    @Inject(SERVING_PARTY_REDUX_OBJECT_TOKEN) public ServingParty: SafariReduxApiObject<ISafariObjectState<ServingParty>, ServingParty>,
    @Inject(ORGANIZATION_REDUX_OBJECT_TOKEN) public Organization: SafariReduxApiObject<ISafariObjectState<Organization>, Organization>,
    @Inject(MATTER_COUNTS_REDUX_OBJECT_TOKEN) public MatterCounts: SafariReduxApiObject<ISafariObjectState<MatterCount>, MatterCount>,

    @Inject(MATTER_ALERTS_REDUX_OBJECT_TOKEN) public MatterAlerts: SafariReduxApiObject<ISafariObjectState<DocumentAlert>, DocumentAlert>,
    @Inject(MATTER_LOOKUP_REDUX_OBJECT_TOKEN) public MatterLookup: SafariReduxApiObject<ISafariObjectState<Matter>, Matter>,
    @Inject(MATTER_SEARCH_REDUX_OBJECT_TOKEN) public MatterSearch: SafariReduxApiObject<ISafariObjectState<MatterSearch>, MatterSearch>,
    @Inject(SERVING_PARTY_SEARCH_REDUX_OBJECT_TOKEN) public ServingPartySearch: SafariReduxApiObject<ISafariObjectState<ServingPartySearch>, ServingPartySearch>,
    @Inject(ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN) public OrganizationSearch: SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>,
    @Inject(ALL_MATTERS_REDUX_OBJECT_TOKEN) public AllMatters: SafariReduxApiObject<ISafariObjectState<Matter>, Matter>,
    @Inject(DROPDOWN_MATTER_SUBTYPE_REDUX_OBJECT_TOKEN) public MatterSubtype: SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtype>, DropdownMatterSubtype>,
    @Inject(DROPDOWN_MATTER_SUBTYPE_USER_REDUX_OBJECT_TOKEN) public MatterSubtypeUser: SafariReduxApiObject<ISafariObjectState<DropdownMatterSubtypeUser>, DropdownMatterSubtypeUser>,

    @Inject(COMPANY_ENTITY_REDUX_OBJECT_TOKEN) public CompanyEntity: SafariReduxApiObject<ISafariObjectState<CompanyEntity>, CompanyEntity>,
    @Inject(CHANGE_SUBTYPE_REDUX_OBJECT_TOKEN) public ChangeSubtype: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(EXTEND_PORTAL_REDUX_OBJECT_TOKEN) public ExtendPortal: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(RESEND_ANSWER_REDUX_OBJECT_TOKEN) public ResendAnswer: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(SEND_PORTAL_REMINDER_REDUX_OBJECT_TOKEN) public SendPortalReminder: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(UPDATE_PORTAL_REDUX_OBJECT_TOKEN) public UpdatePortal: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(RESEND_PORTAL_INVITE_REDUX_OBJECT_TOKEN) public ResendPortalInvite: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,

    @Inject(REPORT_REDUX_OBJECT_TOKEN) public Report: SafariReduxApiObject<ISafariObjectState<Report>, Report>,

    @Inject(LIBRARY_TEMPLATE_REDUX_OBJECT_TOKEN) public LibraryTemplate: SafariReduxApiObject<ISafariObjectState<LibraryTemplate>, LibraryTemplate>,
    @Inject(LIBRARY_FOLDER_LIST_REDUX_OBJECT_TOKEN) public LibraryFolderList: SafariReduxApiObject<ISafariObjectState<LibraryFolderList>, LibraryFolderList>,

    @Inject(INVOICE_TEMPLATE_REDUX_OBJECT_TOKEN) public InvoiceTemplate: SafariReduxApiObject<ISafariObjectState<InvoiceTemplate>, InvoiceTemplate>,
    @Inject(DOCUMENT_REQUEST_INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN)
    public DocumentRequestInvoiceTemplate_Preview: SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>,
    @Inject(INVOICE_TEMPLATE_PREVIEW_REDUX_OBJECT_TOKEN) public InvoiceTemplate_Preview: SafariReduxApiObject<ISafariObjectState<InvoiceTemplatePreview>, InvoiceTemplatePreview>,

    @Inject(STARRED_MATTER_REDUX_OBJECT_TOKEN) public StarredMatter: SafariReduxApiObject<ISafariObjectState<Matter>, Matter>,
    @Inject(RECENT_MATTER_REDUX_OBJECT_TOKEN) public RecentMatter: SafariReduxApiObject<ISafariObjectState<Matter>, Matter>,
    @Inject(LPMS_COMPANY_SETTINGS_REDUX_OBJECT_TOKEN) public LpmsCompany_Settings: SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>,

    @Inject(LIBRARY_DOCUMENT_REDUX_OBJECT_TOKEN) public Document: SafariReduxApiObject<ISafariObjectState<LibraryDocument>, LibraryDocument>,
    @Inject(GENERATE_REPORTS_MATTER_ATTACHMENTS_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportMatterAttachmentLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_REPORTS_MATTER_REVIEWERHISTORY_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportMatterReviewerHistoryLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_REPORTS_MATTER_PARTICIPANTS_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportMatterParticipantLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_REPORTS_MATTER_LEDGERTRANSACTIONS_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportMatterLedgerTransactionsLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_REPORTS_MATTER_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportMattertLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,

    @Inject(TRANSFER_REDUX_OBJECT_TOKEN) public Transfer: SafariReduxApiObject<ISafariObjectState<Transfer>, Transfer>,
    @Inject(TRANSFER_TEMPLINK_REDUX_OBJECT_TOKEN) public Transfer_TempLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(LIBRARY_DOCUMENT_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN) public LibraryDocumentAttachmentLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    public MatterMappers: MatterMapper,
    // "GLOBAL" objects
    @Inject(DROPDOWN_REDUX_OBJECT_TOKEN) public Dropdown,
    @Inject(LPMS_TRANSFER_DIALOG_OBJECT) public FileTransfer: SafariReduxTransferDialogObject,
    @Inject(COMPANY_PICKLIST_REDUX_OBJECT_TOKEN) public CompanyPicklist: SafariReduxApiObject<ISafariObjectState<CompanyPicklist>, CompanyPicklist>,
    @Inject(LIBRARY_DOCUMENT_FILE_OBJECT_REDUX_OBJECT_TOKEN) public LibraryDocumentFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>,
    @Inject(COMPANY_ENTITY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN) public CompanyEntityLogoFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>

    /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (1) ***/
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class ReducerService {
  constructor(
    public LpmsObject: LpmsDataAccessObject,
    private _logger: LoggerService
  ) {}
  get reducers(): ActionReducerMap<ILpmsState> {
    return {
      servingPartyCombinedState: combineReducers({ servingPartyState: REDUCER(this.LpmsObject.ServingParty, this._logger), searchState: REDUCER(this.LpmsObject.ServingPartySearch, this._logger) }),
      organizationCombinedState: combineReducers({ organizationState: REDUCER(this.LpmsObject.Organization, this._logger), searchState: REDUCER(this.LpmsObject.OrganizationSearch, this._logger) }),
      infoRequestCombinedState: combineReducers({
        extendPortalState: REDUCER(this.LpmsObject.ExtendPortal, this._logger),
        sendPortalReminderState: REDUCER(this.LpmsObject.SendPortalReminder, this._logger),
        updatePortalState: REDUCER(this.LpmsObject.UpdatePortal, this._logger),
        resendPortalInviteState: REDUCER(this.LpmsObject.ResendPortalInvite, this._logger),
        invoiceTemplatePreviewState: REDUCER(this.LpmsObject.DocumentRequestInvoiceTemplate_Preview, this._logger),
        infoRequestState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.Matter, this._logger),
        infoRequestPortalActivityState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.PortalActivity, this._logger),
        infoRequestNoteState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.Note, this._logger),
        infoRequestAttachmentTemplateState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.AttachmentTemplate, this._logger),
        infoRequestCorrespondenceState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.Correspondence, this._logger),
        infoRequestAllowedUserState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.AllowedUser, this._logger),
        infoRequestLinkState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.Link, this._logger),
        infoRequestTransferActivityState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.TransferActivity, this._logger),
        infoRequestCorrespondenceAttachmentState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.CorrespondenceAttachment, this._logger),
        infoRequestTemplateFieldState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.TemplateField, this._logger),
        infoRequestAttachmentMetadataState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.AttachmentMetadata, this._logger),
        infoRequestAttachmentFolderState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.AttachmentFolder, this._logger),
        infoRequestSubjectSearchState: REDUCER(this.LpmsObject.MatterMappers.DocumentRequestMapper.SubjectSearch, this._logger)
      }),

      litigationCombinedState: combineReducers({
        litigationState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.Matter, this._logger),

        litigationNoteState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.Note, this._logger),
        litigationAttachmentTemplateState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.AttachmentTemplate, this._logger),
        litigationCorrespondenceState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.Correspondence, this._logger),
        litigationAllowedUserState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.AllowedUser, this._logger),
        litigationLinkState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.Link, this._logger),
        litigationTransferActivityState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.TransferActivity, this._logger),
        litigationCorrespondenceAttachmentState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.CorrespondenceAttachment, this._logger),
        litigationTemplateFieldState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.TemplateField, this._logger),
        litigationAttachmentMetadataState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.AttachmentMetadata, this._logger),
        litigationAttachmentFolderState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.AttachmentFolder, this._logger),
        litigationSubjectSearchState: REDUCER(this.LpmsObject.MatterMappers.LitigationMapper.SubjectSearch, this._logger)
      }),
      garnishmentCombinedState: combineReducers({
        garnishmentState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.Matter, this._logger),

        garnishmentNoteState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.Note, this._logger),
        garnishmentAttachmentTemplateState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.AttachmentTemplate, this._logger),
        garnishmentCorrespondenceState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.Correspondence, this._logger),
        garnishmentAllowedUserState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.AllowedUser, this._logger),
        garnishmentLinkState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.Link, this._logger),
        garnishmentTransferActivityState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.TransferActivity, this._logger),
        garnishmentCorrespondenceAttachmentState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.CorrespondenceAttachment, this._logger),
        garnishmentTemplateFieldState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.TemplateField, this._logger),
        garnishmentAttachmentMetadataState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.AttachmentMetadata, this._logger),
        garnishmentAttachmentFolderState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.AttachmentFolder, this._logger),
        garnishmentSubjectSearchState: REDUCER(this.LpmsObject.MatterMappers.GarnishmentMapper.SubjectSearch, this._logger)
      }),
      transferCombinedState: combineReducers({ transferState: REDUCER(this.LpmsObject.Transfer, this._logger) }),
      otherMatterCombinedState: combineReducers({
        otherMatterState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.Matter, this._logger),

        otherMatterNoteState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.Note, this._logger),
        otherMatterAttachmentTemplateState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.AttachmentTemplate, this._logger),
        otherMatterCorrespondenceState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.Correspondence, this._logger),
        otherMatterAllowedUserState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.AllowedUser, this._logger),
        otherMatterLinkState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.Link, this._logger),
        otherMatterTransferActivityState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.TransferActivity, this._logger),
        otherMatterCorrespondenceAttachmentState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.CorrespondenceAttachment, this._logger),
        otherMatterTemplateFieldState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.TemplateField, this._logger),
        otherMatterAttachmentMetadataState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.AttachmentMetadata, this._logger),
        otherMatterAttachmentFolderState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.AttachmentFolder, this._logger),
        otherMatterSubjectSearchState: REDUCER(this.LpmsObject.MatterMappers.OtherMatterMapper.SubjectSearch, this._logger)
      }),
      invoiceTemplateCombinedState: combineReducers({
        invoiceTemplateState: REDUCER(this.LpmsObject.InvoiceTemplate, this._logger),
        invoiceTemplatePreviewState: REDUCER(this.LpmsObject.InvoiceTemplate_Preview, this._logger)
      }),
      dropdownCombinedState: combineReducers({ dropdownState: DROPDOWN_REDUCER(this.LpmsObject.Dropdown) }),
      fileCombinedState: fileUpload.combinedReducers,
      matterCombinedState: combineReducers({
        matterState: REDUCER(this.LpmsObject.AllMatters, this._logger),
        matterCountsState: REDUCER(this.LpmsObject.MatterCounts, this._logger),
        starredState: REDUCER(this.LpmsObject.StarredMatter, this._logger),
        recentState: REDUCER(this.LpmsObject.RecentMatter, this._logger),
        lookupState: REDUCER(this.LpmsObject.MatterLookup, this._logger),
        changeSubtypeState: REDUCER(this.LpmsObject.ChangeSubtype, this._logger),
        searchState: REDUCER(this.LpmsObject.MatterSearch, this._logger)
      }),
      libraryCombinedState: combineReducers({ libraryDocumentState: REDUCER(this.LpmsObject.Document, this._logger) }),
      libraryTemplateCombinedState: combineReducers({ libraryTemplateState: REDUCER(this.LpmsObject.LibraryTemplate, this._logger) }),
      libraryFolderListCombinedState: combineReducers({ libraryFolderListState: REDUCER(this.LpmsObject.LibraryFolderList, this._logger) }),
      companyEntityCombinedState: combineReducers({ companyEntityState: REDUCER(this.LpmsObject.CompanyEntity, this._logger) }),
      companyCombinedState: combineReducers({ companySettingsState: REDUCER(this.LpmsObject.LpmsCompany_Settings, this._logger) }),
      matterSubtypeCombinedState: combineReducers({
        matterSubtypeState: REDUCER(this.LpmsObject.MatterSubtype, this._logger),
        matterSubtypeUserState: REDUCER(this.LpmsObject.MatterSubtypeUser, this._logger)
      }),
      documentAlertCombinedState: combineReducers({ documentAlertState: REDUCER(this.LpmsObject.MatterAlerts, this._logger) }),
      reportCombinedState: combineReducers({ reportState: REDUCER(this.LpmsObject.Report, this._logger) }),
      companyPicklistCombinedState: combineReducers({ companyPicklistState: REDUCER(this.LpmsObject.CompanyPicklist, this._logger) })
      /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (2) ***/
    };
  }
}
