import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { NoteCreate } from '../models/api/note-create';
import { NoteList } from '../models/api/note-list';
import { NoteRead } from '../models/api/note-read';
import { Note } from '../models/app/note';
@Injectable({
  providedIn: 'root'
})
export class NoteAdapter extends ApiDataAdapter<Note> {
  toCreateModel(o: Note): Required<NoteCreate> {
    return {
      content: o.content
    };
  }

  fromGetModel(o: NoteRead): Required<Note> {
    return {
      authorFullName: o.authorFullName,
      authorAuthIdentityId: o.authorAuthIdentityId,
      content: o.content,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      id: this.helpers.fromApiId(o.id)
    };
  }
  fromListModel(o: NoteList): Required<Note> {
    return {
      authorFullName: o.authorFullName,
      authorAuthIdentityId: o.authorAuthIdentityId,
      content: o.content,
      dateCreated: this.helpers.fromApiDate(o.dateCreated, false),
      id: this.helpers.fromApiId(o.id)
    };
  }
  toDeleteModel(model: any) {
    return model;
  }
}
