import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownDocumentRequestClosedReasonList } from '../models/api/dropdown-document-request-closed-reason-list';
import { DropdownDocumentRequestClosedReason } from '../models/app/dropdown-document-request-closed-reason';

@Injectable({
  providedIn: 'root'
})
export class DropdownDocumentRequestClosedReasonAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownDocumentRequestClosedReasonList): Required<DropdownDocumentRequestClosedReason> {
    return {
      ...super.fromListModel(model),

      isManualReason: model.isManualReason,
      isDeliveredReason: model.isDeliveredReason,
      requireSoftRequiredFields: model.requireSoftRequiredFields
    };
  }
}
