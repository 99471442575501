import { Injectable } from '@angular/core';

import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { TransferActivityModel } from '../../_lpms-api-models.generated';
import { TransferActivity } from '../models/app/transfer-activity';
@Injectable({
  providedIn: 'root'
})
export class TransferActivityAdapter extends ApiDataAdapter<TransferActivity> {
  fromListModel(o: TransferActivityModel): TransferActivity {
    return {
      communicationType: o.communicationType,
      date: this.helpers.fromApiDate(o.date, false),
      errorMessage: o.errorMessage,
      from: o.from,
      id: null,
      to: o.to,
      type: o.type,
      initiatedBy: o.initiatedBy
    };
  }
}
