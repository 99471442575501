import { CoManageDataAccessObject } from '@safarilaw-webapp/feature/co-manage/data-access';
import { NavbarGroup, NavbarHeader, NavbarItem, NavbarService } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { Observable, map } from 'rxjs';

export class LpmsNavbarService extends NavbarService {
  /**
   *
   * WATCH IT here ! This will import the whole module
   * and LpmsNavbarService is used during app bootstrapping. So this has to be lean.
   *
   * For now it's fine for CoManageDataAccess as it is a fairly small module.
   * But you wouldn't want to import something like LpmsDataAccessObject since that would
   * significantly boost boostrapping module size.
   *
   * Also - if CoManageDataAccess becomes too big in the future we'll probaly have to split out
   * this functionality into another DataAccessModule called CoManageMyDataAccess or something like that
   * and then this would refer to that instead (and CoManageDataAccess would roll that in behind the scenes)
   */

  public CoManageObject: CoManageDataAccessObject;
  constructor() {
    super();
    this.CoManageObject = this.inject(CoManageDataAccessObject);
  }
  override getNavbarItems(): Observable<(NavbarGroup | NavbarHeader | NavbarItem)[]> {
    return this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID).pipe(
      map(() => {
        const items: (NavbarGroup | NavbarHeader | NavbarItem)[] = [];
        this.filterNavbarCollectionByPermissions(this.navbarCollection, items);
        return items;
      })
    );
  }
}
