import { Injectable } from '@angular/core';
import * as redux from '../../../../redux/index';
import { IGarnishmentEditPageUiState } from '../../../../redux/state.interface';
import { LpmsMatterPageUiReduxObject, defaultMatterEditPageUiState } from '../../../matter-edit/redux/state';

export const defaultGarnishmentEditPageUiState: IGarnishmentEditPageUiState = {
  ...defaultMatterEditPageUiState,
  addAccountRequest: null,
  addPaymentRequest: null,
  screenshotAccount: null,
  expandAccountRequest: null,
  persistent: {
    ...defaultMatterEditPageUiState.persistent,
    ...{
      accountGuidMappings: { ids: [] },
      paymentGuidMappings: { ids: [] }
    }
  }
};

@Injectable({ providedIn: 'root' })
export class LpmsGarnishmentEditPageUiReduxObject extends LpmsMatterPageUiReduxObject<IGarnishmentEditPageUiState> {
  accountScreenshotPasted = super.addMessage('Attach Account Screenshot', state => state.screenshotAccount, { screenshotAccount: this.fromAction() });
  requestAddAccount = super.addMessage('Add New Account', state => state.addAccountRequest, { addAccountRequest: this.fromAction() });
  requestAddPayment = super.addMessage('Add New Payment', state => state.addPaymentRequest, { addPaymentRequest: this.fromAction() });
  requestExpandAccount = super.addMessage('Expand Account', state => state.expandAccountRequest, { expandAccountRequest: this.fromAction() });
  updatePaymentGuidMappings = super.addMessage(
    'Update Payment Guid Mappings',
    state => state.persistent.paymentGuidMappings,
    (state, action) => ({
      ...state,
      persistent: { ...state.persistent, paymentGuidMappings: action, ...this.stampRequest() }
    })
  );
  updateAccountGuidMappings = super.addMessage(
    'Update Account Guid Mappings',
    state => state.persistent.accountGuidMappings,
    (state, action) => ({
      ...state,
      persistent: { ...state.persistent, accountGuidMappings: action, ...this.stampRequest() }
    })
  );

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.garnishmentEditPageState);
    super('GarnishmentEditPage', getPageState);
    super.addState({ ...defaultGarnishmentEditPageUiState, persistent: { ...defaultGarnishmentEditPageUiState.persistent } }).finalize();
  }
}
