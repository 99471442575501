import { Injectable } from '@angular/core';

import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { AlertModel, Country, DocumentRequestPortalRecipientModel, DocumentRequestSubjectModel, RfpaCertificateStatus, ServingPartyPhoneNumberModel, State } from './_lpms-api-models.generated';
import { MatterAlertBase } from './document-alert/models/app/document-alert-base';
import { ServingPartyPhoneType } from './enums';
import { Matter, MatterSubject } from './matter/models/app/matter';
import * as portalRecipientApp from './portal-recipient/models/app/portal-recipient';
import { ServingPartyPhone } from './serving-party-phone/models/app/serving-party-phone';

@Injectable({
  providedIn: 'root'
})
export class MatterAdapterHelper extends ApiDataAdapter<any> {
  constructor(private _featureFlagService: FeatureFlagsService) {
    super();
  }
  public getReviewerForList(reviewerName: string) {
    return { name: reviewerName, firstName: null, lastName: null, email: null, id: null, isActive: true };
  }

  public parseSubjectsForUpdate(subjects: MatterSubject[]): (Required<Omit<DocumentRequestSubjectModel, 'documentsRequestedTypes'>> & { documentsRequestedTypes?: string[] })[] {
    const parsedSubjects = [];
    for (const subject of subjects) {
      const parsedSubject: Required<Omit<DocumentRequestSubjectModel, 'documentsRequestedTypes'> & { documentsRequestedTypes?: string[] }> = {
        customerId: subject.customerId,
        address: subject.address
          ? {
              city: subject.address.city,
              country: subject.address.country as Country,
              state: subject.address.state as State,
              street1: subject.address.street1,
              street2: subject.address.street2,
              zipCode: subject.address.zipCode
            }
          : null,
        name: subject.name,
        originationDate: this.helpers.toApiDate(subject.originationDate),
        taxId: subject.taxId,
        notes: subject.notes,
        documentsRequested: subject.documentsRequested,
        documentsRequestedTypes: subject.documentsRequestedTypes,
        documentsRequestedText: subject.documentsRequestedText,
        type: subject.type,
        dateRangeStart: this.helpers.toApiDate(subject.dateRangeStart),
        dateRangeEnd: this.helpers.toApiDate(subject.dateRangeEnd),
        rfpaCertificateStatus: subject.rfpaCertificateStatus as RfpaCertificateStatus,
        rfpaNotes: subject.rfpaNotes,
        alias: subject.alias,
        first: subject.first,
        last: subject.last,
        middle: subject.middle,
        suffix: subject.suffix
      };

      parsedSubjects.push(parsedSubject);
    }
    return parsedSubjects;
  }
  public parseSubjectsForList(subjects: DocumentRequestSubjectModel[]): MatterSubject[] {
    const parsedSubjects = [];
    for (const subject of subjects || []) {
      const parsedSubject: MatterSubject = {
        customerId: subject.customerId,
        name: subject.name,
        taxId: subject.taxId,
        notes: subject.notes,
        address: null,
        originationDate: null,
        documentsRequested: null,
        documentsRequestedTypes: null,
        documentsRequestedText: null,
        type: null,
        dateRangeStart: null,
        dateRangeEnd: null,
        rfpaCertificateStatus: null,
        rfpaNotes: null,
        alias: subject.alias,
        first: subject.first,
        last: subject.last,
        middle: subject.middle,
        suffix: subject.suffix
      };
      parsedSubjects.push(parsedSubject);
    }

    return parsedSubjects;
  }
  public parseSubjectsForGet(subjects: (Omit<DocumentRequestSubjectModel, 'documentsRequestedTypes'> & { documentsRequestedTypes?: string[] })[]): MatterSubject[] {
    const parsedSubjects = [];
    for (const subject of subjects || []) {
      const parsedSubject: MatterSubject = {
        address: subject.address
          ? {
              city: subject.address.city,
              country: subject.address.country,
              state: subject.address.state,
              street1: subject.address.street1,
              street2: subject.address.street2,
              zipCode: subject.address.zipCode
            }
          : null,
        customerId: subject.customerId,
        name: subject.name,
        originationDate: this.helpers.fromApiDate(subject.originationDate),
        taxId: subject.taxId,
        notes: subject.notes,
        documentsRequested: subject.documentsRequested,
        documentsRequestedTypes: subject.documentsRequestedTypes,
        documentsRequestedText: subject.documentsRequestedText,
        type: subject.type,
        dateRangeStart: this.helpers.fromApiDate(subject.dateRangeStart),
        dateRangeEnd: this.helpers.fromApiDate(subject.dateRangeEnd),
        rfpaCertificateStatus: subject.rfpaCertificateStatus,
        rfpaNotes: subject.rfpaNotes,
        alias: subject.alias,
        first: subject.first,
        last: subject.last,
        middle: subject.middle,
        suffix: subject.suffix
      };
      parsedSubjects.push(parsedSubject);
    }

    return parsedSubjects;
  }
  public parseAlertsForGet(alerts: AlertModel[]) {
    const parsedAlerts = [];
    for (const alert of alerts || []) {
      const parsedAlert: MatterAlertBase = {
        alertType: alert.type,
        alertDate: this.helpers.fromApiDate(alert.date)
      };
      parsedAlerts.push(parsedAlert);
    }
    return parsedAlerts;
  }
  public parsePortalRecipientForGet(recipient: DocumentRequestPortalRecipientModel): portalRecipientApp.PortalRecipient {
    if (recipient == null) {
      return null;
    }
    const portalRecipient: portalRecipientApp.PortalRecipient = {
      expirationDate: this.helpers.fromApiDate(recipient.expirationDate, false),
      id: recipient.id.toString(),
      lastAccessDate: this.helpers.fromApiDate(recipient.lastAccessDate, false),
      responseId: recipient.responseId,
      hasConfirmedReceipt: recipient.hasConfirmedReceipt,
      requiresPayment: recipient.requiresPayment,
      netDue: recipient.netDue,
      paymentDate: recipient.paymentDate,
      hasPaid: recipient.hasPaid,
      portalUrl: recipient.portalUrl,
      email: null,
      name: null,
      passcode: null,
      portalInviteText: null
    };
    return portalRecipient;
  }

  public parseServingPartyPhonesForUpdate(matter: Matter): ServingPartyPhone[] {
    const phones: ServingPartyPhone[] = [];
    if (!String.isNullOrEmpty(matter.servingPartyPhone)) {
      phones.push({
        isPrimary: false,
        number: matter.servingPartyPhone,
        type: ServingPartyPhoneType.Office,
        extension: matter.servingPartyPhoneExtension
      });
    }

    if (!String.isNullOrEmpty(matter.servingPartyFax)) {
      phones.push({
        isPrimary: false,
        number: matter.servingPartyFax,
        type: ServingPartyPhoneType.Fax,
        extension: null
      });
    }
    if (!String.isNullOrEmpty(matter.servingPartyMobile)) {
      phones.push({
        isPrimary: false,
        number: matter.servingPartyMobile,
        type: ServingPartyPhoneType.Mobile,
        extension: null
      });
    }
    return phones;
  }

  public getServingPartyPhonesForGet(phones: ServingPartyPhoneNumberModel[]) {
    let phone;
    let fax;
    let mobile;
    if (Array.isArray(phones)) {
      phone = phones.find(p => p.type === ServingPartyPhoneType.Office);
      fax = phones.find(p => p.type === ServingPartyPhoneType.Fax);
      mobile = phones.find(p => p.type === ServingPartyPhoneType.Mobile);
    }

    return {
      servingPartyPhone: (phone || { number: null }).number,
      servingPartyPhoneExtension: (phone || { extension: null }).extension,
      servingPartyFax: (fax || { number: null }).number,
      servingPartyMobile: (mobile || { number: null }).number
    };
  }
}
