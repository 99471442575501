import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CreateSubjectSearchModel, SubjectSearchModel } from '../../_lpms-api-models.generated';
import { SubjectSearch } from '../models/app/subject-search';

@Injectable({
  providedIn: 'root'
})
export class SubjectSearchAdapter extends ApiDataAdapter<SubjectSearch> {
  fromGetModel(o: SubjectSearchModel): SafariRequired<SubjectSearch> {
    return {
      contentType: o.contentType,
      createdBy: o.createdBy,
      createdDate: this.helpers.fromApiDate(o.createdDate),
      fileName: o.fileName,
      id: this.helpers.fromApiId(o.id),
      lastUpdated: o.lastUpdated,
      matterId: this.helpers.fromApiId(o.matterId),
      matterType: o.matterType,
      result: o.result,
      searchIndex: o.searchIndex,
      searchStatus: o.searchStatus,
      searchType: o.searchType,
      sizeInBytes: o.sizeInBytes,
      searchName: o.searchName
    };
  }
  fromListModel(o: SubjectSearchModel): SafariRequired<SubjectSearch> {
    return {
      contentType: o.contentType,
      createdBy: o.createdBy,
      createdDate: this.helpers.fromApiDate(o.createdDate, false),
      fileName: o.fileName,
      id: this.helpers.fromApiId(o.id),
      lastUpdated: this.helpers.fromApiDate(o.lastUpdated, false),
      matterId: this.helpers.fromApiId(o.matterId),
      matterType: o.matterType,
      result: o.result,
      searchIndex: o.searchIndex,
      searchStatus: o.searchStatus,
      searchType: o.searchType,
      sizeInBytes: o.sizeInBytes,
      searchName: o.searchName
    };
  }

  toCreateModel(o: SubjectSearch): SafariRequired<CreateSubjectSearchModel> {
    return {
      searchIndex: o.searchIndex,
      searchType: o.searchType
    };
  }
}
