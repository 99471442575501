import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';

import { DropdownAnswerType as DropdownAnswerTypeApi } from '../models/api/dropdown-answer-type';
import { DropdownAnswerType } from '../models/app/dropdown-answer-type';
@Injectable({
  providedIn: 'root'
})
export class DropdownAnswerTypeAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownAnswerTypeApi): Required<DropdownAnswerType> {
    return {
      ...super.fromListModel(model),
      answerGroupName: model.answerGroupName,
      answerGroup: model.answerGroup
    };
  }
}
