import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterListModel, RecentItemModel } from '../../_lpms-api-models.generated';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { Matter, MatterForList } from '../../matter/models/app/matter';
@Injectable({
  providedIn: 'root'
})
export class RecentAdapter extends ApiDataAdapter<Matter> {
  constructor(private _matterAdapter: MatterAdapter) {
    super();
  }
  fromListModel(matter: MatterListModel): Required<MatterForList> {
    return this._matterAdapter.fromListModel(matter);
  }

  toCreateModel(model: Matter, context?: ApiCallContext): Required<RecentItemModel> {
    return {
      matterId: this.helpers.toApiId(model.id),
      matterType: model.type
    };
  }
}
