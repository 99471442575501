import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownUser } from '../models/app/dropdown-user';

import { getPhoneNumbersForApp, IAppUserPhoneNumbers } from '@safarilaw-webapp/shared/common-objects-models';
@Injectable({
  providedIn: 'root'
})
export class DropdownUserAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownUser): Required<DropdownUser> {
    return {
      ...super.fromListModel(model),
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      teamId: model.teamId,
      securityRole: model.securityRole,
      jobTitle: model.jobTitle,
      phoneNumbers: model.phoneNumbers,
      hasSignature: model.hasSignature,
      matterTypes: model.matterTypes.map(matterType => ({
        matterType: matterType.matterType,
        participantLevel: matterType.participantLevel,
        canBeReviewer: matterType.canBeReviewer,
        canBeParticipant: matterType.canBeParticipant
      })),
      ...(getPhoneNumbersForApp(model) as Required<IAppUserPhoneNumbers>)
    };
  }
}
