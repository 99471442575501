import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { MatterListModel, StarredMatterModel } from '../../_lpms-api-models.generated';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { Matter, MatterForList } from '../../matter/models/app/matter';
@Injectable({
  providedIn: 'root'
})
export class StarAdapter extends ApiDataAdapter<Matter> {
  constructor(private _matterAdapter: MatterAdapter) {
    super();
  }
  fromListModel(matter: MatterListModel): Required<MatterForList> {
    return this._matterAdapter.fromListModel(matter);
  }
  toDeleteModel(model: any) {
    return model;
  }
  toCreateModel(model: Matter, context?: ApiCallContext): Required<StarredMatterModel> {
    return {
      id: this.helpers.toApiId(model.id),
      type: model.type
    };
  }
}
