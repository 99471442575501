import { provideEnvironmentInitializer } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthGuardService } from '@safarilaw-webapp/shared/auth/store-access';

import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { NgxMaskOptions, provideEnvironmentNgxMask } from 'ngx-mask';
import { environmentImports } from './app.routing.env.imports';
import { InitComponent } from './init/init.component';

export const options: Partial<NgxMaskOptions> | (() => Partial<NgxMaskOptions>) = null;
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: NgxCurrencyInputMode.Financial
};

export const routes: Routes = [
  // This path used to point to "legal-process" which in turn used to load feature module
  // Now we are going to go straight to feature module, and that one internally will have
  // children that represent previous top level URLS (legal-process vs settings)
  {
    path: '',
    providers: [provideEnvironmentInitializer(() => {}), provideEnvironmentNgxCurrency(customCurrencyMaskConfig), provideEnvironmentNgxMask(options)],
    loadChildren: () => import('@safarilaw-webapp/feature/lpms/ui').then(m => m.routes)
    //canActivate: [AuthGuardService]
  },

  ...environmentImports,
  // COPY THIS FOR ALL NEW APPS
  // Routes for shared-common-pages
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'interstitial',
    loadComponent: () => import('@safarilaw-webapp/common-pages/interstitial').then(a => a.InterstitialComponent),

    data: { hideNavbar: true, hideHeader: true, operationName: 'Interstitial' }
  },
  {
    path: 'auth-callback',
    loadComponent: () => import('@safarilaw-webapp/common-pages/auth-callback').then(a => a.AuthCallbackComponent),

    data: { hideNavbar: false, hideHeader: true, operationName: 'Auth Callback' }
  },
  {
    path: 'logout',
    loadComponent: () => import('@safarilaw-webapp/common-pages/logout').then(a => a.LogoutComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Logout' }
  },
  {
    path: 'clear',
    loadComponent: () => import('@safarilaw-webapp/common-pages/clear-storage').then(a => a.ClearStorageComponent),

    data: { operationName: 'Clear Local Storage' }
  },
  {
    path: 'login-error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-error').then(a => a.LoginErrorComponent),

    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - Error' }
  },
  {
    path: 'login-no-user',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-no-user').then(a => a.LoginNoUserComponent),

    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - No User' }
  },
  {
    path: 'error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-error').then(a => a.PageErrorComponent),

    data: { pageTitle: 'Request Failure', operationName: 'Error' }
  },
  {
    path: 'login',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login').then(a => a.LoginComponent),

    data: { hideNavbar: true, hideHeader: true, suppressContainer: true, operationName: 'Login' }
  },
  {
    path: '403',
    loadComponent: () => import('@safarilaw-webapp/common-pages/unauthorized').then(a => a.UnauthorizedComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Not Authorized' }
  },
  {
    path: '404',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-not-found').then(a => a.PageNotFoundComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  },
  {
    path: '504',
    loadComponent: () => import('@safarilaw-webapp/common-pages/gateway-timeout').then(a => a.GatewayTimeoutComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Gateway Timeout' }
  },
  {
    path: 'termsofservice',
    loadComponent: () => import('@safarilaw-webapp/common-pages/terms-of-service').then(a => a.TermsOfServiceComponent),

    data: { pageTitle: 'Terms & Privacy', operationName: 'Terms Of Service' }
  },

  {
    path: 'sso-init',
    loadComponent: () => import('@safarilaw-webapp/common-pages/co-user-sso-init').then(a => a.CoUserSsoInitComponent),

    pathMatch: 'full',
    canActivate: [AuthGuardService],
    data: { hideNavbar: true, hideHeader: true, operationName: 'SSO Init' }
  },
  {
    path: 'select-company',
    loadComponent: () => import('@safarilaw-webapp/common-pages/co-user-select-company').then(a => a.CoUserSelectCompanyComponent),

    pathMatch: 'full',
    canActivate: [AuthGuardService],
    data: { hideNavbar: true, hideHeader: true, operationName: 'Select Company' }
  },
  { path: 'init', component: InitComponent, data: { hideNavbar: true, hideHeader: true } },
  {
    path: '**',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-not-found').then(a => a.PageNotFoundComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  }

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
];
