import { Injectable } from '@angular/core';
import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { MatterAttachmentType } from '@safarilaw-webapp/feature/lpms/data-access';
import { SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import { getEnumValues } from '@safarilaw-webapp/shared/utils';
import { IMatterPageUiState } from '../../../redux/state.interface';

export const defaultMatterEditPageUiState = {
  ...defaultPageUiState,
  openFolderRequest: undefined,

  addSubfolderRequest: undefined,
  fileEditFinalized: undefined,
  addLedgerReleaseFundsRequest: undefined,
  generateTemplatePreviewRequest: undefined,
  loadCorrespondenceAttachmentsRequest: undefined,
  loadCorrespondenceRequest: undefined,
  loadAttachmentTemplateRequest: undefined,
  resendAnswerRequest: undefined,
  resendCorrespondenceRequest: undefined,
  validateCorrespondenceRequest: undefined,
  fileEditFinalizeRequest: undefined,
  attachmentTemplateCreateRequest: undefined,
  toggleStarRequest: undefined,
  loadInvoiceTemplateRequest: null,
  invoicePreviewInfo: null,
  currentSubtype: null,
  companyEntityUpdateRequest: undefined,
  loadAllowedUsersRequest: null,
  loadUserRequest: undefined,
  changeSubtypeRequest: null,
  loadHistoryRequest: null,
  expandOrCollapseAllTwistiesRequest: null,
  expandOrCollapseAllSubjectTwistiesRequest: null,
  generateZipRequest: null,
  addSubjectRequest: null,
  removeSubjectRequest: null,
  currentStatus: null,
  addNoteRequest: undefined,
  showLinkSectionRequest: undefined,
  addLinkRequest: undefined,
  transferLedgerInfo: undefined,
  newSubjectSearchesInfo: undefined,
  // attachmentCount array is simply a map of enum to index, but since there
  // is no enum with value 0 the first entry is always going to be undefined and can be ignored
  attachmentCount: [undefined, ...(Array(getEnumValues(MatterAttachmentType).length).fill(0) as number[])],
  attachmentsPendingUploadCount: undefined,
  netAttachmentsCount: undefined,
  draftInfo: { hasDrafts: false },
  documentEditorFormOpen: { isOpen: false },
  servingPartyLookupRequest: null,
  servingPartyOrgLookupRequest: null,
  closeAnswerEditorRequest: null,
  persistent: {
    fileAttachmentTypeTab: MatterAttachmentType.Served,
    notesVisible: false,
    linksVisible: false,
    participantsVisible: false,
    automationVisible: false,
    transferVisible: false,
    historyVisible: false,
    openFolderRequest: [],
    subjectGuidMappings: [],
    recoverExtendPortalValues: undefined
  },
  errors: {
    fileTabHasValidationErrors: []
  }
};

@Injectable({ providedIn: 'any' })
export abstract class LpmsMatterPageUiReduxObject<S extends IMatterPageUiState> extends SafariReduxPageUiObject<S> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  requestRecoverExtendPortalValues = super.addMessage(
    'Recover Extend Portal Values',
    state => state.persistent.recoverExtendPortalValues,
    (state, action) => ({
      ...state,
      persistent: { ...state.persistent, recoverExtendPortalValues: action.payload ? { ...action.payload } : null }
    })
  );
  requestLoadHistory = super.addMessage('Request LoadHistory', state => state.loadHistoryRequest, {
    loadHistoryRequest: this.fromAction()
  });
  requestCloseAnswerEditor = super.addMessage('Request Close Answer Editor', state => state.closeAnswerEditorRequest, {
    closeAnswerEditorRequest: this.fromAction()
  });
  setFileAttachmentTypeTab = super.addMessage(
    'Set File Attachment Type Tab',
    state => state.persistent.fileAttachmentTypeTab,
    (state, action) => ({
      ...state,
      persistent: { ...state.persistent, fileAttachmentTypeTab: action.drawer }
    })
  );
  toggleInvoicePreviewMessage = super.addMessage('Toggle Invoice Preview Message', state => state.invoicePreviewInfo, { invoicePreviewInfo: this.fromAction() });
  requestAllowedUsersRefresh = super.addMessage('Request Allowed Users Refresh', state => state.loadAllowedUsersRequest, { loadAllowedUsersRequest: this.fromAction() });
  updateNewSubjectSearches = super.addMessage('Update New Subject Searches', state => state.newSubjectSearchesInfo, { newSubjectSearchesInfo: this.fromAction() });
  updateTransferLedgerInfo = super.addMessage('Update Transfer Ledger Info', state => state.transferLedgerInfo, { transferLedgerInfo: this.fromAction() });
  clearInvoicePreviewMessage = super.addMessage('Clear Invoice Preview Message', state => state.invoicePreviewInfo, { invoicePreviewInfo: null });

  toggleNotes = super.addMessage(
    'Toggle Notes',
    state => state.persistent.notesVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, notesVisible: !state.persistent.notesVisible, ...this.stampRequest() }
    })
  );

  openFolder = super.addMessage(
    'Open Folder',
    state => state.persistent.openFolderRequest,
    (state, action) => {
      const array = [...state.persistent.openFolderRequest];
      array[action.attachmentType] = { id: action.id, attachmentType: action.attachmentType };
      return {
        ...state,
        persistent: { ...state.persistent, openFolderRequest: [...array], ...this.stampRequest() }
      };
    }
  );

  expandNotes = super.addMessage(
    'Expand Notes',
    state => state.persistent.notesVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, notesVisible: true, ...this.stampRequest() }
    })
  );

  collapseNotes = super.addMessage(
    'Collapse Notes',
    state => state.persistent.notesVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, notesVisible: false, ...this.stampRequest() }
    })
  );

  toggleLinks = super.addMessage(
    'Toggle Links',
    state => state.persistent.linksVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, linksVisible: !state.persistent.linksVisible, ...this.stampRequest() }
    })
  );
  expandLinks = super.addMessage(
    'Expand Links',
    state => state.persistent.linksVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, linksVisible: true, ...this.stampRequest() }
    })
  );
  collapseLinks = super.addMessage(
    'Collapse Links',
    state => state.persistent.linksVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, linksVisible: false, ...this.stampRequest() }
    })
  );

  toggleParticipants = super.addMessage(
    'Toggle Participants',
    state => state.persistent.participantsVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, participantsVisible: !state.persistent.participantsVisible, ...this.stampRequest() }
    })
  );
  toggleAutomation = super.addMessage(
    'Toggle Automation',
    state => state.persistent.automationVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, automationVisible: !state.persistent.automationVisible, ...this.stampRequest() }
    })
  );
  toggleTransfer = super.addMessage(
    'Toggle Transfer',
    state => state.persistent.transferVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, transferVisible: !state.persistent.transferVisible, ...this.stampRequest() }
    })
  );
  expandAutomation = super.addMessage(
    'Expand Automation',
    state => state.persistent.automationVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, automationVisible: true, ...this.stampRequest() }
    })
  );
  collapseAutomation = super.addMessage(
    'Collapse Automation',
    state => state.persistent.automationVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, automationVisible: false, ...this.stampRequest() }
    })
  );

  expandTransfers = super.addMessage(
    'Expand Transfers',
    state => state.persistent.transferVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, transferVisible: true, ...this.stampRequest() }
    })
  );
  collapseTransfers = super.addMessage(
    'Collapse Transfers',
    state => state.persistent.transferVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, transferVisible: false, ...this.stampRequest() }
    })
  );

  expandParticipants = super.addMessage(
    'Expand Participants',
    state => state.persistent.participantsVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, participantsVisible: true, ...this.stampRequest() }
    })
  );
  collapseParticipants = super.addMessage(
    'Collapse Participants',
    state => state.persistent.participantsVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, participantsVisible: false, ...this.stampRequest() }
    })
  );

  toggleHistory = super.addMessage(
    'Toggle History',
    state => state.persistent.historyVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, historyVisible: !state.persistent.historyVisible, ...this.stampRequest() }
    })
  );
  expandHistory = super.addMessage(
    'Expand History',
    state => state.persistent.historyVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, historyVisible: true, ...this.stampRequest() }
    })
  );
  collapseHistory = super.addMessage(
    'Collapse History',
    state => state.persistent.historyVisible,
    state => ({
      ...state,
      persistent: { ...state.persistent, historyVisible: false, ...this.stampRequest() }
    })
  );

  addNote = super.addMessage('Add Note', state => state.addNoteRequest, { addNoteRequest: this.fromAction() });
  showLinksSection = super.addMessage('Show Links Section', state => state.showLinkSectionRequest, { showLinkSectionRequest: this.fromAction() });
  createNewAttachmentTemplate = super.addMessage('Create New Attatchment Template', state => state.attachmentTemplateCreateRequest, { attachmentTemplateCreateRequest: this.fromAction() });

  addNewFolder = super.addMessage('Add New Folder', state => state.addSubfolderRequest, { addSubfolderRequest: this.fromAction() });

  requestAddLink = super.addMessage(
    'Request Add Link',
    state => {
      if (state.addLinkRequest == null || state.addLinkRequest._success !== undefined) {
        return null;
      }
      return state.addLinkRequest;
    },
    (state, action) => ({
      ...state,
      addLinkRequest: { ...action, _success: undefined, ...this.stampRequest() }
    })
  );
  requestAddLinkSuccess = super.addMessage(
    'Request Add Link Success',
    state => {
      if (state.addLinkRequest == null || state.addLinkRequest._success !== true) {
        return null;
      }
      return state.addLinkRequest;
    },
    (state, action) => ({
      ...state,
      addLinkRequest: { ...action, _success: true, ...this.stampRequest() }
    })
  );

  netAttachmentsCount = super.addMessage('Set Net Attachments Count', state => state.netAttachmentsCount, { netAttachmentsCount: this.fromAction() });
  attachmentsPendingUploadCount = super.addMessage('Set Attachments Pending Upload Count', state => state.attachmentsPendingUploadCount, { attachmentsPendingUploadCount: this.fromAction() });
  requestLoadUser = super.addMessage('Request Load User', state => state.loadUserRequest, { loadUserRequest: this.fromAction() });
  requestToggleStar = super.addMessage('Request Toggle Star', state => state.toggleStarRequest, { toggleStarRequest: this.fromAction() });
  requestFileEditFinalize = super.addMessage('Request File Edit Finalize', state => state.fileEditFinalizeRequest, { fileEditFinalizeRequest: this.fromAction() });
  validateCorrespondenceVariables = super.addMessage(
    'Validate Correspondence Variables',
    state => {
      if (state.validateCorrespondenceRequest == null || state.validateCorrespondenceRequest._success !== undefined) {
        return null;
      }
      return state.validateCorrespondenceRequest;
    },
    (state, action) => ({
      ...state,
      validateCorrespondenceRequest: { ...action, _success: undefined, ...this.stampRequest() }
    })
  );

  validateCorrespondenceVariablesSuccess = super.addMessage(
    'Validate Correspondence Variables Success',
    state => {
      if (state.validateCorrespondenceRequest == null || state.validateCorrespondenceRequest._success !== true) {
        return null;
      }
      return state.validateCorrespondenceRequest;
    },
    (state, action) => ({
      ...state,
      validateCorrespondenceRequest: { ...action, _success: true, ...this.stampRequest() }
    })
  );

  validateCorrespondenceVariablesFail = super.addMessage(
    'Validate Correspondence Variables Fail',
    state => {
      if (state.validateCorrespondenceRequest == null || state.validateCorrespondenceRequest._success !== false) {
        return null;
      }
      return state.validateCorrespondenceRequest;
    },
    (state, action) => ({
      ...state,
      validateCorrespondenceRequest: { ...action, _success: false, ...this.stampRequest() }
    })
  );
  resendAnswer = super.addMessage('Resend Answer', state => state.resendAnswerRequest, { resendAnswerRequest: this.fromAction() });
  resendCorrespondence = super.addMessage('Resend Correspondence', state => state.resendCorrespondenceRequest, { resendCorrespondenceRequest: this.fromAction() });
  requestLoadCorrespondence = super.addMessage('Request Load Correspondence', state => state.loadCorrespondenceRequest, { loadCorrespondenceRequest: this.fromAction() });
  requestLoadAttachmentTemplate = super.addMessage('Request Load AttachmentTemplate', state => state.loadAttachmentTemplateRequest, { loadAttachmentTemplateRequest: this.fromAction() });
  requestLoadCorrespondenceAttachments = super.addMessage('Request Load Correspondence Attachments', state => state.loadCorrespondenceAttachmentsRequest, {
    loadCorrespondenceAttachmentsRequest: this.fromAction()
  });
  requestAddLedgerReleaseFunds = super.addMessage('Request Add Ledger Release Funds', state => state.addLedgerReleaseFundsRequest, { addLedgerReleaseFundsRequest: this.fromAction() });
  generateTemplatePreview = super.addMessage(
    'Generate Template Preview',
    state => {
      if (state.generateTemplatePreviewRequest == null || state.generateTemplatePreviewRequest._success !== undefined) {
        return null;
      }
      return state.generateTemplatePreviewRequest;
    },
    (state, action) => ({
      ...state,
      generateTemplatePreviewRequest: { ...action, _success: undefined, ...this.stampRequest() }
    })
  );
  generateTemplatePreviewSuccess = super.addMessage(
    'Generate Template Preview Success',
    state => {
      if (state.generateTemplatePreviewRequest == null || state.generateTemplatePreviewRequest._success !== true) {
        return null;
      }
      return state.generateTemplatePreviewRequest;
    },
    (state, action) => ({
      ...state,
      generateTemplatePreviewRequest: { ...action, _success: true, ...this.stampRequest() }
    })
  );

  generateTemplatePreviewFail = super.addMessage(
    'Generate Template Preview Fail',
    state => {
      if (state.generateTemplatePreviewRequest == null || state.generateTemplatePreviewRequest._success !== false) {
        return null;
      }
      return state.generateTemplatePreviewRequest;
    },
    (state, action) => ({
      ...state,
      generateTemplatePreviewRequest: { ...action, _success: false, ...this.stampRequest() }
    })
  );
  requestServingPartyLookup = super.addMessage('Request Serving Party Lookup', state => state.servingPartyLookupRequest, { servingPartyLookupRequest: this.fromAction() });
  requestServingPartyOrgLookup = super.addMessage('Request Serving Party Org Lookup', state => state.servingPartyOrgLookupRequest, { servingPartyOrgLookupRequest: this.fromAction() });
  requestAddSubject = super.addMessage('Request Add Subject', state => state.addSubjectRequest, { addSubjectRequest: this.fromAction() });
  requestRemoveSubject = super.addMessage('Request Remove Subject', state => state.removeSubjectRequest, { removeSubjectRequest: this.fromAction() });
  requestChangeSubtype = super.addMessage('Request Change Subtype', state => state.changeSubtypeRequest, { changeSubtypeRequest: this.fromAction() });

  requestLoadInvoiceTemplatePreview = super.addMessage('Request Load Invoice Template Preview', state => state.loadInvoiceTemplateRequest, { loadInvoiceTemplateRequest: this.fromAction() });
  fileEditFinalized = super.addMessage('File Edit Finalized', state => state.fileEditFinalized, { fileEditFinalized: this.fromAction() });
  updateCompanyEntity = super.addMessage('Update Company Entity', state => state.companyEntityUpdateRequest, { companyEntityUpdateRequest: this.fromAction() });
  updateHasDrafts = super.addMessage('Update Has Drafts', state => state.draftInfo, { draftInfo: this.fromAction() });
  updateStatus = super.addMessage('Update Current Status', state => state.currentStatus, { currentStatus: this.fromAction() });
  updateDocumentEditFormOpen = super.addMessage('Update Document Edit Form Open', state => state.documentEditorFormOpen, { documentEditorFormOpen: this.fromAction() });
  updateSubjectGuidMappings = super.addMessage(
    'Update Subject Guid Mappings',
    state => state.persistent.subjectGuidMappings,
    (state, action) => ({
      ...state,
      persistent: { ...state.persistent, subjectGuidMappings: [...action.ids], ...this.stampRequest() }
    })
  );
  updateTabsWithValidationErrors = super.addMessage(
    'Update Tabs With Validation Errors',
    state => state.errors.fileTabHasValidationErrors,
    (state, action) => {
      const array = [...state.errors.fileTabHasValidationErrors];
      array[action.id] = action.hasErrors;
      return {
        ...state,
        errors: {
          ...state.errors,
          fileTabHasValidationErrors: array
        },
        ...this.stampRequest()
      };
    }
  );

  constructor(pageName: string, sliceSelector: MemoizedSelector<object, S, DefaultProjectorFn<S>> = null) {
    super('Lpms', pageName, sliceSelector);
  }
}
