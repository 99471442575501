import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import {
  CreateMatterAttachmentFolderModel,
  DocumentRequestAttachmentModel,
  GarnishmentAttachmentModel,
  LitigationAttachmentModel,
  MatterAttachmentFolderModel,
  MatterAttachmentFolderUpdateModel,
  OtherMatterAttachmentModel
} from '../../_lpms-api-models.generated';
import { AttachmentFolder } from '../models/app/attachment-folder';

@Injectable({
  providedIn: 'root'
})
export class AttachmentFolderAdapter extends ApiDataAdapter<AttachmentFolder> {
  fromGetNewModel(): SafariRequired<AttachmentFolder> {
    return {
      id: ObjectHelper.getNewId(),
      name: null,
      type: null,
      dateUpdated: undefined,
      updatedBy: undefined
    };
  }
  fromGetModel(o: MatterAttachmentFolderModel): SafariRequired<AttachmentFolder> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      type: o.type,
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      updatedBy: o.updatedBy
    };
  }
  toUpdateModel(o: AttachmentFolder): SafariRequired<MatterAttachmentFolderUpdateModel> {
    return {
      name: o.name
    };
  }
  toCreateModel(o: AttachmentFolder): SafariRequired<CreateMatterAttachmentFolderModel> {
    return {
      name: o.name,
      type: o.type
    };
  }
  fromListModel(o: DocumentRequestAttachmentModel | GarnishmentAttachmentModel | OtherMatterAttachmentModel | LitigationAttachmentModel): SafariRequired<AttachmentFolder> {
    return {
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      type: o.type,
      dateUpdated: this.helpers.fromApiDate(o.dateUpdated, false),
      updatedBy: o.updatedBy
    };
  }
  toDeleteModel(o: any) {
    return o;
  }
}
