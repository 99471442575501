import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CorrespondenceAttachmentModel } from '../../_lpms-api-models.generated';
import { CorrespondenceAttachment } from '../models/app/correspondence-attachment';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenceAttachmentAdapter extends ApiDataAdapter<CorrespondenceAttachment> {
  /**
  NOTE: This endpoint uses fileTransferService which implies that POST will use file transfer 
  features and operate on FileObject/MultiForm data. Therefore there is no Create version
  for the adapter
   */
  fromGetModel(o: CorrespondenceAttachmentModel): SafariRequired<CorrespondenceAttachment> {
    return {
      contentType: o.contentType,
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      sizeInBytes: o.sizeInBytes
    };
  }

  fromListModel(o: CorrespondenceAttachmentModel): SafariRequired<CorrespondenceAttachment> {
    return {
      contentType: o.contentType,
      id: this.helpers.fromApiId(o.id),
      name: o.name,
      sizeInBytes: o.sizeInBytes
    };
  }
  toDeleteModel(o: any) {
    return o;
  }
}
