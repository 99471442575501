import { Injectable } from '@angular/core';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { PageUiAction, PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../../../redux/index';

const defaultMatterSearchUiState = {
  ...defaultPageUiState,
  closeSearchRequest: undefined,
  searchMatterRequest: undefined
};
/**
 * THIS IS TO BE USED AS TEMPLATE FOR ALL NEW (AND EVENTUALLY EXISTING) PAGE REDUX OBJECTS
 */
export class SearchMatterRequest {
  searchQuery: string;
  typeFilter: IdName;
}
export class IMatterSearchPageState extends PageUiState {
  closeSearchRequest: PageUiAction;
  searchMatterRequest: SearchMatterRequest;
}
@Injectable({ providedIn: 'root' })
export class LpmsMatterSearchPageReduxUiObject extends SafariReduxPageUiObject<IMatterSearchPageState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};
  /**
   * This is a new, consolidated way of creating strong-typed actions, selectors and reducer for the page
   * in one shot.
   * We will still keep our original addActionSelector and getReducers as protected in the base in case
   * we have some more complex situations in the future but it's possible that all we need on page UI
   * can be handled with this addMessage method.
   */
  requestSearchClose = super.addMessage('Close Search', state => state.closeSearchRequest, { closeSearchRequest: this.fromAction() });

  requestMatterSearch = super.addMessage('Request Search', state => state.searchMatterRequest, { searchMatterRequest: this.fromAction() });

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.matterSearch);
    super('Lpms', 'Matter Search', getPageState, defaultMatterSearchUiState);
  }
}
