import { Injectable } from '@angular/core';

import { ApiDataAdapter, PatchObject, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DateTime } from 'luxon';
import {
  DeliveryMethod,
  DocumentRequestModel,
  EnterDocumentRequestDataModel,
  InformationRequestClosedReason,
  InformationRequestStatus,
  InvoiceType,
  MatterType,
  ReceiveDocumentRequestModel,
  State
} from '../../_lpms-api-models.generated';
import { INVOICE_TYPE_ENUM_MAX } from '../../enums';
import { MatterAdapter } from '../../matter/adapters/matter-adapter';
import { MatterSubject } from '../../matter/models/app/matter';
import { MatterAdapterHelper } from '../../utils';
import { DocumentRequest } from '../models/app/document-request';
import { DocumentRequestBillingUtility } from '../services/document-request-billing-utility';

@Injectable({
  providedIn: 'root'
})
export class DocumentRequestAdapter extends ApiDataAdapter<DocumentRequest> {
  constructor(
    private _documentRequestBillingService: DocumentRequestBillingUtility,
    private _matterAdapter: MatterAdapter,
    private _matterAdapterHelper: MatterAdapterHelper
  ) {
    super();
  }

  fromGetNewModel(): SafariRequired<DocumentRequest> {
    const currentDate = DateTime.now().toISO();
    return {
      ...this._matterAdapter.fromGetNewModel(),
      ...{
        portalDurationInDays: null,
        dateResponseDue: null,
        deliveryStatus: null,
        deliveryMethod: null,
        subjects: [],
        dateRangeStart: currentDate,
        dateRangeEnd: currentDate,
        collectableFee: 0,
        collectedFee: 0,
        unpaidInvoiceAttachmentId: undefined,
        type: MatterType.DocumentRequest,
        portalRecipient: null,
        mailTrackingReferenceNumber: null,
        dateToReturn: currentDate,
        invoiceAmount: 0,
        customInvoiceDescription: '',
        invoiceType: 0,
        invoiceTemplateId: null,
        prepaidCheckNumber: '',
        finalPaymentAmount: 0,
        hasRestrictedAccess: false,
        finalPaymentCheckNumber: '',
        prepaidAmount: 0,
        participants: [],
        portalActivity: [],
        internalReferenceId: '',
        hasEntityNameDiscrepancy: false,
        transferMethod: null,
        stateReceived: null,
        requireOneTimePin: false,
        closedReason: null,
        alerts: [],
        bsaNotes: null,
        bsaNotificationRequired: null,
        bsaReviewDate: null,
        isRollingProduction: null,
        servingPartyInstructions: null,
        servingPartyRequireInstructionAcknowledgment: null,
        legalAuthorityTypes: null,
        legalAuthorityText: null,
        deliveryPortalAcknowledgmentAccepted: null
      }
    };
  }
  toUpdatePartialModel(formModel: Partial<DocumentRequest>, originalObject: Partial<DocumentRequest>): PatchObject {
    return super.getPatchObject(formModel);
  }
  toCreateModel(documentRequest: DocumentRequest): Required<ReceiveDocumentRequestModel> {
    return {
      ...this._matterAdapter.toCreateModel(documentRequest),
      dateResponseDue: documentRequest.dateResponseDue,
      dateToReturn: documentRequest.dateToReturn,
      entityServedId: null
    };
  }
  getReviewerForList(reviewerName: string) {
    return { name: reviewerName, firstName: null, lastName: null, email: null, id: null };
  }
  toUpdateModel(documentRequest: DocumentRequest): Omit<Required<EnterDocumentRequestDataModel>, 'legalAuthorityTypes' | 'caseSubtypeIds' | 'notifiedTeamIds'> & {
    legalAuthorityTypes: string[];
    caseSubtypeIds: string[];
    notifiedTeamIds: string[];
  } {
    const retVal: Omit<Required<EnterDocumentRequestDataModel>, 'legalAuthorityTypes' | 'caseSubtypeIds' | 'notifiedTeamIds'> & {
      legalAuthorityTypes: string[];
      caseSubtypeIds: string[];
      notifiedTeamIds: string[];
    } = {
      ...this._matterAdapter.toUpdateModel(documentRequest),
      status: documentRequest.status as InformationRequestStatus,
      closedReason: documentRequest.closedReason as InformationRequestClosedReason,
      // force subjects as any for now
      subjects: documentRequest.subjects ? (this._matterAdapterHelper.parseSubjectsForUpdate(documentRequest.subjects) as any) : documentRequest.subjects,
      dateResponseDue: documentRequest.dateResponseDue ? this.helpers.toApiDate(documentRequest.dateResponseDue) : documentRequest.dateResponseDue,
      dateToReturn: documentRequest.dateToReturn ? this.helpers.toApiDate(documentRequest.dateToReturn) : documentRequest.dateToReturn,
      deliveryMethod: documentRequest.deliveryMethod as DeliveryMethod,
      matterName: documentRequest.matterName,
      mailTrackingReferenceNumber: documentRequest.mailTrackingReferenceNumber,
      invoiceType: documentRequest.invoiceType as InvoiceType,
      invoiceTemplateId: this.helpers.toApiId(documentRequest.invoiceTemplateId),
      invoiceAmount: documentRequest.invoiceAmount,
      customInvoiceDescription: documentRequest.customInvoiceDescription,
      prepaidAmount: documentRequest.legalOrderFeeOrPrepaidAmount,
      prepaidCheckNumber: documentRequest.legalOrderFeeOrPrepaidCheckNumber,
      internalReferenceId: documentRequest.internalReferenceId,
      hasRestrictedAccess: documentRequest.hasRestrictedAccess,
      hasEntityNameDiscrepancy: documentRequest.hasEntityNameDiscrepancy,
      stateReceived: documentRequest.stateReceived as unknown as State,
      finalPaymentAmount: documentRequest.finalPaymentAmount,
      finalPaymentCheckNumber: documentRequest.finalPaymentCheckNumber,
      requireOneTimePin: documentRequest.requireOneTimePin,
      portalDurationInDays: documentRequest.portalDurationInDays,
      bsaNotificationRequired: documentRequest.bsaNotificationRequired,
      bsaNotes: documentRequest.bsaNotes,
      bsaReviewDate: this.helpers.toApiDate(documentRequest.bsaReviewDate),
      isRollingProduction: documentRequest.isRollingProduction,
      servingPartyInstructions: documentRequest.servingPartyInstructions,
      servingPartyRequireInstructionAcknowledgment: documentRequest.servingPartyRequireInstructionAcknowledgment,
      legalAuthorityTypes: documentRequest.legalAuthorityTypes,
      legalAuthorityText: documentRequest.legalAuthorityText,
      deliveryPortalAcknowledgmentAccepted: documentRequest.deliveryPortalAcknowledgmentAccepted
    };

    /* Don't submit certain billing fields when the invoice state doesn't support it */
    const invoiceType = this._documentRequestBillingService.getSelectedInvoiceType(
      documentRequest['__data']?.invoiceTypeList,
      documentRequest['__data']?.invoiceTemplateDropdownList,
      retVal.invoiceType
    );

    const isManualInvoice = this._documentRequestBillingService.isManualInvoice(invoiceType);
    const invoiceHasCustomNarrative = this._documentRequestBillingService.invoiceHasCustomNarrative(invoiceType);
    const invoiceHasCustomAmount = this._documentRequestBillingService.invoiceAllowsCustomAmount(invoiceType);
    const hasCostRecovery = this._documentRequestBillingService.invoiceHasCostRecovery(invoiceType);
    if (!isManualInvoice) {
      delete retVal.finalPaymentAmount;
      delete retVal.finalPaymentCheckNumber;
    }
    if (!invoiceHasCustomNarrative) {
      delete retVal.customInvoiceDescription;
    }
    if (!invoiceHasCustomAmount) {
      delete retVal.invoiceAmount;
    }
    if (!hasCostRecovery) {
      delete retVal.invoiceAmount;
    }

    /* InvoiceTemplateId and InvoiceType are one field on the form, two separate fields in the API.
     * If the submission is not form based (like portal close), invoiceType and invoiceTemplateId will be set already and not
     * require a conversion. The only case we need to cover like this is when invoiceType is actually set to InvoiceTemplate */
    if (retVal.invoiceType != InvoiceType.AutoGenerated_Template && typeof retVal.invoiceType !== 'undefined') {
      retVal.invoiceTemplateId = retVal.invoiceType > INVOICE_TYPE_ENUM_MAX ? retVal.invoiceType : null;
      retVal.invoiceType = retVal.invoiceType > INVOICE_TYPE_ENUM_MAX ? InvoiceType.AutoGenerated_Template : retVal.invoiceType;
    }

    return retVal;
  }

  fromGetModel(
    documentRequest: Omit<DocumentRequestModel, 'caseTypeIds' | 'caseSubtypeIds | subjects | notifiedTeamIds'> & {
      caseTypeIds: string[];
      caseSubtypeIds: string[];
      subjects: MatterSubject[];
      notifiedTeamIds: string[];
    }
  ): Required<DocumentRequest> {
    return {
      ...this._matterAdapter.fromCommonGetModel(documentRequest),
      unpaidInvoiceAttachmentId: this.helpers.fromApiId(documentRequest.unpaidInvoiceAttachmentId),
      type: MatterType.DocumentRequest,
      deliveryStatus: documentRequest.deliveryStatus,
      deliveryMethod: documentRequest.deliveryMethod,
      dateResponseDue: this.helpers.fromApiDate(documentRequest.dateResponseDue),
      dateToReturn: this.helpers.fromApiDate(documentRequest.dateToReturn),

      mailTrackingReferenceNumber: documentRequest.mailTrackingReferenceNumber,
      invoiceType: documentRequest.invoiceType,
      portalRecipient: this._matterAdapterHelper.parsePortalRecipientForGet(documentRequest.portalRecipient),
      customInvoiceDescription: documentRequest.customInvoiceDescription,
      legalOrderFeeOrPrepaidAmount: documentRequest.prepaidAmount,
      legalOrderFeeOrPrepaidCheckNumber: documentRequest.prepaidCheckNumber,
      finalPaymentAmount: documentRequest.finalPaymentAmount,
      finalPaymentCheckNumber: documentRequest.finalPaymentCheckNumber,

      hasRestrictedAccess: documentRequest.hasRestrictedAccess,
      invoiceTemplateId: documentRequest.invoiceTemplateId?.toString(),
      invoiceAmount: documentRequest.invoiceAmount,

      portalActivity: undefined,
      hasEntityNameDiscrepancy: documentRequest.hasEntityNameDiscrepancy,
      internalReferenceId: documentRequest.internalReferenceId,
      stateReceived: documentRequest.stateReceived?.toString(),
      requireOneTimePin: documentRequest.requireOneTimePin,
      portalDurationInDays: documentRequest.portalDurationInDays,
      bsaNotificationRequired: documentRequest.bsaNotificationRequired,
      bsaNotes: documentRequest.bsaNotes,
      bsaReviewDate: this.helpers.fromApiDate(documentRequest.bsaReviewDate),
      isRollingProduction: documentRequest.isRollingProduction,
      servingPartyInstructions: documentRequest.servingPartyInstructions,
      servingPartyRequireInstructionAcknowledgment: documentRequest.servingPartyRequireInstructionAcknowledgment,
      legalAuthorityTypes: documentRequest.legalAuthorityTypes as unknown as string[],
      legalAuthorityText: documentRequest.legalAuthorityText,
      deliveryPortalAcknowledgmentAccepted: documentRequest.deliveryPortalAcknowledgmentAccepted
    };
  }
  fromHistory(model: any): any {
    return model;
  }
}
