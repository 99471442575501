import { Injectable } from '@angular/core';
import { DocumentRequestClosedReason, DocumentRequestStatus } from '@safarilaw-webapp/feature/lpms/data-access';
import * as redux from '../../../../redux/index';
import { IDocumentRequestEditPageUiState } from '../../../../redux/state.interface';

import { defaultMatterEditPageUiState, LpmsMatterPageUiReduxObject } from '../../../matter-edit/redux/state';

export const defaultDocumentRequestEditPageUiState: IDocumentRequestEditPageUiState = {
  ...defaultMatterEditPageUiState,
  currentStatus: null,
  portalReminderRequest: null,
  portalCloseRequest: null,
  portalExtendRequest: null,
  returnToDeliverySetupRequest: null,
  focusPrepaidRequest: null,
  focusServingPartyInstructionsRequest: null,
  loadAllowedUsersRequest: null,
  portalActivityRequest: undefined,
  attachmentsNotInPortal: undefined,
  isAddingServingPartyInstructionsPayload: { isAdding: false }
};

export class PortalCloseRequest {
  status: DocumentRequestStatus;
  closedReason: DocumentRequestClosedReason;
  isCurrentInvoiceAutoGenerated: boolean;
}
export enum ServingPartyLookupFilter {
  Contact = 'Contact',
  Email = 'Email'
}
export enum ServingPartyLookupMode {
  New = 0,
  Continue = 1,
  Clear = 2
}
export class ServingPartyLookupRequest {
  servingPartyLookupMode: ServingPartyLookupMode;
  servingPartySearchInfo: {
    searchString: string;
    filter: ServingPartyLookupFilter;
  };
}
export class ServingPartyOrgLookupRequest {
  query: string;
}
@Injectable({ providedIn: 'root' })
export class LpmsInfoRequestEditPageUiReduxObject extends LpmsMatterPageUiReduxObject<IDocumentRequestEditPageUiState> {
  requestPortalActivity = super.addMessage('Request Load Portal Activity', state => state.portalActivityRequest, { portalActivityRequest: this.fromAction() });
  requestExtendPortal = super.addMessage('Request Extend Portal', state => state.portalExtendRequest, { portalExtendRequest: this.fromAction() });
  requestSendPortalReminder = super.addMessage('Request Send Portal Reminder', state => state.portalReminderRequest, { portalReminderRequest: this.fromAction() });
  requestReturnToDeliverySetup = super.addMessage('Request Return To Delivery Setup', state => state.returnToDeliverySetupRequest, { returnToDeliverySetupRequest: this.fromAction() });
  requestClosePortal = super.addMessage('Request Close Portal', state => state.portalCloseRequest, { portalCloseRequest: this.fromAction() });
  requestFocusPrepaidField = super.addMessage('Request Focus Prepaid', state => state.focusPrepaidRequest, { focusPrepaidRequest: this.fromAction() });
  requestFocusServingPartyInstructionsField = super.addMessage('Request Focus Serving Party Instructions', state => state.focusServingPartyInstructionsRequest, {
    focusServingPartyInstructionsRequest: this.fromAction()
  });
  attachmentsNotInPortal = super.addMessage('Set Attachments Not In Portal Count', state => state.attachmentsNotInPortal, { attachmentsNotInPortal: this.fromAction() });
  isAddingServingPartyInstructions = super.addMessage('Set Is Adding Serving Party Instructions', state => state.isAddingServingPartyInstructionsPayload, {
    isAddingServingPartyInstructionsPayload: this.fromAction()
  });

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.infoRequestEditPageState);
    super('InfoRequestEditPage', getPageState);
    super.addState({ ...defaultDocumentRequestEditPageUiState, persistent: { ...defaultDocumentRequestEditPageUiState.persistent } }).finalize();
  }
}
