import { Injectable } from '@angular/core';
import * as redux from '../../../../../redux/index';
import { IMatterPageUiState } from '../../../../../redux/state.interface';
import { LpmsMatterPageUiReduxObject, defaultMatterEditPageUiState } from '../../../../matter-edit/redux/state';

@Injectable({ providedIn: 'root' })
export class LpmsMatterCreatePageUiReduxObject extends LpmsMatterPageUiReduxObject<IMatterPageUiState> {
  updateSubtype = super.addMessage('Update Current Subtype', state => state.currentSubtype, { currentSubtype: this.fromAction() });

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.matterCreatePageState);
    super('MatterCreatePage', getPageState);
    super.addState({ ...defaultMatterEditPageUiState, persistent: { ...defaultMatterEditPageUiState.persistent } }).finalize();
  }
}
