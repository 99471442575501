import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';

import { DropdownAnswerDeliveryMethod as DropdownAnswerDeliveryMethodApi } from '../models/api/dropdown-answer-delivery-method';
import { DropdownAnswerDeliveryMethod } from '../models/app/dropdown-answer-delivery-method';
@Injectable({
  providedIn: 'root'
})
export class DropdownAnswerDeliveryMethodAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownAnswerDeliveryMethodApi): Required<DropdownAnswerDeliveryMethod> {
    return {
      ...super.fromListModel(model),
      isManualMethod: model.isManualMethod
    };
  }
}
